import { StarIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import ReviewModal from "../modals/ReviewModal";

export default function WriteReview(props) {
  const [open, setOpen] = useState(false);
  const [stars, setStars] = useState(0);

  useEffect(() => {
    setStars(props.userData.stars ? props.userData.stars : 5);
  }, [props.userData.stars]);

  return (
    <>
      <blockquote>
        <div>
          <h3 className="text-lg font-medium">Want to drop a review?</h3>
          <p className="mt-2 text-sm text-gray-500 italic">
            {!props.userData.review && <span>&ldquo;What do you think about MigrationCheck? We would appreciate your feedback because your satisfaction matters to us.&rdquo;</span>}
            {props.userData.review && <span>&ldquo;{props.userData.review}&rdquo;</span>}
          </p>
        </div>
        <footer className="mt-3">
          <div className="inline-flex items-center divide-x divide-gray-300">
            <div className="min-w-0 flex-1 pr-5 text-sm text-gray-500">
              <div className="flex items-center space-x-3">
                <div className="flex-shrink-0">
                  <img
                    className="h-6 w-6 rounded-full"
                    // src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&h=256&q=80"
                    src="/assets/images/avatar/ava17.png"
                    alt=""
                  />
                </div>
                <div className="text-sm font-medium text-gray-700">{props.userData.firstname}</div>
              </div>
            </div>
            <div className="flex-shrink-0 flex pl-5">
              {Array.from({ length: 5 }, (_, i) =>
                <StarIcon key={i} onMouseEnter={() => setStars(i + 1)}
                  onMouseLeave={() => setStars(props.userData.stars ? props.userData.stars : 5)}
                  onClick={() => { props.setUserData({ ...props.userData, stars: i + 1 }); setOpen(true); }}
                  className={`h-5 w-5 cursor-pointer ${stars > i ? "text-yellow-400" : "text-gray-300"}`} aria-hidden="true" />
              )}
            </div>
          </div>
        </footer>
      </blockquote>

      <ReviewModal
        userData={props.userData}
        setUserData={props.setUserData}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}