import React from "react";

export default function Tooltip({ children }) {
  return (
    <div className="relative tooltip">
      <div className="w-4 h-4">
        <img src="/assets/images/information.svg" className="w-4 h-4" alt="" />
      </div>
      <div className="tooltip-content">
        <p>{children}</p>
      </div>
    </div>
  );
}