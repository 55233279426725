import { KeyIcon, MailIcon, XCircleIcon, CheckCircleIcon } from "@heroicons/react/outline";
import React, { useEffect, useReducer, useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { Link } from "react-router-dom";
import { ChevronRightIcon, SparklesIcon } from "@heroicons/react/solid";

import {
  validate,
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
} from "../../form-validation/validators";

const loginEmailReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};


const loginFormReducer = (state, action) => {
  switch (action.type) {
    case "INPUTCHANGE":
      // console.log('We are here')
      let formIsValid = true;
      for (const input in state.inputs) {
        if (input === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[input].isValid;
        }
      }
      // console.log(formIsValid)
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: { value: action.value, isValid: action.isValid },
        },
        isValid: formIsValid,
      };
    default:
      return state;
  }
};

function ResetPW(props) {
  const auth = useContext(AuthContext);
  let navigate = useNavigate();
  const [instruction, setInstruction] = useState(false)
  const [loginEmailState, dispatch] = useReducer(loginEmailReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });
  const [loginFormState, dispatching] = useReducer(loginFormReducer, {
    inputs: {
      email: { value: "", isValid: false },
    },
    isValid: false,
  }); //here: isValid die gesamte Form?

  const [error, setError] = useState(false);
  const handleCloseErrorModal = () => {
    setError(null);
  };

  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: loginEmailState.value,
      isValid: loginEmailState.isValid,
      inputId: "email",
    });
  }, [loginEmailState.value, loginEmailState.isValid]);

  const emailChangeHandler = (event) => {
    // console.log(event.target.value)
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()],
    });
  };

  const emailTouchHandler = () => {
    dispatch({ type: "TOUCH" });
  };
 
  const loginFormSubmitHandler = async (event) => {
    event.preventDefault();
    console.log('This is what we send to the server: ', loginFormState.inputs)
    
    // console.log(
    //   'This is what we send to the server: ',
    //   loginFormState.inputs.email,
    // )
    try {
      // setIsLoading(true);
      const response = await fetch("/api/user/reset", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // JSON.stringify() Converts/parses Data to JSON
          email: loginFormState.inputs.email.value
        }),
      });
      // console.log(response);
      const responseData = await response.json(); // Transforms/parses the data from json to
      // console.log(responseData)
      // console.log(response.ok)
      if (!response.ok) {
        // without that check we reach the catch-blog only when we dont get a response at all (technical error)-> so we have to check if we get an error response-code (like 401->authenticaten failed)(lec.147):
        // console.log(responseData.message)
        throw new Error(responseData.message); // due to throwing here an error we trigger the catch block (lec. 147)
      }
      setInstruction(true);
      // navigate("/");
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
      // setError(err.message || 'Something went wrong, please try again');
      // console.log(error.message)
      setError(error.message);
    }

    //Achtung: Folgendes nur, wenn erfolgreich eingeloggt!
    // auth.login();
    // navigate("/home");
  };

  const redirectToHomepage = () => {
    window.location.href = 'https://migration-check.com';
  }

  return (
    <>
      <div className="min-h-screen flex bg-gray-100">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img src="/assets/images/logo.svg" onClick={redirectToHomepage} className="cursor-pointer h-[21px]"/>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Reset your Password
              </h2>
            </div>

            {error && (
              <div className="rounded-md bg-red-100 p-4 mt-6">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-red-800">Something went wrong, please try again or contact our support:<br />wecare@migration-check.com</p>
                  </div>
                </div>
              </div>
            )}


              {instruction && (<div className="rounded-md bg-green-100 p-4 mt-6">
                <div className="flex">
                  <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="flex-shrink-0 h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">We have sent you an email with instructions on how to reset your password.</p>
                  </div>
                </div>
              </div>
              )}

            <div className="mt-8">
              <div className="mt-6">
                <form onSubmit={loginFormSubmitHandler} className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                       Enter your Email address
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MailIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm disabled:bg-gray-200"
                        placeholder="johndoe@gmail.com"
                        onChange={emailChangeHandler}
                        onBlur={emailTouchHandler}
                        value={loginEmailState.value}
                        isInvalid={
                          !loginEmailState.isValid && loginEmailState.isTouched
                        }
                        disabled={true && instruction}
                      />
                    </div>
                    {!loginEmailState.isValid && loginEmailState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please use a valid email, that you used for your
                        registration
                      </p>
                    )}
                  </div>


                  <div>
                    <button
                      type="submit"
                      disabled={!loginFormState.isValid || instruction}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                    >
                      Recover
                    </button>
                  </div>
                </form>
                {/* <div className="flex justify-around"> */}
                  {/* <div className="mt-12">
                      <span className="text-sm text-gray-400">
                      No account yet?
                      </span>
                      <Link
                        to="/signup"
                        className="group flex items-center transition hover:translate-x-1 transform-gpu"
                      >
                        <SparklesIcon className="h-4 w-4 text-primary-500 group-hover:text-primary-600" />
                        <span className="ml-2 mr-1 text-sm font-medium bg-clip-text text-transparent bg-primary-linear group-hover:bg-primary-linear-hover">
                          Signup
                        </span>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Link>
                  </div> */}
                  <div className="mt-12">
                      <span className="text-sm text-gray-400">
                      Try to Log in?
                      </span>
                      <Link
                        to="/"
                        className="group flex items-center transition hover:translate-x-1 transform-gpu"
                      >
                        <SparklesIcon className="h-4 w-4 text-primary-500 group-hover:text-primary-600" />
                        <span className="ml-2 mr-1 text-sm font-medium bg-clip-text text-transparent bg-primary-linear group-hover:bg-primary-linear-hover">
                          Login
                        </span>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Link>
                  </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1 bg-linear-primary-3-1 shadow-inner">
          <img
            className="absolute inset-0 h-full w-full object-contain object-bottom"
            src="/assets/images/loginmigrationcheck.png"
            alt="migrationcheck germany"
          />
        </div>
      </div>
    </>
  );
}

export default ResetPW;
