export const showGuideMe = (migrationcheck) => {
  // console.log(migrationcheck);
  switch (migrationcheck) {
    case 'Au Pair': return false
    case 'Volunteer': return false
    case 'Work': return true
    case 'Start a Business': return false
    case 'Apprenticeship': return true  
    case 'Study': return true
    case 'Contract Marriage"': return false
    case 'Family Reunion': return false
    default: return false
  }
}