import { Fragment, useRef, useState, useContext, useReducer, useEffect } from 'react'
import { PencilIcon, MailIcon} from "@heroicons/react/outline";
import { Dialog, Transition } from '@headlessui/react'
import { useNavigate } from "react-router-dom"
import {
  CalendarIcon,
  UserIcon,
  XIcon,
  ShareIcon,
  GlobeIcon
} from "@heroicons/react/solid";
import { AuthContext } from "../context/auth-context";
import {
  validate,
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL
} from "../form-validation/validators";

const loginEmailReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const nameReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const ageReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const countryReducer = (state, action) => {
  console.log(state);
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const messageReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const languageReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const loginFormReducer = (state, action) => {
  switch (action.type) {
    case "INPUTCHANGE":
      // console.log('We are here')
      let formIsValid = true;
      for (const input in state.inputs) {
        if (input === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[input].isValid;
        }
      }
      // console.log(formIsValid)
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: { value: action.value, isValid: action.isValid },
        },
        isValid: formIsValid,
      };
    default:
      return state;
  }
};


export default function IntroduceMePublic(props) {
  const auth = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(false);
  const [purpose, setPurpose] = useState([]);


  const cancelButtonRef = useRef(null)
  const [loginEmailState, dispatchemail] = useReducer(loginEmailReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });
  const [nameState, dispatchname] = useReducer(nameReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });
  const [ageState, dispatch] = useReducer(ageReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });
  const [countryState, dispatchercountry] = useReducer(countryReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });
  const [qualificationState, dispatcher] = useReducer(messageReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });
  const [languageState, dispatcherlang] = useReducer(languageReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });

  const [loginFormState, dispatching] = useReducer(loginFormReducer, {
    inputs: {
      email: { value: "", isValid: false },
      name: { value: "", isValid: false },
      age: { value: "", isValid: false },
      qualification: { value: "", isValid: false },
      language: { value: "", isValid: false },
      country: { value: "", isValid: false },
    },
    isValid: false,
  });

  // console.log(loginFormState);
  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: loginEmailState.value,
      isValid: loginEmailState.isValid,
      inputId: "email",
    });
  }, [loginEmailState.value, loginEmailState.isValid]);
  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: ageState.value,
      isValid: ageState.isValid,
      inputId: "age",
    });
  }, [ageState.value, ageState.isValid]);
  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: countryState.value,
      isValid: countryState.isValid,
      inputId: "country",
    });
  }, [countryState.value, countryState.isValid]);
  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: qualificationState.value,
      isValid: qualificationState.isValid,
      inputId: "qualification",
    });
  }, [qualificationState.value, qualificationState.isValid]);

  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: languageState.value,
      isValid: languageState.isValid,
      inputId: "language",
    });
  }, [languageState.value, languageState.isValid]);

  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: nameState.value,
      isValid: nameState.isValid,
      inputId: "name",
    });
  }, [nameState.value, nameState.isValid]);

  const emailChangeHandler = (event) => {
    // console.log(event.target.value)
    dispatchemail({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()],
    });
  };

  const nameChangeHandler = (event) => {
    // console.log(event.target.value)
    dispatchname({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_REQUIRE()],
    });
  };

  const ageChangeHandler = (event) => {
    // console.log(event.target.value)
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_REQUIRE()],
    });
  };
  const countryChangeHandler = (event) => {
    console.log(event.target.value)
    dispatchercountry({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_REQUIRE()],
    });
  };
  const qualificationChangeHandler = (event) => {
    // console.log(event.target.value)
    dispatcher({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_MINLENGTH(4)],
    });
  };

  const languageChangeHandler = (event) => {
    // console.log(event.target.value)
    dispatcherlang({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_MINLENGTH(4)],
    });
  };

  const emailTouchHandler = () => {
    dispatchemail({ type: "TOUCH" });
  };
  const nameTouchHandler = () => {
    dispatchname({ type: "TOUCH" });
  };
  const ageTouchHandler = () => {
    dispatch({ type: "TOUCH" });
  };
  const countryTouchHandler = () => {
    dispatchercountry({ type: "TOUCH" });
  };
  const qualificationTouchHandler = () => {
    dispatcher({ type: "TOUCH" });
  };
  const languageTouchHandler = () => {
    dispatcherlang({ type: "TOUCH" });
  };
  // console.log(purpose);
  const purposesChangeHandler = (event) => {
    if (event.target.checked) {
      setPurpose([...purpose, event.target.name])
    } else {
      setPurpose(purpose.filter(a => a !== event.target.name))
    }
    
    

    console.log(purpose.length)
    // console.log(event.target.checked);
    // console.log(event.target.name);
    // console.log(event.target);
  }

  const purposeTicked = (value) => {
    if (purpose.includes(value)){
      return true;
    } else {
      return false
    }
  }



  const loginFormSubmitHandler = async (event) => {
    event.preventDefault();
    setPurpose([]);
    // console.log('This is what we send to the server: ', loginFormState.inputs);
    try {
      const response = await fetch("/api/users/introduceme", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // JSON.stringify() Converts/parses Data to JSON
          email: loginFormState.inputs.email.value,
          age: loginFormState.inputs.age.value,
          language: loginFormState.inputs.language.value,
          qualification: loginFormState.inputs.qualification.value,
          name: loginFormState.inputs.name.value,
          purpose: purpose,
          country:loginFormState.inputs.country.value,
        }),
      });
    
      const responseData = await response.json(); // Transforms/parses the data from json to
      // console.log("responseData: ", responseData)
      if (!response.ok) {

        throw new Error(responseData.message); // due to throwing here an error we trigger the catch block (lec. 147)
      }

      props.setOpenConnectMe(false);
      props.setPage(2);
    } catch (error) {
      console.log(error);

      setError(error.message);
    }

  };

  // console.log(loginFormState);

  return (
    <Transition.Root show={props.openConnectMe} as={Fragment}>
      <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={props.setOpenConnectMe}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
               

              <>
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                          onClick={() => props.setOpenConnectMe(false)}
                        >
                          <span className="sr-only">Close</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                </div>
                <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              {/* <img src="/assets/images/logo.svg" onClick={redirectToHomepage} className="cursor-pointer h-[21px]"/> */}
              <div className="mx-auto flex h-100 w-100 items-center">
                <img
                  className='object-none w-24 h-24 rounded-full ring-2 ring-offset-2 ring-primary-600 opacity-70'
                  src="/assets/images/connect5.svg"
                  alt=""
                />

                
              
                <h2 className="text-3xl font-extrabold text-gray-900 ml-4">
                Introduce me to companies
                </h2>
                {/* <ShareIcon className="h-12 w-12 mr-12" aria-hidden="true" color='#ff6f91'/> */}
              </div>
              <p className="text-sm text-gray-400 mt-3">
              Please provide the following information to complete your profile. Be sure you include your highest educational level!
              </p>
              <br />
            </div>

            <div>
              <div className="">
                <form onSubmit={loginFormSubmitHandler} className="space-y-5">
                  {/* Email */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Your Email address
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MailIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="true"
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="johndoe@gmail.com"
                        onChange={emailChangeHandler}
                        onBlur={emailTouchHandler}
                        value={loginEmailState.value}
                        isInvalid={
                          !loginEmailState.isValid && loginEmailState.isTouched
                        }
                      />
                    </div>
                    {!loginEmailState.isValid && loginEmailState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please use a valid email
                      </p>
                    )}
                  </div>
                    {/* Name */}
                    <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Your Name
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <UserIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="name"
                        name="name"
                        autoComplete="false"
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="Name"
                        onChange={nameChangeHandler}
                        onBlur={nameTouchHandler}
                        value={nameState.value}
                        isInvalid={
                          !nameState.isValid && nameState.isTouched
                        }
                      />
                    </div>
                    {!nameState.isValid && nameState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please enter your name
                      </p>
                    )}
                  </div>
                  {/* Age */}
                  <div>
                    <label
                      htmlFor="age"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Your Age
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <CalendarIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="age"
                        name="age"
                        autoComplete="false"
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="29 years"
                        onChange={ageChangeHandler}
                        onBlur={ageTouchHandler}
                        value={ageState.value}
                        isInvalid={
                          !ageState.isValid && ageState.isTouched
                        }
                      />
                    </div>
                    {!ageState.isValid && ageState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please enter your age
                      </p>
                    )}
                  </div>

                  {/* Country */}
                  <div>
                    <label
                      htmlFor="age"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Your Country
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <GlobeIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="age"
                        name="age"
                        autoComplete="false"
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="Indonesia"
                        onChange={countryChangeHandler}
                        onBlur={countryTouchHandler}
                        value={countryState.value}
                        isInvalid={
                          !countryState.isValid && countryState.isTouched
                        }
                      />
                    </div>
                    {!countryState.isValid && countryState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please enter your country
                      </p>
                    )}
                  </div>

                  {/* Areas of interest */}
                  <div className="space-y-3">
                    <label
                      htmlFor=""
                      className="block text-sm font-medium text-gray-500"
                    >
                      Please select a maximum of five professionals you are most interested in working as and training to become.
                    </label>
                    <div className="flex">
                      {/* Checkbox Bus driver / truck driver */}
                      <div className="relative flex items-start basis-1/2">
                        <div className="flex items-center h-5">
                          <input
                            id="Bus driver / truck driver"
                            name="Bus driver / truck driver"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={purposeTicked('Bus driver / truck driver')}
                            onChange={purposesChangeHandler}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="" className="text-gray-500 text-xs">
                            Bus driver / truck driver
                          </label>
                        </div>
                      </div>
                      {/* Checkbox Butcher */}
                      <div className="relative flex items-start basis-1/2">
                        <div className="flex items-center h-5">
                          <input
                            id="Butcher"
                            name="Butcher"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={purposeTicked('Butcher')}
                            onChange={purposesChangeHandler}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="" className="text-gray-500 text-xs">
                            Butcher
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      {/* Checkbox Carpenter / woodworker */}
                      <div className="relative flex items-start basis-1/2">
                        <div className="flex items-center h-5">
                          <input
                            id="Carpenter / woodworker"
                            name="Carpenter / woodworker"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={purposeTicked('Carpenter / woodworker')}
                            onChange={purposesChangeHandler}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="" className="text-gray-500 text-xs">
                            Carpenter / woodworker
                          </label>
                        </div>
                      </div>
                      {/* Checkbox Constrution worker (bricklayer, roofer) */}
                      <div className="relative flex items-start basis-1/2">
                        <div className="flex items-center h-5">
                          <input
                            id="Constrution worker (bricklayer, roofer)"
                            name="Constrution worker (bricklayer, roofer)"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={purposeTicked('Constrution worker (bricklayer, roofer)')}
                            onChange={purposesChangeHandler}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="" className="text-gray-500 text-xs">
                            Constrution worker (bricklayer, roofer)
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      {/* Checkbox Electrician */}
                      <div className="relative flex items-start basis-1/2">
                        <div className="flex items-center h-5">
                          <input
                            id="Electrician"
                            name="Electrician"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={purposeTicked('Electrician')}
                            onChange={purposesChangeHandler}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="" className="text-gray-500 text-xs">
                            Electrician
                          </label>
                        </div>
                      </div>
                      {/* Checkbox Gardener */}
                      <div className="relative flex items-start basis-1/2">
                        <div className="flex items-center h-5">
                          <input
                            id="Gardener"
                            name="Gardener"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={purposeTicked('Gardener')}
                            onChange={purposesChangeHandler}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="" className="text-gray-500 text-xs">
                            Gardener
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      {/* Checkbox Geriatric nurse */}
                      <div className="relative flex items-start basis-1/2">
                        <div className="flex items-center h-5">
                          <input
                            id="Geriatric nurse"
                            name="Geriatric nurse"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={purposeTicked('Geriatric nurse')}
                            onChange={purposesChangeHandler}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="" className="text-gray-500 text-xs">
                            Geriatric nurse
                          </label>
                        </div>
                      </div>
                      {/* Checkbox Hotel specialist */}
                      <div className="relative flex items-start basis-1/2">
                        <div className="flex items-center h-5">
                          <input
                            id="Hotel specialist"
                            name="Hotel specialist"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={purposeTicked('Hotel specialist')}
                            onChange={purposesChangeHandler}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="" className="text-gray-500 text-xs">
                            Hotel specialist
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      {/* Checkbox System mechanic */}
                      <div className="relative flex items-start basis-1/2">
                        <div className="flex items-center h-5">
                          <input
                            id="System mechanic"
                            name="System mechanic"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={purposeTicked('System mechanic')}
                            onChange={purposesChangeHandler}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="" className="text-gray-500 text-xs">
                            System mechanic
                          </label>
                        </div>
                      </div>
                      {/* Checkbox Metal worker in construction technology */}
                      <div className="relative flex items-start basis-1/2">
                        <div className="flex items-center h-5">
                          <input
                            id="Metal worker in construction technology"
                            name="Metal worker in construction technology"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={purposeTicked('Metal worker in construction technology')}
                            onChange={purposesChangeHandler}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="" className="text-gray-500 text-xs">
                            Metal worker in construction technology
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      {/* Checkbox Painter / varnisher */}
                      <div className="relative flex items-start basis-1/2">
                        <div className="flex items-center h-5">
                          <input
                            id="Painter / varnisher"
                            name="Painter / varnisher"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={purposeTicked('Painter / varnisher')}
                            onChange={purposesChangeHandler}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="" className="text-gray-500 text-xs">
                            Painter / varnisher
                          </label>
                        </div>
                      </div>
                      {/* Checkbox Warehouse logistics clerk */}
                      <div className="relative flex items-start basis-1/2">
                        <div className="flex items-center h-5">
                          <input
                            id="Warehouse logistics clerk"
                            name="Warehouse logistics clerk"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={purposeTicked('Warehouse logistics clerk')}
                            onChange={purposesChangeHandler}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="" className="text-gray-500 text-xs">
                            Warehouse logistics clerk
                          </label>
                        </div>
                      </div>
                    </div>


                  </div>

                  {/* Textarea */}
                  <div>
                    <label

                      className="block text-sm font-medium text-gray-500"
                    >
                      Your Qualifications
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <PencilIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <textarea
                        id="textarea"
                        name="textarea"
                        rows={4}
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="I have completed my secondary school education and have gained six months of work experience in gardening."
                        onChange={qualificationChangeHandler}
                        onBlur={qualificationTouchHandler}
                        value={qualificationState.value}
                        isInvalid={
                          !qualificationState.isValid && qualificationState.isTouched
                        }
                      ></textarea>
                    </div>
                    {!qualificationState.isValid && qualificationState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please enter your qualifications.
                      </p>
                    )}
                  </div>

                  {/* Textarea */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Your Language Competence
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <PencilIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <textarea
                        id="textarea"
                        name="textarea"
                        rows={2}
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="German (beginner), English (native speaker)"
                        onChange={languageChangeHandler}
                        onBlur={languageTouchHandler}
                        value={languageState.value}
                        isInvalid={
                          !languageState.isValid && languageState.isTouched
                        }
                      ></textarea>
                    </div>
                    {!languageState.isValid && languageState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please enter your language competence
                      </p>
                    )}
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={!loginFormState.isValid}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                    >
                      Send
                    </button>
                  </div>
                </form>

              </div>
            </div>
              </div>
              </>
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}