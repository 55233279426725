import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AuthContext } from "../context/auth-context";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { NavLink, useNavigate } from "react-router-dom";

export default function SelectMigrationCheck(props) {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [migrationChecks, setMigrationChecks] = useState([]);
  const [activeButton, setActiveButton] = useState(true);

  const loadMigrationChecks = () => {
    fetch("/api/migration-checks?active=true", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => {
        // console.log(result);
        // Output the result of moving the element at index 0 to index 2
        const reorderedMigrationChecks = (move(result, 7, 2));
        // console.log(reorderedMigrationChecks);
        setMigrationChecks(reorderedMigrationChecks);
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  const createUserResult = (migrationCheckId) => {
    setActiveButton(false);
    fetch(`/api/migration-checks/${migrationCheckId}/user-results`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => navigate(`/participate/${result.id}`))
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  useEffect(() => {
    loadMigrationChecks();
  }, []);

  const move = (arr, old_index, new_index) => {
    // Adjust negative indices to the equivalent positive indices
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }

    // If 'new_index' is beyond the array length, extend the array with undefined elements
    if (new_index >= arr.length) {
        var k = new_index - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
    }

    // Remove the element at 'old_index' and insert it at 'new_index'
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    // Return the modified array
    return arr;
  }


  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm:w-full sm:p-6">
                <div>
                  <video
                    type="video/mp4"
                    className="h-32 w-32 rounded-full ring ring-offset-2 ring-primary-600 mx-auto mt-2"
                    autoPlay
                    muted
                    playsInline
                  >
                    <source src="/assets/vids/page33.mp4" type="video/mp4" />
                    {/* {<source src={vid1} type="video/mp4" />} */}
                  </video>
                  <div className="mt-6 text-center ml-4">
                    <h2 className="text-2xl font-bold leading-tight text-gray-900">
                      For which of these purposes do you want to take <nobr>a detailed check?</nobr>
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      Please choose a category and be aware that choosing one reduces the quota of your detailed checks.
                      {/* Choose a category for a new migration-check. Be aware that this reduces your migration-check quota. */}
                    </p>
                  </div>
                  {activeButton ? 
                  <div className="mt-6 border-t border-b border-gray-200 py-6 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2">
                    {/* border-rose-600/40 hover:border-rose-600 */}
                    {migrationChecks.map((migrationCheck) => (
                      <button
                        key={migrationCheck.id}
                        onClick={() => createUserResult(migrationCheck.id)}
                        className="transition text-left group inline-flex items-center justify-center px-4 py-3 border border-2 border-gray-300/40 rounded-lg hover:border-primary-600 focus:outline-none text-gray-700"
                      >
                        <div className="flex flex-shrink-0">
                          {migrationCheck.type === 0 && (
                            <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                              {/* <BriefcaseIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                      /> */}
                              <img
                                src="assets/images/icons_purposes/work.svg"
                                alt="work"
                              />
                            </span>
                          )}
                          {migrationCheck.type === 1 && (
                            <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                              {/* <AcademicCapIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                      /> */}
                              <img
                                src="assets/images/icons_purposes/study.svg"
                                alt="study"
                              />
                            </span>
                          )}
                          {migrationCheck.type === 2 && (
                            <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                              {/* <SupportIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                      /> */}
                              <img
                                src="assets/images/icons_purposes/volunteer.svg"
                                alt="volunteer"
                              />
                            </span>
                          )}
                          {migrationCheck.type === 3 && (
                            <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                              {/* <PresentationChartBarIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                      /> */}
                              <img
                                src="assets/images/icons_purposes/business.svg"
                                alt="business"
                              />
                            </span>
                          )}
                          {migrationCheck.type === 4 && (
                            <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                              {/* <UserGroupIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                      /> */}
                              <img
                                src="assets/images/icons_purposes/joinfamily.svg"
                                alt="join family"
                              />
                            </span>
                          )}
                          {migrationCheck.type === 5 && (
                            <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                              {/* <HeartIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                      /> */}
                              <img
                                src="assets/images/icons_purposes/marriage.svg"
                                alt="marriage"
                              />
                            </span>
                          )}
                          {migrationCheck.type === 6 && (
                            <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                              {/* <GiftIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                      /> */}
                              <img
                                src="assets/images/icons_purposes/apprenticeship.svg"
                                alt="apprenticeship"
                              />
                            </span>
                          )}
                          {migrationCheck.type === 7 && (
                            <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                              {/* <GiftIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                      /> */}
                              <img
                                src="assets/images/icons_purposes/au_pair.svg"
                                alt="au pair"
                              />
                            </span>
                          )}
                        </div>
                        <div className="min-w-0 flex-1 ml-4">
                          <div className="text-sm font-semibold text-gray-900">
                            {migrationCheck.title}
                          </div>
                          <p className="text-xs text-gray-500">
                            {migrationCheck.hint}
                          </p>
                        </div>
                      </button>
                    ))}
                  </div> : 
                  <div className="mt-6 border-t border-b border-gray-200 py-6 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2">
                  {/* border-rose-600/40 hover:border-rose-600 */}
                  {migrationChecks.map((migrationCheck) => (
                    <div
                      key={migrationCheck.id}
                      className="transition text-left group inline-flex items-center justify-center px-4 py-3 border border-2 border-gray-300/40 rounded-lg hover:border-primary-600 focus:outline-none text-gray-700"
                    >
                      <div className="flex flex-shrink-0">
                        {migrationCheck.type === 0 && (
                          <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                            {/* <BriefcaseIcon
                                      className="h-6 w-6 text-white"
                                      aria-hidden="true"
                                    /> */}
                            <img
                              src="assets/images/icons_purposes/work.svg"
                              alt="work"
                            />
                          </span>
                        )}
                        {migrationCheck.type === 1 && (
                          <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                            {/* <AcademicCapIcon
                                      className="h-6 w-6 text-white"
                                      aria-hidden="true"
                                    /> */}
                            <img
                              src="assets/images/icons_purposes/study.svg"
                              alt="study"
                            />
                          </span>
                        )}
                        {migrationCheck.type === 2 && (
                          <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                            {/* <SupportIcon
                                      className="h-6 w-6 text-white"
                                      aria-hidden="true"
                                    /> */}
                            <img
                              src="assets/images/icons_purposes/volunteer.svg"
                              alt="volunteer"
                            />
                          </span>
                        )}
                        {migrationCheck.type === 3 && (
                          <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                            {/* <PresentationChartBarIcon
                                      className="h-6 w-6 text-white"
                                      aria-hidden="true"
                                    /> */}
                            <img
                              src="assets/images/icons_purposes/business.svg"
                              alt="business"
                            />
                          </span>
                        )}
                        {migrationCheck.type === 4 && (
                          <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                            {/* <UserGroupIcon
                                      className="h-6 w-6 text-white"
                                      aria-hidden="true"
                                    /> */}
                            <img
                              src="assets/images/icons_purposes/joinfamily.svg"
                              alt="join family"
                            />
                          </span>
                        )}
                        {migrationCheck.type === 5 && (
                          <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                            {/* <HeartIcon
                                      className="h-6 w-6 text-white"
                                      aria-hidden="true"
                                    /> */}
                            <img
                              src="assets/images/icons_purposes/marriage.svg"
                              alt="marriage"
                            />
                          </span>
                        )}
                        {migrationCheck.type === 6 && (
                          <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                            {/* <GiftIcon
                                      className="h-6 w-6 text-white"
                                      aria-hidden="true"
                                    /> */}
                            <img
                              src="assets/images/icons_purposes/apprenticeship.svg"
                              alt="apprenticeship"
                            />
                          </span>
                        )}
                        {migrationCheck.type === 7 && (
                          <span className="inline-flex items-center justify-center h-12 w-12 rounded-lg">
                            {/* <GiftIcon
                                      className="h-6 w-6 text-white"
                                      aria-hidden="true"
                                    /> */}
                            <img
                              src="assets/images/icons_purposes/au_pair.svg"
                              alt="au pair"
                            />
                          </span>
                        )}
                      </div>
                      <div className="min-w-0 flex-1 ml-4">
                        <div className="text-sm font-semibold text-gray-900">
                          {migrationCheck.title}
                        </div>
                        <p className="text-xs text-gray-500">
                          {migrationCheck.hint}
                        </p>
                      </div>
                    </div>
                  ))}
                  </div>
                  }
                  <div className="mt-4 flex">
                    <span className="text-xs text-gray-500 group-hover:text-gray-600">
                      This detailed check serves all non-EU citizens who want to temporarily or permanently live in Germany by means of the listed categories.
                    </span>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
