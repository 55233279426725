import { Menu, Transition } from "@headlessui/react";
import {
  BadgeCheckIcon,
  ChevronRightIcon,
  SparklesIcon,
} from "@heroicons/react/solid";
import { Fragment, useContext, useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { AuthContext } from "../context/auth-context";
import Container from "./Container";
import Settings from "../modals/Settings";

function Navbar(props) {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [openSettings, setOpenSettings] = useState(false);
  /* 
  

  0 = keine
  1 = Dashbord
  2 = Participate

  */

  const [isDashboard, setIsDashboard] = useState(location.pathname === "/");
  const [isParticipation, setIsParticipation] = useState(location.pathname !== "/");

  const handleLogout = () => {
    auth.logout();
    navigate("/login");
  };

  useEffect(() => {
    if (location.pathname.startsWith("/participate")) {
      setIsDashboard(false);
      setIsParticipation(true);
    } else {
      setIsDashboard(true);
      setIsParticipation(false);
    }
  }, [location])

  return (
    <>
    <div>
      <nav className="fixed top-0 left-0 right-0 z-20 backdrop-blur bg-gray-100/75 border-b border-gray-200">
        <Container>
          <div className="relative h-16">
            <Transition
              show={isParticipation}
              enter="transition-opacity ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100 yolo"
              leave="transition-opacity ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute inset-0 flex justify-center items-center">
                <div className="relative w-[50%]">
                  <TransitionGroup>
                    {[props.section].map(x =>
                      <CSSTransition key={x}
                        classNames="fade"
                        timeout={500}
                      >
                        <p className="flex justify-center text-sm font-medium text-gray-900 truncate">
                          {!!x && x}
                          {!x && <span>&nbsp;</span>}
                        </p>
                      </CSSTransition>)}
                  </TransitionGroup>
                  <div className="mt-2" aria-hidden="true">
                    <div className="bg-gray-200 rounded-full overflow-hidden">
                      <div className="transition-all duration-400 h-2 bg-primary-500 rounded-full" style={{ width: `${props.progress < 100 ? props.progress : 100}%` }} />
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <div className="relative flex justify-between items-center h-16">
              <NavLink to="/" className="flex items-center cursor-pointer">
                {!isParticipation && <img className="h-[21px]" src="/assets/images/logo.svg" />}
                {isParticipation && <img className="h-[21px] block lg:hidden" src="/assets/images/logosmall.svg" />}
                {isParticipation && <img className="h-[21px] hidden lg:block" src="/assets/images/logo.svg" />}
              </NavLink>
              <div className="relative flex-shrink-0 flex items-center">
                <Transition
                  show={isDashboard}
                  enter="transition-opacity ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100 yolo"
                  leave="transition-opacity ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <p className="hidden sm:block mr-6 border-r border-gray-200 pr-4">
                    <NavLink
                      to="/webinars"
                      className="group flex items-center transition hover:translate-x-1 transform-gpu"
                    >
                      <SparklesIcon className="h-4 w-4 text-primary-500 group-hover:text-primary-600" />
                      <span className="ml-2 mr-1 text-sm font-medium bg-clip-text text-transparent bg-primary-linear group-hover:bg-primary-linear-hover">
                        Webinars
                      </span>
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </NavLink>
                  </p>
                </Transition>
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full object-cover ring-2 ring-offset-2 ring-primary-500"
                      // src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&h=256&q=80"
                      src="/assets/images/avatar/ava17.png"
                      alt=""
                    />
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute z-30 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              onClick={handleLogout}
                              className={`block cursor-pointer px-4 py-2 text-sm text-gray-700 ${active ? "bg-gray-100" : ""
                                }`}
                            >
                              Log out
                            </span>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              onClick={()=>{setOpenSettings(true)}}
                              className={`block cursor-pointer px-4 py-2 text-sm text-gray-700 ${active ? "bg-gray-100" : ""
                                }`}
                            >
                              Settings
                            </span>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </Container>
      </nav>
      <div className="pt-16">
        <div className="relative">
          <Outlet />
        </div>
      </div>
    </div>
    { openSettings && (
      <Settings setOpen= {setOpenSettings} open= {openSettings}  />
    )}
    </>
  );
}

export default Navbar;
