import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AuthContext } from "../context/auth-context";
import Confirmation from "./Confirmation";
import { XIcon } from "@heroicons/react/solid";

export default function Settings(props) {
  const auth = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const sendRequest = () => {
    fetch(`/api/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => {
        setUserData(result);
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  useEffect(() => {
    sendRequest()
  }, []);

  const handleInputChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`/api/users/${userData.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
      body: JSON.stringify(userData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((_) => props.setOpen(false))
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  const deleteUser = () => {
    fetch(`/api/user/goodbye`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => {
        console.log("1");
        if (res.ok) {
          props.setOpen(false)
          auth.logout();
          window.location.href = "https://migration-check.com"
        }

        console.log("3");
        return Promise.reject(res);
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={props.setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                  {userData && (
                    <>
                      <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                          onClick={() => props.setOpen(false)}
                        >
                          <span className="sr-only">Close</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <h2 className="text-lg font-medium text-gray-900">
                          Settings
                        </h2>
                        {/* <p className="mt-1 text-sm text-gray-500">
                          Adjust or delete your profile
                        </p> */}
                        <div className="mt-6 py-6 border-t border-b space-y-3">
                          <div>
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium text-gray-700"
                            >
                              E-Mail-Adresse
                            </label>
                            <div className="mt-1">
                              <input
                                id="email"
                                name="email"
                                type="text"
                                className="shadow-sm focus:ring-primary-500 focus:border-rose-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                                placeholder="E-mail-address"
                                value={userData.email}
                                onChange={handleInputChange}
                                disabled={true}
                              />
                            </div>
                          </div>
                          {/* Firstname */}
                          {/* <div>
                        <label
                          htmlFor="firstname"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Name (optional)
                        </label>
                        <div className="mt-1">
                          <input
                            id="firstname"
                            name="firstname"
                            type="text"
                            className="shadow-sm focus:ring-primary-500 focus:border-rose-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Firstname"
                            value={userData.firstname}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div> */}
                          {/* 2FA */}
                          {/* <Switch.Group as="div" className="flex items-center justify-between">
                        <span className="flex flex-grow flex-col">
                          <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                            Available to hire
                          </Switch.Label>
                          <Switch.Description as="span" className="text-sm text-gray-500">
                            Nulla amet tempus sit accumsan. Aliquet turpis sed sit lacinia.
                          </Switch.Description>
                        </span>
                        <Switch
                          checked={enabled}
                          onChange={setEnabled}
                          className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ${enabled ? 'bg-indigo-600' : 'bg-gray-200'}`}
                        >
                          <span
                            aria-hidden="true"
                            className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${enabled ? 'translate-x-5' : 'translate-x-0'}`}
                          />
                        </Switch>
                      </Switch.Group> */}

                        </div>
                        {/* <div className="flex itmems-center justify-between">
                      <div>
                        <button
                          type="submit"
                          className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                        >
                          Speichern
                        </button>
                      </div>
                    </div> */}

                        <div className="flex itmems-center justify-between">
                          <div>
                            <p className="mt-5 text-sm text-gray-700">
                              Deactivate account
                            </p>
                            <button
                              type="button"
                              className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                              onClick={() => { setOpenConfirmation(true) }}
                            >
                              Deactivate
                            </button>
                            <p className="mt-2 text-sm text-gray-400">
                              Attention: All of your data will be permanently removed. This action cannot be undone!
                            </p>
                          </div>

                        </div>
                      </form>
                    </>)
                  }
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {openConfirmation && <Confirmation open={openConfirmation} setOpen={setOpenConfirmation} deleteUser={deleteUser} />}
    </>
  );
}
