import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AuthContext } from "../context/auth-context";
import { useNavigate } from "react-router-dom";

export default function CreateMigrationCheckModal(props) {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [migrationCheck, setMigrationCheck] = useState(null);

  useEffect(() => {
    if (props.editMigrationCheck) {
      setMigrationCheck({
        title: props.editMigrationCheck.title,
        description: props.editMigrationCheck.description,
        type: props.editMigrationCheck.type,
        active: props.editMigrationCheck.active
          ? props.editMigrationCheck.active
          : false,
      });
    } else {
      setMigrationCheck({
        title: "",
        description: "",
        type: 0,
        active: false,
      });
    }
  }, [props.editMigrationCheck]);

  const handleInputChange = (e) => {
    // console.log(e);
    if (e.target.type === "checkbox") {
      setMigrationCheck({
        ...migrationCheck,
        [e.target.name]: e.target.checked,
      });
    } else {
      setMigrationCheck({ ...migrationCheck, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (props.editMigrationCheck) {
      fetch(`/api/migration-checks/${props.editMigrationCheck.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify(migrationCheck),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }

          return Promise.reject(res);
        })
        .then((_) => props.setOpen(false))
        .catch((err) => {
          if (err.status === 401) {
            auth.logout();
          }
        });
    } else {
      fetch(`/api/migration-checks`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify(migrationCheck),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }

          return Promise.reject(res);
        })
        .then((_) => props.setOpen(false))
        .catch((err) => {
          if (err.status === 401) {
            auth.logout();
          }
        });
    }
  };

  const deleteMigrationCheck = () => {
    fetch(`/api/migration-checks/${props.editMigrationCheck.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((_) => {
        props.setOpen(false);
        navigate("/admin/migration-checks");
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                {migrationCheck && (
                  <form onSubmit={handleSubmit}>
                    <h2 className="text-lg font-medium text-gray-900">
                      {props.editMigrationCheck
                        ? "Migration Check bearbeiten"
                        : "Neuer Migration Check"}
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      {props.editMigrationCheck
                        ? "Bearbeite den Migration Check"
                        : "Erstelle einen neuen Migration Check"}
                    </p>
                    <div className="mt-6 py-6 border-t border-b space-y-3">
                      <div>
                        <label
                          htmlFor="title"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Title
                        </label>
                        <div className="mt-1">
                          <input
                            id="title"
                            name="title"
                            type="text"
                            className="shadow-sm focus:ring-primary-500 focus:border-rose-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="title"
                            value={migrationCheck.title}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="description"
                            name="description"
                            type="text"
                            rows={3}
                            className="shadow-sm focus:ring-primary-500 focus:border-rose-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="description"
                            value={migrationCheck.description}
                            onChange={handleInputChange}
                          />
                        </div>
                        <p className="mt-1 text-xs text-gray-500">
                          Use {"{result}"} to refer the result.
                        </p>
                        <p className="mt-1 text-xs text-gray-500">
                          &#60; 25%: poor
                        </p>
                        <p className="mt-1 text-xs text-gray-500">
                          &#60; 50%: fair
                        </p>
                        <p className="mt-1 text-xs text-gray-500">
                          &#60; 75%: good
                        </p>
                        <p className="mt-1 text-xs text-gray-500">
                          &#62;&#61; 75%: great
                        </p>
                      </div>

                      <div>
                        <label
                          htmlFor="type"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Type (0-6)
                        </label>
                        <div className="mt-1">
                          <input
                            id="type"
                            name="type"
                            type="number"
                            className="shadow-sm focus:ring-primary-500 focus:border-rose-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="type"
                            value={migrationCheck.type}
                            onChange={handleInputChange}
                          />
                          <p className="mt-1 text-xs text-gray-500">0: work</p>
                          <p className="mt-1 text-xs text-gray-500">1: study</p>
                          <p className="mt-1 text-xs text-gray-500">
                            2: volunteer
                          </p>
                          <p className="mt-1 text-xs text-gray-500">
                            3: start a business
                          </p>
                          <p className="mt-1 text-xs text-gray-500">
                            4: join family
                          </p>
                          <p className="mt-1 text-xs text-gray-500">
                            5: get married
                          </p>
                          <p className="mt-1 text-xs text-gray-500">
                            6: apprenticeship
                          </p>
                          <p className="mt-1 text-xs text-gray-500">
                            7: au pair
                          </p>
                        </div>
                      </div>

                      {props.editMigrationCheck && (
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="active"
                              name="active"
                              type="checkbox"
                              className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                              checked={migrationCheck.active}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="ml-3 text-sm">
                            <label
                              htmlFor="active"
                              className="font-medium text-gray-700"
                            >
                              Active
                            </label>
                            <p className="text-gray-500">
                              This migration check is active for users to
                              participate.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex itmems-center justify-between">
                      <div>
                        {props.editMigrationCheck && (
                          <button
                            type="button"
                            className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                            onClick={deleteMigrationCheck}
                          >
                            Löschen
                          </button>
                        )}
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                        >
                          Speichern
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
