// export default function Upgrade(props) {
//   return (
//     <div className="max-w-[880px] mx-auto px-4 sm:px-6 lg:px-8 py-20">
//       Select a new Package:
//     </div>
//   );
// }
import { useNavigate } from "react-router-dom";
import Tooltip from "../../components/Tooltip"
// import { SparklesIcon, PuzzleIcon, TrendingUpIcon, DocumentTextIcon, DesktopComputerIcon, ColorSwatchIcon } from "@heroicons/react/solid";
import Footer from "../../components/Footer";

const chancenkarte =
[{ title: "Eligibility Requirements: Understand the criteria for the Chancenkarte—university graduates qualify without points, while those with professional qualifications need to meet a six-point threshold."},
{ title: 'Proving Financial Means: Explore the three ways to demonstrate financial stability, including a blocked account, formal declaration, and secondary job.'},
{ title: 'Finding a Secondary Job: Learn about part-time job opportunities and how to secure them while searching for a full-time role that matches your qualifications.'},
{ title: 'Q&A Session: Get answers to your specific questions about the Chancenkarte and financial requirements.'},
]

const CraftingCompellingApplicationsIncluded =
[{ title: "Gain insights into the German job market and understand what employers are looking for in job applications."},
{ title: 'Cover Letter Strategies: Discover the art of writing cover letters that make an impact and set you apart from the competition.'},
{ title: 'Resume Mastery: Learn how to craft a standout resume that highlights your strengths and captivates employers.'},
{ title: 'Networking Opportunities: Connect with like-minded individuals and expand your professional network for future career opportunities.'},
]

const MasteringSpeculativeApplicationsIncluded =
[{ title: "Understanding the Landscape: Navigate the German job market by learning the intricacies of speculative applications and how they can open unexpected doors."},
{ title: 'Crafting Impactful Documents: Master the art of creating compelling speculative applications, including cover letters and resumes, that grab the attention of potential employers.'},
{ title: 'Tailoring Your Approach: Learn the importance of tailoring your applications to different companies and industries, showcasing your unique value proposition.'},
{ title: 'Interactive Workshops: Engage in hands-on online workshops to refine your speculative application documents with guidance from HR experts.'},
]

const GermanItJobMarketIncluded =
[{ title: "Learn to assess your prospects and perspectives for the German IT job market and what really counts in this field."},
{ title: 'What are German IT companies looking for? What is industry and large companies are looking for and which areas with which skills are currently in particularly high demand.'},
{ title: 'What can you expect from an IT job in Germany? How to start and what should be considered and what mistakes should be avoided?'},
{ title: 'We discuss salary opportunities, work-life balance, benefits, the difference between small and big companys in Germany and much more.'},
]

const FirstOneHundredIncluded =
[{ title: "Days 1-14: You will learn how to find suitable accommodation, set up utilities in your new home, familiarize yourself with local amenities, and register your address."},
{ title: 'Days 15-30: Know what should be on your to-do list for these days, including how to get around using public transportation, bike, or car and visiting the foreigners authority.'},
{ title: 'Days 31-60: Opening bank accounts, comparing insurance offers, socializing, and networking with locals and expats require effective communication, which you’ll learn.'},
{ title: 'Days 61-100: Discover the best places to eat, drink, shop, and have fun. Cope with culture shock, homesickness, and other challenges.'},
]

const UnlockingVocationalIncluded =
[{ title: "Insights into Vocational Training: Gain a comprehensive understanding of the German dual vocational training system, one of the most esteemed models globally."},
{ title: 'Navigating the German Job Market: Explore the vast opportunities available for international apprentices and understand the steps to secure a vocational training position.'},
{ title: 'Crafting Winning Applications: Learn how to create standout applications for vocational training positions and get noticed by reputable German companies.'},
{ title: 'Building a Career Foundation: Discover how vocational training can serve as the foundation for a successful and rewarding career, offering both theoretical knowledge and hands-on experience.'},
]

const JobInterviewIncluded =
[{ title: "Mastering Virtual Interviews: In an era of remote work, discover tips and tricks to excel in virtual interviews, from video etiquette to creating a professional online presence."},
{ title: 'Handling Tricky Situations: Navigate through challenging scenarios, such as addressing gaps in your employment history or discussing salary expectations.'},
{ title: 'Communication Skills: Hone your communication skills and learn how to effectively convey your strengths, accomplishments, and value during interviews.'},
{ title: 'Expert Guidance: Receive personalized coaching from seasoned professionals with extensive experience in recruitment and hiring processes.'},
]



export default function Webinars() {
  const navigate = useNavigate();
  return (
    <>
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Webinars</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Feel free to choose webinars that suit you. We look forward to seeing you! Our webinars are group events.
          </p>
        </div>
        {/* Chancenkarte: Finances for Your Opportunity Card */}
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <div className="flex gap-x-3">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">Chancenkarte:</h2>
              {/* <ColorSwatchIcon className="h-6 w-6" style={{ color: "#1bb55c33" }} /> */}
            </div>
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Finances for Your Opportunity Card</h3>
            <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-2">
                12:00 - 13:30 CET, Wednesday, September 25, 2024
              <Tooltip>{"CET Central European Time"}</Tooltip>
            </div>

            {/* <div className="mt-2 text-base leading-7 text-gray-600 flex gap-x-2">
                Place: Zoom
              <Tooltip>{"After your registration you will receive a dial-in link"}</Tooltip>
            </div> */}

            <div className="mt-2 text-base leading-7 text-gray-600 italic flex gap-x-2">
            The Chancenkarte is your gateway to professional opportunities in Germany, but financial readiness is crucial for your success. This webinar will focus on securing a secondary job for your Opportunity Card.
            </div>

            <div className="mt-6 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What to Expect</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <div
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {chancenkarte.map((x) => (
                <li key={x.title} className="flex gap-x-3">
                  <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                  {x.title}
                  {/* <Tooltip>{x.tooltip}</Tooltip> */}
                </li>
              ))}
            </div>
          </div>
          <div className="-mt-2 p-2 xl:px-8 xl:py-20 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="h-full rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">One time fee, no other costs</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-7xl font-bold tracking-tight text-gray-900">0</span>
                  {/* <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span> */}
                  <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                </p>
                {/* <a
                  href="#"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get access
                </a> */}
                <button
                // disabled
                  type="button"
                  className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    window.location.href="https://calendly.com/migrationcheck/chancenkarte-finances-for-your-opportunity-card";
                  }}
                >
                  Register now
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  {/* This webinar is scheduled for 60 minutes */}
                  This online webinar takes place in zoom
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Crafting Compelling Applications: Your Key to Securing a Job in Germany */}
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <div className="flex gap-x-3">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">Crafting Compelling Applications:</h2>
              {/* <ColorSwatchIcon className="h-6 w-6" style={{ color: "#1bb55c33" }} /> */}
            </div>
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Your Key to Securing a Job in Germany</h3>
            <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-2">
                10:00 - 12:00 CET, Tuesday, October 1, 2024
              <Tooltip>{"CET Central European Time"}</Tooltip>
            </div>

            {/* <div className="mt-2 text-base leading-7 text-gray-600 flex gap-x-2">
                Place: Zoom
              <Tooltip>{"After your registration you will receive a dial-in link"}</Tooltip>
            </div> */}

            <div className="mt-2 text-base leading-7 text-gray-600 italic flex gap-x-2">
            For German recruiters to offer you a job, your applications have to be impressive! Join this webinar to understand the nuances of crafting applications in the German context.
            </div>

            <div className="mt-6 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What to Expect</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <div
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {CraftingCompellingApplicationsIncluded.map((x) => (
                <li key={x.title} className="flex gap-x-3">
                  <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                  {x.title}
                  {/* <Tooltip>{x.tooltip}</Tooltip> */}
                </li>
              ))}
            </div>
          </div>
          <div className="-mt-2 p-2 xl:px-8 xl:py-20 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="h-full rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">One time fee, no other costs</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-7xl font-bold tracking-tight text-gray-900">29</span>
                  {/* <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span> */}
                  <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                </p>
                {/* <a
                  href="#"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get access
                </a> */}
                <button
                // disabled
                  type="button"
                  className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    window.location.href="https://calendly.com/migrationcheck/crafting-compelling-applications-march";
                  }}
                >
                  Register now
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  {/* This webinar is scheduled for 60 minutes */}
                  This online webinar takes place in zoom
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Kickstart Your Career in the German IT Job Market */}
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <div className="flex gap-x-3">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">Kickstart Your Career in the German IT Job Market</h2>
              {/* <ColorSwatchIcon className="h-6 w-6" style={{ color: "#1bb55c33" }} /> */}
            </div>
            {/* <h3 className="text-2xl font-bold tracking-tight text-gray-900"> Your Gateway to Job Offers in Germany</h3> */}
            <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-2">
                08:00 - 10:00 CET, Wednesday, October 2, 2024
              <Tooltip>{"CET Central European Time"}</Tooltip>
            </div>

            {/* <div className="mt-2 text-base leading-7 text-gray-600 flex gap-x-2">
                Place: Zoom
              <Tooltip>{"After your registration you will receive a dial-in link"}</Tooltip>
            </div> */}

            <div className="mt-2 text-base leading-7 text-gray-600 italic flex gap-x-2">
            The German labor market offers incredibly good career prospects, especially in the IT sector. A great opportunity for you? An industry expert will elaborate.
            </div>

            <div className="mt-6 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What to Expect</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <div
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {GermanItJobMarketIncluded.map((x) => (
                <li key={x.title} className="flex gap-x-3">
                  <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                  {x.title}
                  {/* <Tooltip>{x.tooltip}</Tooltip> */}
                </li>
              ))}
            </div>
          </div>
          <div className="-mt-2 p-2 xl:px-8 xl:py-20 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="h-full rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">One time fee, no other costs</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-7xl font-bold tracking-tight text-gray-900">0</span>
                  {/* <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span> */}
                  <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                </p>
                {/* <a
                  href="#"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get access
                </a> */}
                <button
                  disabled
                  type="button"
                  className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    window.location.href="https://calendly.com/migrationcheck/howtokickstart356724578741";
                  }}
                >
                  Booked out
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  {/* This webinar is scheduled for 60 minutes */}
                  This online webinar takes place in zoom
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Mastering Speculative Applications: Your Gateway to Job Offers in Germany */}
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <div className="flex gap-x-3">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">Mastering Speculative Applications:</h2>
              {/* <ColorSwatchIcon className="h-6 w-6" style={{ color: "#1bb55c33" }} /> */}
            </div>
            <h3 className="text-2xl font-bold tracking-tight text-gray-900"> Your Gateway to Job Offers in Germany</h3>
            <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-2">
                11:00 - 14:00 CET, Wednesday, October 9, 2024
              <Tooltip>{"CET Central European Time"}</Tooltip>
            </div>

            {/* <div className="mt-2 text-base leading-7 text-gray-600 flex gap-x-2">
                Place: Zoom
              <Tooltip>{"After your registration you will receive a dial-in link"}</Tooltip>
            </div> */}

            <div className="mt-2 text-base leading-7 text-gray-600 italic flex gap-x-2">
            Are you ready to take control of your job search and proactively secure opportunities in the German job market? Don’t miss out on this opportunity!
            </div>

            <div className="mt-6 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What to Expect</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <div
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {MasteringSpeculativeApplicationsIncluded.map((x) => (
                <li key={x.title} className="flex gap-x-3">
                  <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                  {x.title}
                  {/* <Tooltip>{x.tooltip}</Tooltip> */}
                </li>
              ))}
            </div>
          </div>
          <div className="-mt-2 p-2 xl:px-8 xl:py-20 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="h-full rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">One time fee, no other costs</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-7xl font-bold tracking-tight text-gray-900">39</span>
                  {/* <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span> */}
                  <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                </p>
                {/* <a
                  href="#"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get access
                </a> */}
                <button
                  // disabled
                  type="button"
                  className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    window.location.href="https://calendly.com/migrationcheck/mastering-speculative-applications-march";
                  }}
                >
                  Register now
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  {/* This webinar is scheduled for 60 minutes */}
                  This online webinar takes place in zoom
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Earn and Learn: Unlocking Vocational Training Opportunities */}
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <div className="flex gap-x-3">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">Earn and Learn:</h2>
              {/* <ColorSwatchIcon className="h-6 w-6" style={{ color: "#1bb55c33" }} /> */}
            </div>
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Unlocking Vocational Training Opportunities</h3>
            <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-2">
                12:00 - 14:00 CET, Wednesday, October 23, 2024
              <Tooltip>{"CET Central European Time"}</Tooltip>
            </div>

            {/* <div className="mt-2 text-base leading-7 text-gray-600 flex gap-x-2">
                Place: Zoom
              <Tooltip>{"After your registration you will receive a dial-in link"}</Tooltip>
            </div> */}

            <div className="mt-2 text-base leading-7 text-gray-600 italic flex gap-x-2">
            Are you a motivated young person seeking to kickstart your career in Germany while gaining practical skills? This exclusive webinar is for you!
            </div>

            <div className="mt-6 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What to Expect</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <div
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {UnlockingVocationalIncluded.map((x) => (
                <li key={x.title} className="flex gap-x-3">
                  <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                  {x.title}
                  {/* <Tooltip>{x.tooltip}</Tooltip> */}
                </li>
              ))}
            </div>
          </div>
          <div className="-mt-2 p-2 xl:px-8 xl:py-20 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="h-full rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">One time fee, no other costs</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-7xl font-bold tracking-tight text-gray-900">0</span>
                  {/* <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span> */}
                  <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                </p>
                {/* <a
                  href="#"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get access
                </a> */}
                <button
                // disabled
                  type="button"
                  className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    window.location.href="https://calendly.com/migrationcheck/earn-and-learn-in-germany";
                  }}
                >
                  Register now
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  {/* This webinar is scheduled for 60 minutes */}
                  This online webinar takes place in zoom
                </p>
              </div>
            </div>
          </div>
        </div>  
        {/* First 100 Days in Germany */}
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <div className="flex gap-x-3">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">First 100 Days in Germany</h2>
              {/* <ColorSwatchIcon className="h-6 w-6" style={{ color: "#1bb55c33" }} /> */}
            </div>
            {/* <h3 className="text-2xl font-bold tracking-tight text-gray-900"> Your Gateway to Job Offers in Germany</h3> */}
            <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-2">
                TBD
              <Tooltip>{"CET Central European Time"}</Tooltip>
            </div>

            {/* <div className="mt-2 text-base leading-7 text-gray-600 flex gap-x-2">
                Place: Zoom
              <Tooltip>{"After your registration you will receive a dial-in link"}</Tooltip>
            </div> */}

            <div className="mt-2 text-base leading-7 text-gray-600 italic flex gap-x-2">
            Welcome to Germany! Embarking on a new chapter in a new country can be both exhilarating and challenging. Join this webinar to make your transition seamless.
            </div>

            <div className="mt-6 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What to Expect</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <div
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {FirstOneHundredIncluded.map((x) => (
                <li key={x.title} className="flex gap-x-3">
                  <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                  {x.title}
                  {/* <Tooltip>{x.tooltip}</Tooltip> */}
                </li>
              ))}
            </div>
          </div>
          <div className="-mt-2 p-2 xl:px-8 xl:py-20 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="h-full rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">One time fee, no other costs</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-7xl font-bold tracking-tight text-gray-900">0</span>
                  {/* <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span> */}
                  <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                </p>
                {/* <a
                  href="#"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get access
                </a> */}
                <button
                disabled
                  type="button"
                  className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    window.location.href="https://calendly.com/migrationcheck/first-100-days-in-germany-march";
                  }}
                >
                  not scheduled
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  {/* This webinar is scheduled for 60 minutes */}
                  This online webinar takes place in zoom
                </p>
              </div>
            </div>
          </div>
        </div>  
        {/* Job Interview Coaching  */}
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <div className="flex gap-x-3">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">Job Interview Coaching:</h2>
              {/* <ColorSwatchIcon className="h-6 w-6" style={{ color: "#1bb55c33" }} /> */}
            </div>
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Secure Your Dream Position</h3>
            <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-2">
                TBD
              <Tooltip>{"CET Central European Time"}</Tooltip>
            </div>

            {/* <div className="mt-2 text-base leading-7 text-gray-600 flex gap-x-2">
                Place: Zoom
              <Tooltip>{"After your registration you will receive a dial-in link"}</Tooltip>
            </div> */}

            <div className="mt-2 text-base leading-7 text-gray-600 italic flex gap-x-2">
            Are you ready to shine in your job interviews and land your dream position? Join this webinar to elevate your interview skills and impress recruiters.
            </div>

            <div className="mt-6 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What to Expect</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <div
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {JobInterviewIncluded.map((x) => (
                <li key={x.title} className="flex gap-x-3">
                  <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                  {x.title}
                  {/* <Tooltip>{x.tooltip}</Tooltip> */}
                </li>
              ))}
            </div>
          </div>
          <div className="-mt-2 p-2 xl:px-8 xl:py-20 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="h-full rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">One time fee, no other costs</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-7xl font-bold tracking-tight text-gray-900">29</span>
                  {/* <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span> */}
                  <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                </p>
                {/* <a
                  href="#"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get access
                </a> */}
                <button
                disabled
                  type="button"
                  className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    // window.location.href="https://calendly.com/migrationcheck/job-interview-coaching";
                  }}
                >
                  not scheduled
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  {/* This webinar is scheduled for 60 minutes */}
                  This online webinar takes place in zoom
                </p>
              </div>
            </div>
          </div>
        </div>

         


      </div>
    </div>
    <div className="bg-white">
    <Footer />
    </div>
    </>
  )
}
