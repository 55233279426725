import {
  BadgeCheckIcon,
  CalendarIcon,
  DesktopComputerIcon,
  ChatIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  ClockIcon,
  ExclamationCircleIcon,
  ExternalLinkIcon,
  SparklesIcon,
  TrendingUpIcon,
  UsersIcon,
} from "@heroicons/react/solid";
import { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import Moment from "react-moment";
import moment from "moment";
import SelectMigrationCheck from "../../modals/SelectMigrationCheck";
import Sale from "../../modals/Sale";
import Container from "../../components/Container";
import { ArrowCircleUpIcon, PlusIcon } from "@heroicons/react/outline";
import WriteReview from "../../components/WriteReview";
import ProgressProvider from "../../components/ProgressProvider";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { generateTextForScore } from "../../business/Helpers";
import Footer from "../../components/Footer";

export default function Dashboard() {
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [openSale, setOpenSale] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userResults, setUserResults] = useState(null);

  let location = useLocation();

  const loadUserResults = () => {
    fetch(`/api/user/results`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + auth.token,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => {
        setUserResults(result);
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  const sendRequest = () => {
    fetch(`/api/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => {
        setUserData(result);
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  useEffect(() => {
    if (auth.token) {
      sendRequest();
      loadUserResults();
    }
  }, [auth.token]);

  const bookConsultation = async () => {
    const response = await fetch(`/api/calendar/consultation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        auth.logout();
      } else {
        //when the response is technically ok, but when we return a 400er or 500er error back
        throw new Error(responseData.message); // due to throwing here an error we trigger the catch block (lec. 147)
      }
    }
    sendRequest(); // just to reload the page if booking on calendly is not performed
    window.location.href = `${responseData.bookingUrl}?utm_source=${responseData.bookingUrl}`;
  };

  const continueBookingConsultation = () => {
    window.location.href = `${userData.consultingBookingUrl}?utm_source=${userData.consultingBookingUrl}`;
  };

  // const joinConsultation = () => { };

  const bookCoaching = async () => {
    const response = await fetch(`/api/calendar/coaching`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
    });
    const responseData = await response.json();
    if (!response.ok) {
      if (response.status === 401) {
        auth.logout();
      } else {
        //when the response is technically ok, but when we return a 400er or 500er error back
        throw new Error(responseData.message); // due to throwing here an error we trigger the catch block (lec. 147)
      }
    }
    sendRequest(); // just to reload the page if booking on calendly is not performed
    window.location.href = `${responseData.bookingUrl}?utm_source=${responseData.bookingUrl}`;
  };

  const continueBookingCoaching = () => {
    window.location.href = `${userData.coachingBookingUrl}?utm_source=${userData.coachingBookingUrl}`;
  };

  // const joinCoaching = () => { };



  useEffect(() => {
    // console.log(location.state);
    if (location.state?.source == 'signup'){
      // console.log("hier")
      setTimeout(() => {
        setOpenSale(true);
      }, 2500);
    }
    window.history.replaceState({}, "");
  }, [])

  useEffect(() => {
    setTimeout(() => {
        setOpenSale(true);
    }, 2500);
  }, [])

  return (
    <>
      {userData && userResults && (
        <div className="flex flex-col min-h-[calc(100vh-4rem)]">
          <Container>
            <div className="py-10">
              <header>
                {userData.firstname && (
                  <h1 className="md:text-3xl text-2xl font-bold leading-tight text-gray-900">
                    {/* Welcome to Migration Check, {userData.firstname}! */}
                    Welcome to your personal dashboard, {userData.firstname}!
                  </h1>)}
                {!userData.firstname && (
                  <h1 className="md:text-3xl text-2xl font-bold leading-tight text-gray-900">
                    {/* Welcome to Migration Check, {userData.firstname}! */}
                    Welcome to your personal dashboard!
                  </h1>)}
              </header>
              <main>
                <div className="lg:grid lg:grid-cols-12 lg:gap-8 mt-8">
                  <div className="lg:col-span-4 2xl:col-span-3">
                    <div className="bg-white shadow rounded-lg px-4 py-5 sm:px-6 mb-6">
                      <div className="flex items-center m-1">
                        <div className="flex-shrink-0 h-12 w-12">
                          <img
                            className="h-12 w-12 rounded-full ring-2 ring-offset-2 ring-primary-600"
                            // src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&h=256&q=80"
                            src="/assets/images/avatar/ava17.png"
                            alt=""
                          />
                        </div>
                        <div className="ml-4 overflow-hidden">
                          <div className="text-lg leading-6 font-medium text-gray-900 truncate">
                            {userData.firstname} {userData.lastname}
                          </div>
                          <p className="text-sm font-medium text-gray-500 truncate">
                            {userData.email}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col mt-8">
                        {/* className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 w-full disabled:bg-primary-600/50 shake-animation" */}

                        <button
                          type="button"
                          className="inline-flex items-center justify-center font-medium text-sm leading-none px-6 py-[17px] text-white bg-primary-linear hover:bg-primary-linear-hover rounded-lg duration-150 focus:ring-4 ring-primary-100 ring-opacity-40 shake-animation disabled:animate-none disabled:bg-primary-linear-disabled"
                          onClick={() => setOpen(true)}
                          disabled={userData.quota <= 0}
                        >
                          <PlusIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                          />
                          New Detailed Check
                        </button>

                        {/* mt-3 inline-flex items-center justify-center px-6 py-3 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none */}
                        <Link
                          to="/upgrade"
                          // onClick={() => window.location.href="/upgrade"}
                          className="mt-3 inline-flex items-center justify-center px-6 py-3 border border-gray-300 text-sm font-medium rounded-lg border-primary-500 bg-white hover:bg-gray-50 focus:outline-none"
                        >
                          <ArrowCircleUpIcon
                            className="-ml-0.5 mr-2 h-4 w-4 text-primary-500"
                            aria-hidden="true"
                          />
                          <span className="bg-clip-text text-transparent bg-primary-linear">
                            Upgrade
                          </span>
                        </Link>
                        <Link
                          to="/webinars"
                          // onClick={() => window.location.href="/upgrade"}
                          className="sm:hidden mt-3 inline-flex items-center justify-center px-6 py-3 border border-gray-300 text-sm font-medium rounded-lg border-primary-500 bg-white hover:bg-gray-50 focus:outline-none"
                        >
                          <DesktopComputerIcon
                            className="-ml-0.5 mr-2 h-4 w-4 text-primary-500"
                            aria-hidden="true"
                          />
                          <span className="bg-clip-text text-transparent bg-primary-linear">
                            Webinar
                          </span>
                        </Link>
                      </div>
                      <div className="flex flex-col mt-8 text-sm text-gray-500 space-y-6">
                        <div className="flex items-center">
                          <BadgeCheckIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <div className="ml-2 font-medium">
                            <span className="font-semibold text-primary-500">
                              {userData.quota}
                            </span>{" "}
                            {userData.quota <= 1
                              ? "detailed check"
                              : "detailed checks"}
                          </div>
                        </div>
                        <div className="flex items-center">
                          <TrendingUpIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <div className="ml-2 font-medium">
                            <span className="font-semibold text-primary-500">
                              {userData.consultingQuota}
                            </span>{" "}
                            {userData.consultingQuota <= 1
                              ? "consulting session"
                              : "consulting sessions"}
                          </div>
                        </div>
                        <div className="flex items-center">
                          <UsersIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <div className="ml-2 font-medium">
                            <span className="font-semibold text-primary-500">
                              {userData.coachingQuota}
                            </span>{" "}
                            {userData.coachingQuota <= 1
                              ? "coaching session"
                              : "coaching sessions"}
                          </div>
                        </div>
                        <hr />
                        <div className="flex items-center">
                          <CalendarIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <div className="ml-2 font-medium">
                            registered&nbsp;
                            <Moment date={userData.createdOn} fromNow />
                          </div>
                        </div>
                        <div className="flex items-center">
                          <ClockIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <div className="ml-2 font-medium">
                            still active for&nbsp;
                            <Moment
                              date={userData.expireDate}
                              fromNow
                              ago
                            />{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 sm:px-6 hidden lg:block">
                      <WriteReview
                        userData={userData}
                        setUserData={setUserData}
                      />
                    </div>
                  </div>
                  <div className="lg:col-span-8 2xl:col-span-9 space-y-6">
                    <div className="bg-white shadow rounded-lg overflow-hidden">
                      <div className="border-b border-gray-200 py-5 px-4 sm:px-6">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <BadgeCheckIcon className="h-8 w-8 text-secondary-500" />
                          </div>
                          <div className="ml-4">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              My Detailed Migration-Checks
                            </h3>
                          </div>
                        </div>
                      </div>
                      <ul className="divide-y divide-gray-200">
                        {userResults.map((userResult) => (
                          <li key={userResult.id}>
                            <NavLink
                              to={
                                userResult.submissionDate
                                  ? `/user-results/${userResult.id}`
                                  : `/participate/${userResult.id}`
                              }
                              className="block hover:bg-gray-50"
                            >
                              <div className="flex items-center px-4 py-4 sm:px-6">
                                <div className="min-w-0 flex-1 flex items-center">
                                  <div className="min-w-0 flex-1 pr-4">
                                    <div className="relative group flex items-start space-x-4">
                                      <div className="flex-shrink-0">
                                        {userResult.migrationCheck.type ===
                                          0 && (
                                            <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg">
                                              {/* <BriefcaseIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                          /> */}
                                              <img
                                                src="assets/images/icons_purposes/work.svg"
                                                alt="work"
                                              />
                                            </span>
                                          )}
                                        {userResult.migrationCheck.type ===
                                          1 && (
                                            <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg">
                                              {/* <AcademicCapIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                          /> */}
                                              <img
                                                src="assets/images/icons_purposes/study.svg"
                                                alt="study"
                                              />
                                            </span>
                                          )}
                                        {userResult.migrationCheck.type ===
                                          2 && (
                                            <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg">
                                              {/* <SupportIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                          /> */}
                                              <img
                                                src="assets/images/icons_purposes/volunteer.svg"
                                                alt="volunteer"
                                              />
                                            </span>
                                          )}
                                        {userResult.migrationCheck.type ===
                                          3 && (
                                            <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg">
                                              {/* <PresentationChartBarIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                          /> */}
                                              <img
                                                src="assets/images/icons_purposes/business.svg"
                                                alt="business"
                                              />
                                            </span>
                                          )}
                                        {userResult.migrationCheck.type ===
                                          4 && (
                                            <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg">
                                              {/* <UserGroupIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                          /> */}
                                              <img
                                                src="assets/images/icons_purposes/joinfamily.svg"
                                                alt="join family"
                                              />
                                            </span>
                                          )}
                                        {userResult.migrationCheck.type ===
                                          5 && (
                                            <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg">
                                              {/* <HeartIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                          /> */}
                                              <img
                                                src="assets/images/icons_purposes/marriage.svg"
                                                alt="marriage"
                                              />
                                            </span>
                                          )}
                                        {userResult.migrationCheck.type ===
                                          6 && (
                                            <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg">
                                              {/* <GiftIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                          /> */}
                                              <img
                                                src="assets/images/icons_purposes/apprenticeship.svg"
                                                alt="apprenticeship"
                                              />
                                            </span>
                                          )}
                                        {userResult.migrationCheck.type ===
                                          7 && (
                                            <span className="inline-flex items-center justify-center h-10 w-10 rounded-lg">
                                              {/* <GiftIcon
                                            className="h-6 w-6 text-white"
                                            aria-hidden="true"
                                          /> */}
                                              <img
                                                src="assets/images/icons_purposes/au_pair.svg"
                                                alt="au pair"
                                              />
                                            </span>
                                          )}
                                      </div>
                                      <div className="min-w-0 flex-1">
                                        <div className="space-y-2 lg:space-y-0 lg:grid lg:grid-cols-7 lg:gap-4">
                                          <div className="col-span-3">
                                            <div className="text-sm font-medium text-gray-1500">
                                              {userResult.migrationCheck.title}
                                            </div>
                                            <p className="text-sm text-gray-500">
                                              {userResult.submissionDate &&
                                                userResult.migrationCheck.description?.replaceAll(
                                                  "{result}",
                                                  generateTextForScore(
                                                    (userResult.points * 100) /
                                                    userResult.maxPoints, userResult.migrationCheck.title
                                                  )
                                                )}
                                              {!userResult.submissionDate &&
                                                "Complete your check to see results and recommendations."}
                                            </p>
                                          </div>
                                          <div className="flex items-center lg:col-span-2">
                                            {!userResult.submissionDate && (
                                              <span className="flex items-center">
                                                <ExclamationCircleIcon
                                                  className="flex-shrink-0 h-5 w-5 text-yellow-500"
                                                  aria-hidden="true"
                                                />
                                                <span className="ml-2 text-sm font-medium text-gray-500">
                                                  In progress
                                                </span>
                                              </span>
                                            )}
                                            {userResult.submissionDate && (
                                              <span className="flex items-center">
                                                <CheckCircleIcon
                                                  className="flex-shrink-0 h-5 w-5 text-green-500"
                                                  aria-hidden="true"
                                                />
                                                <span className="ml-2 text-sm font-medium text-gray-500">
                                                  completed
                                                </span>
                                              </span>
                                            )}
                                          </div>
                                          <div className="flex items-center lg:col-span-2">
                                            {!userResult.submissionDate && (
                                              <div className="flex items-center">
                                                <div className="w-5 h-5">
                                                  <ProgressProvider
                                                    valueStart={0}
                                                    valueEnd={0}
                                                  >
                                                    {(value) => (
                                                      <CircularProgressbar
                                                        value={value}
                                                        strokeWidth={12}
                                                        styles={buildStyles({
                                                          pathColor: `#ff6f91`,
                                                          textColor: "#ff6f91",
                                                          trailColor: "#e5e7eb",
                                                        })}
                                                      />
                                                    )}
                                                  </ProgressProvider>
                                                </div>
                                                <span className="ml-2 text-sm text-gray-400">
                                                  not completed
                                                </span>
                                              </div>
                                            )}
                                            {userResult.submissionDate && (
                                              <div className="flex items-center">
                                                <div className="w-5 h-5">
                                                  <ProgressProvider
                                                    valueStart={0}
                                                    valueEnd={Math.round(
                                                      (userResult.points *
                                                        100) /
                                                      userResult.maxPoints
                                                    )}
                                                  >
                                                    {(value) => (
                                                      <CircularProgressbar
                                                        value={value}
                                                        strokeWidth={12}
                                                        styles={buildStyles({
                                                          pathColor: `#ff6f91`,
                                                          textColor: "#ff6f91",
                                                          trailColor: "#e5e7eb",
                                                        })}
                                                      />
                                                    )}
                                                  </ProgressProvider>
                                                </div>
                                                <span className="ml-2 text-sm text-gray-500 font-medium">
                                                  {generateTextForScore(
                                                    userResult.points, userResult.migrationCheck.title
                                                  )}
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="pl-2">
                                  <ChevronRightIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </div>
                              </div>
                            </NavLink>
                          </li>
                        ))}
                        {userResults.length === 0 && (
                          <div className="flex flex-col items-center p-6">
                            <div className="p-6">
                              <img
                                className="max-w-[128px] h-auto"
                                src="assets/images/no-migration-check.svg"
                                alt=""
                              />
                            </div>
                            <span className="text-xs text-gray-500">
                              Click on the "New Detailed Check" button to get
                              started!
                            </span>
                          </div>
                        )}
                      </ul>
                    </div>
                    {userData.consultingStartTime &&
                      moment(userData.consultingStartTime).isAfter() && (
                        <div className="text-sm text-gray-500">
                          <div
                            // type="button"
                            className="block w-full text-left bg-white hover:bg-gray-50 shadow rounded-lg px-4 py-5 sm:px-6"
                            // onClick={joinConsultation}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <div className="flex-shrink-0">
                                  <TrendingUpIcon className="h-8 w-8 text-primary-500" />
                                </div>
                                <div className="ml-4">
                                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Path Finding Consultation
                                  </h3>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {/* <Moment
                                      date={moment(
                                        userData.consultingStartTime
                                      )}
                                      fromNow
                                      ago
                                    /> */}
                                    You've scheduled your consulting session: {moment(userData.consultingStartTime).format('MMMM Do YYYY, h:mm:ss a')}{", "}Central European Time (CET)
                                  </p>
                                </div>
                              </div>
                              <div className="pl-2">
                                <CalendarIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {(!userData.consultingStartTime ||
                      !moment(userData.consultingStartTime).isAfter()) &&
                      userData.consultingBookingUrl && (
                        <div className="text-sm text-gray-500">
                          <button
                            type="button"
                            className="block w-full text-left bg-white hover:bg-gray-50 shadow rounded-lg px-4 py-5 sm:px-6"
                            onClick={continueBookingConsultation}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <div className="flex-shrink-0">
                                  <TrendingUpIcon className="h-8 w-8 text-primary-500" />
                                </div>
                                <div className="ml-4">
                                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Path Finding Consultation
                                  </h3>
                                  <p className="mt-1 text-sm text-gray-500">
                                    Schedule your time slot
                                  </p>
                                </div>
                              </div>
                              <div className="pl-2">
                                <ChevronRightIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </button>
                        </div>
                      )}
                    {(!userData.consultingStartTime ||
                      !moment(userData.consultingStartTime).isAfter()) &&
                      !userData.consultingBookingUrl &&
                      userData.consultingQuota > 0 && (
                        <div className="text-sm text-gray-500">
                          <button
                            type="button"
                            className="block w-full text-left bg-white hover:bg-gray-50 shadow rounded-lg px-4 py-5 sm:px-6"
                            onClick={bookConsultation}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <div className="flex-shrink-0">
                                  <TrendingUpIcon className="h-8 w-8 text-primary-500" />
                                </div>
                                <div className="ml-4">
                                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Path-Finding Consultation
                                  </h3>
                                  <p className="mt-1 text-sm text-gray-500">
                                    Select an appointment here to meet with your
                                    personal consultant.
                                  </p>
                                </div>
                              </div>
                              <div className="pl-2">
                                <ChevronRightIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </button>
                        </div>
                      )}
                    {(!userData.consultingStartTime ||
                      !moment(userData.consultingStartTime).isAfter()) &&
                      !userData.consultingBookingUrl &&
                      userData.consultingQuota === 0 && (
                        <div className="text-sm text-gray-500">
                          <NavLink
                            to="/buy/pathfindingconsultation"
                            className="block w-full text-left bg-white hover:bg-gray-50 shadow rounded-lg px-4 py-5 sm:px-6"
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <div className="flex-shrink-0">
                                  <TrendingUpIcon className="h-8 w-8 text-primary-500" />
                                </div>
                                <div className="ml-4">
                                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Path-Finding Consultation
                                  </h3>
                                  <p className="mt-1 text-sm text-gray-500">
                                    A session with us can help you discover the
                                    best migration option for your specific
                                    needs.
                                  </p>
                                </div>
                              </div>
                              <div className="pl-2">
                                <ChevronRightIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      )}

                    {userData.coachingStartTime &&
                      moment(userData.coachingStartTime).isAfter() && (
                        <div className="text-sm text-gray-500">
                          <div
                            // type="button"
                            className="block w-full text-left bg-white hover:bg-gray-50 shadow rounded-lg px-4 py-5 sm:px-6"
                            // onClick={joinCoaching}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <div className="flex-shrink-0">
                                  <SparklesIcon className="h-8 w-8 text-primary-500" />
                                </div>
                                <div className="ml-4">
                                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Visa Interview Coaching
                                  </h3>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {/* <Moment
                                      date={moment(userData.coachingStartTime)}
                                      fromNow
                                      ago
                                    /> */}
                                    {/* {moment().format("MMM Do YY")} */}
                                    You've scheduled your coaching session: {moment(userData.coachingStartTime).format('MMMM Do YYYY, h:mm:ss a')}{", "}Central European Time (CET)
                                  </p>
                                </div>
                              </div>
                              <div className="pl-2">
                                <CalendarIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {(!userData.coachingStartTime ||
                      !moment(userData.coachingStartTime).isAfter()) &&
                      userData.coachingBookingUrl && (
                        <div className="text-sm text-gray-500">
                          <button
                            type="button"
                            className="block w-full text-left bg-white hover:bg-gray-50 shadow rounded-lg px-4 py-5 sm:px-6"
                            onClick={continueBookingCoaching}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <div className="flex-shrink-0">
                                  <SparklesIcon className="h-8 w-8 text-primary-500" />
                                </div>
                                <div className="ml-4">
                                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Visa Interview Coaching
                                  </h3>
                                  <p className="mt-1 text-sm text-gray-500">
                                    Schedule your time slot
                                  </p>
                                </div>
                              </div>
                              <div className="pl-2">
                                <ChevronRightIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </button>
                        </div>
                      )}
                    {(!userData.coachingStartTime ||
                      !moment(userData.coachingStartTime).isAfter()) &&
                      !userData.coachingBookingUrl &&
                      userData.coachingQuota > 0 && (
                        <div className="text-sm text-gray-500">
                          <button
                            type="button"
                            className="block w-full text-left bg-white hover:bg-gray-50 shadow rounded-lg px-4 py-5 sm:px-6"
                            onClick={bookCoaching}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <div className="flex-shrink-0">
                                  <SparklesIcon className="h-8 w-8 text-primary-500" />
                                </div>
                                <div className="ml-4">
                                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Visa Interview Coaching
                                  </h3>
                                  <p className="mt-1 text-sm text-gray-500">
                                    Book an appointment here to meet our visa
                                    interview coach.
                                  </p>
                                </div>
                              </div>
                              <div className="pl-2">
                                <ChevronRightIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </button>
                        </div>
                      )}
                    {(!userData.coachingStartTime ||
                      !moment(userData.coachingStartTime).isAfter()) &&
                      !userData.coachingBookingUrl &&
                      userData.coachingQuota === 0 && (
                        <div className="text-sm text-gray-500">
                          <NavLink
                            to="/buy/visainterviewcoachingw0352467"
                            className="block w-full text-left bg-white hover:bg-gray-50 shadow rounded-lg px-4 py-5 sm:px-6"
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <div className="flex-shrink-0">
                                  <UsersIcon className="h-8 w-8 text-primary-500" />
                                </div>
                                <div className="ml-4">
                                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Visa Interview Coaching
                                  </h3>
                                  <p className="mt-1 text-sm text-gray-500">
                                    The five Ps of success: Proper preparation
                                    prevents poor performance. We can assist you
                                    in preparing for your visa interview.
                                  </p>
                                </div>
                              </div>
                              <div className="pl-2">
                                <ChevronRightIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </NavLink>
                        </div>
                      )}
                    <a
                      href="https://migration-check.com/blog/"
                      className="block bg-white hover:bg-gray-50 shadow rounded-lg px-4 py-5 sm:px-6"
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <ChatIcon className="h-8 w-8 text-primary-500" />
                          </div>
                          <div className="ml-4">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Our Blog
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                              You want to stay updated on information about your
                              big move? Then check out our blog for all the
                              latest news and articles. You may also want to
                              subscribe to our newsletter to make sure you don't
                              miss any new developments or updates that might
                              affect your plans.
                            </p>
                          </div>
                        </div>
                        <div className="pl-2">
                          <ExternalLinkIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </a>
                    <div className="px-4 sm:px-6 block lg:hidden">
                      <WriteReview
                        userData={userData}
                        setUserData={setUserData}
                      />
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </Container>
          <div className="mt-auto">
            <Footer />
          </div>
          {/* {moment(userData.createdOn).add(5, "M").isBefore(new Date()) && (
            <SelectMigrationCheck open={true} setOpen={setOpen} />
          )} */}
        </div>
      )}
      {/* {userData?.quota === 0 && <Sale openSale={openSale} setOpenSale={setOpenSale} /> } */}
      {userResults?.length === 1 && !userData?.premium && <Sale openSale={openSale} setOpenSale={setOpenSale} /> }
      <SelectMigrationCheck open={open} setOpen={setOpen} />

   

      

      {/* <ErrorModal
        showErrorModal={!!error}
        handleCloseErrorModal={handleCloseErrorModal}
        error={error}
                                    />*/}
    </>
  );
}
