export default function footer() {
  return (
    <footer>
      <div className="container mx-auto">
        <div className="border-t border-gray-900/10 py-2">
          <p className="text-xs text-center leading-5 text-gray-500">&copy; Copyright 2024, All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  )
}