import { Fragment, useRef, useState, useContext, useReducer, useEffect } from 'react'
import { PencilIcon, MailIcon} from "@heroicons/react/outline";
import { Dialog, Transition } from '@headlessui/react'
import { useNavigate } from "react-router-dom"
import {
  BadgeCheckIcon,
  CalendarIcon,
  DesktopComputerIcon,
  ChatIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  ClockIcon,
  ExclamationCircleIcon,
  ExternalLinkIcon,
  SparklesIcon,
  TrendingUpIcon,
  UserIcon,
  XIcon,
  ShareIcon
} from "@heroicons/react/solid";
import { AuthContext } from "../context/auth-context";
import {
  validate,
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH
} from "../form-validation/validators";

const nameReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const ageReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const messageReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const languageReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const loginFormReducer = (state, action) => {
  switch (action.type) {
    case "INPUTCHANGE":
      // console.log('We are here')
      let formIsValid = true;
      for (const input in state.inputs) {
        if (input === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[input].isValid;
        }
      }
      // console.log(formIsValid)
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: { value: action.value, isValid: action.isValid },
        },
        isValid: formIsValid,
      };
    default:
      return state;
  }
};


export default function ConnectMe(props) {
  const auth = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(false);

  const sendRequest = () => {
    fetch(`/api/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => {
        setUserData(result);
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  useEffect(() => {
    sendRequest()
  }, []);

  const cancelButtonRef = useRef(null)
  const [nameState, dispatchname] = useReducer(nameReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });
  const [ageState, dispatch] = useReducer(ageReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });
  const [qualificationState, dispatcher] = useReducer(messageReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });
  const [languageState, dispatcherlang] = useReducer(languageReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });

  const [loginFormState, dispatching] = useReducer(loginFormReducer, {
    inputs: {
      name: { value: "", isValid: false },
      age: { value: "", isValid: false },
      qualification: { value: "", isValid: false },
      language: { value: "", isValid: false },
    },
    isValid: false,
  });

  // console.log(loginFormState);

  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: ageState.value,
      isValid: ageState.isValid,
      inputId: "age",
    });
  }, [ageState.value, ageState.isValid]);
  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: qualificationState.value,
      isValid: qualificationState.isValid,
      inputId: "qualification",
    });
  }, [qualificationState.value, qualificationState.isValid]);

  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: languageState.value,
      isValid: languageState.isValid,
      inputId: "language",
    });
  }, [languageState.value, languageState.isValid]);

  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: nameState.value,
      isValid: nameState.isValid,
      inputId: "name",
    });
  }, [nameState.value, nameState.isValid]);

  const nameChangeHandler = (event) => {
    // console.log(event.target.value)
    dispatchname({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_REQUIRE()],
    });
  };

  const ageChangeHandler = (event) => {
    // console.log(event.target.value)
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_REQUIRE()],
    });
  };
  const qualificationChangeHandler = (event) => {
    // console.log(event.target.value)
    dispatcher({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_MINLENGTH(4)],
    });
  };

  const languageChangeHandler = (event) => {
    // console.log(event.target.value)
    dispatcherlang({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_MINLENGTH(4)],
    });
  };

  const nameTouchHandler = () => {
    dispatchname({ type: "TOUCH" });
  };
  const ageTouchHandler = () => {
    dispatch({ type: "TOUCH" });
  };
  const qualificationTouchHandler = () => {
    dispatcher({ type: "TOUCH" });
  };
  const languageTouchHandler = () => {
    dispatcherlang({ type: "TOUCH" });
  };


  

  const loginFormSubmitHandler = async (event) => {
    event.preventDefault();
    // console.log('This is what we send to the server: ', loginFormState.inputs);
    try {
      const response = await fetch("/api/users/connectme", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // JSON.stringify() Converts/parses Data to JSON
          email: userData.email,
          age: loginFormState.inputs.age.value,
          language: loginFormState.inputs.language.value,
          qualification: loginFormState.inputs.qualification.value,
          name: loginFormState.inputs.name.value,
        }),
      });
    
      const responseData = await response.json(); // Transforms/parses the data from json to
      // console.log("responseData: ", responseData)
      if (!response.ok) {

        throw new Error(responseData.message); // due to throwing here an error we trigger the catch block (lec. 147)
      }

      props.setOpenConnectMe(false);
      props.setShowNoti(true);
    } catch (error) {
      console.log(error);

      setError(error.message);
    }

  };

  return (
    <Transition.Root show={props.openConnectMe} as={Fragment}>
      <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={props.setOpenConnectMe}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
               
              {userData &&
              <>
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                          onClick={() => props.setOpenConnectMe(false)}
                        >
                          <span className="sr-only">Close</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                </div>
                <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              {/* <img src="/assets/images/logo.svg" onClick={redirectToHomepage} className="cursor-pointer h-[21px]"/> */}
              <div className="mx-auto flex h-100 w-100 items-center">
                <img
                  className='object-none w-24 h-24 rounded-full ring-2 ring-offset-2 ring-primary-600 opacity-70'
                  src="/assets/images/connect5.svg"
                  alt=""
                />

                
              
                <h2 className="text-3xl font-extrabold text-gray-900 ml-4">
                Connect me with recruiters
                </h2>
                {/* <ShareIcon className="h-12 w-12 mr-12" aria-hidden="true" color='#ff6f91'/> */}
              </div>
              <p className="text-sm text-gray-400 mt-3">
              Please provide the following information to complete your profile. Be sure you include only relevant work experience.
              </p>
              <br />
            </div>

            <div>
              <div className="">
                <form onSubmit={loginFormSubmitHandler} className="space-y-5">
                  {/* Email */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      E-Mail-Adresse
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="text"
                        className="shadow-sm focus:ring-primary-500 focus:border-rose-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
                        placeholder="E-mail-address"
                        value={userData.email}
                        disabled={true}
                      />
                    </div>
                  </div>
                    {/* Name */}
                    <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Your Name
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <UserIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="name"
                        name="name"
                        autoComplete="false"
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="Name"
                        onChange={nameChangeHandler}
                        onBlur={nameTouchHandler}
                        value={nameState.value}
                        isInvalid={
                          !nameState.isValid && nameState.isTouched
                        }
                      />
                    </div>
                    {!nameState.isValid && nameState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please enter your name
                      </p>
                    )}
                  </div>
                  {/* Age */}
                  <div>
                    <label
                      htmlFor="age"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Your Age
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <CalendarIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="age"
                        name="age"
                        autoComplete="false"
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="29 years"
                        onChange={ageChangeHandler}
                        onBlur={ageTouchHandler}
                        value={ageState.value}
                        isInvalid={
                          !ageState.isValid && ageState.isTouched
                        }
                      />
                    </div>
                    {!ageState.isValid && ageState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please enter your age
                      </p>
                    )}
                  </div>


                  {/* Textarea */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Your Qualifications
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <PencilIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <textarea
                        id="textarea"
                        name="textarea"
                        rows={4}
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="I have a (e.g B.Sc.) in (e.g. Computer Science) and have gained five years of work experience in (e.g. web development and IT security)."
                        onChange={qualificationChangeHandler}
                        onBlur={qualificationTouchHandler}
                        value={qualificationState.value}
                        isInvalid={
                          !qualificationState.isValid && qualificationState.isTouched
                        }
                      ></textarea>
                    </div>
                    {!qualificationState.isValid && qualificationState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please enter your qualifications please
                      </p>
                    )}
                  </div>

                  {/* Textarea */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Your Language Competence
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <PencilIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <textarea
                        id="textarea"
                        name="textarea"
                        rows={2}
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="German (good), English (native speaker)"
                        onChange={languageChangeHandler}
                        onBlur={languageTouchHandler}
                        value={languageState.value}
                        isInvalid={
                          !languageState.isValid && languageState.isTouched
                        }
                      ></textarea>
                    </div>
                    {!languageState.isValid && languageState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please enter your qualifications please
                      </p>
                    )}
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={!loginFormState.isValid}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                    >
                      Send
                    </button>
                  </div>
                </form>

              </div>
            </div>
              </div>
              </>}
                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}