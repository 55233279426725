import { ChevronLeftIcon } from "@heroicons/react/solid";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { AuthContext } from "../../context/auth-context";
import MigrationCheckSummary from "../../components/MigrationCheckSummary";
import parse from 'html-react-parser';

export default function MigrationCheck(props) {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [userResult, setUserResult] = useState(null);
  const [question, setQuestion] = useState(null);
  const [index, setIndex] = useState(-1);
  const { id } = useParams();

  const loadUserResult = () => {
    fetch(`/api/user-results/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((userResult) => {
        setUserResult(userResult);

        if (userResult.questions.length === 0) {
          // load first question
          loadQuestion(userResult, userResult.migrationCheck.questionId);
        } else {
          // resume from last attempt
          const selectedAnswer = userResult.questions[
            userResult.questions.length - 1
          ].answers.find((x) => x.selected === true);
          if (selectedAnswer.targetId) {
            loadQuestion(userResult, selectedAnswer.targetId);
          } else {
            setIndex(userResult.questions.length);
          }
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });;
  };

  const loadQuestion = (userResult, questionId, action) => {
    fetch(`/api/questions/${questionId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => {
        setQuestion(result);
        setIndex(userResult.questions.length);

        action?.();
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });;
  };

  const onAnswerClicked = (question, answer) => {
    const questionIndex = userResult.questions.findIndex(
      (x) => x.id === answer.targetId
    );
    if (questionIndex > 0) {
      // clicked already selected answer
      setIndex(questionIndex);
    } else {
      fetch(`/api/user-results/${userResult.id}/user-answers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify({ answerId: answer.id, index: index }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }

          return Promise.reject(res);
        })
        .then((_) => {
          userResult.questions.splice(index);
          userResult.questions.push(question);

          question.answers.forEach((x) => (x.selected = false));
          answer.selected = true;

          setUserResult({ ...userResult });
          setQuestion(null);

          if (answer.targetId) {
            loadQuestion(userResult, answer.targetId, () => {
              // nach dem state change, damit kein "selected" erscheint bei der animation
            });
          } else {
            question.answers.forEach((x) => (x.selected = false));
            answer.selected = true;

            setQuestion(null);
            setIndex(index + 1);
          }
        })
        .catch((err) => {
          if (err.status === 401) {
            auth.logout();
          }
        });;
    }
  };

  const finishUserResult = () => {
    fetch(`/api/user-results/${userResult.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => {
        navigate(`/user-results/${userResult.id}`);
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  const onBackClicked = () => {
    if (index > 0) {
      setIndex(index - 1);
    } else {
      navigate("/");
    }
  };

  const getPage = () => {
    if (index < userResult.questions.length) {
      return [userResult.questions[index]];
    } else {
      return [question ? question : { id: "summary" }];
    }
  };

  useEffect(() => {
    loadUserResult();
    return () => {
      props.setProgress(0);
      props.setSection("");
    };
  }, []);

  useEffect(() => {
    if (question) {
      const passed = userResult.questions
        .flatMap((x) => x.answers)
        .map((x) => x.selected)
        .filter(Boolean).length;
      const progress = (passed * 100) / (passed + question.distance);
      props.setProgress(progress > 0 ? progress : 1);
      props.setSection(question.section);
    }
  }, [question]);

  return (
    <>
      {index > -1 && (
        <>
          <TransitionGroup>
            {getPage().map((x) => (
              <CSSTransition key={x.id} classNames="fade" timeout={500}>
                <div>
                  {(index < userResult.questions.length || question) && (
                    <div className="max-w-[880px] mx-auto px-4 sm:px-6 lg:px-8 py-20">
                      <header>
                        <button
                          className="group"
                          onClick={() => onBackClicked()}
                        >
                          <div className="flex items-center">
                            <ChevronLeftIcon
                              className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                            <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                              {index > 0 ? "previous question" : "Dashboard"}
                            </span>
                          </div>
                        </button>
                        <div>Question {index + 1}</div>
                        <h1 className="mt-2 text-3xl font-bold leading-tight text-gray-900">
                          {x.title}
                        </h1>
                        <h1 className="mt-2 text-xl leading-tight text-gray-500">
                          {parse(x.description)}
                        </h1>
                      </header>
                      <main className="mt-12">
                        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2 mt-8">
                          {x.answers.map((answer) => (
                            <button
                              key={answer.id}
                              className={`transition inline-flex items-center justify-center py-6 text-base font-medium rounded-md focus:outline-none ${
                                answer.selected
                                  ? "bg-primary-500 hover:bg-primary-600 text-white"
                                  : "text-gray-700 border border-2 border-rose-700/40 hover:border-rose-700"
                              }`}
                              onClick={() => onAnswerClicked(x, answer)}
                            >
                              {answer.title}
                            </button>
                          ))}
                        </div>
                      </main>
                    </div>
                  )}
                  {index === userResult.questions.length && !question && (
                    <MigrationCheckSummary
                      userResult={userResult}
                      setProgress={props.setProgress}
                      setSection={props.setSection}
                      onBackClicked={onBackClicked}
                      finishUserResult={finishUserResult}
                    ></MigrationCheckSummary>
                  )}
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </>
      )}
    </>
  );
}
