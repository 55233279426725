import { PencilIcon, MailIcon, XCircleIcon, CheckCircleIcon } from "@heroicons/react/outline";
import React, { useEffect, useReducer, useContext, useState } from "react";
import IntroduceMePublic from "../../modals/IntroduceMePublic";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { Link } from "react-router-dom";
import { ChevronRightIcon, SparklesIcon } from "@heroicons/react/solid";

import {
  validate,
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH
} from "../../form-validation/validators";




const loginCheckTerms = (state, action) => {
  // console.log(action);
  switch (action.type) {
    case "CHANGE":
      return { ...state, isValid: action.val };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const loginFormReducer = (state, action) => {
  switch (action.type) {
    case "INPUTCHANGE":
      // console.log('We are here')
      let formIsValid = true;
      for (const input in state.inputs) {
        if (input === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[input].isValid;
        }
      }
      // console.log(formIsValid)
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: { value: action.value, isValid: action.isValid },
        },
        isValid: formIsValid,
      };
    default:
      return state;
  }
};

function Introducemepublic(props) {

  const [page, setPage] = useState(1);
  const [openIntroduceMe, setOpenIntroduceMe] = useState(false);

  let navigate = useNavigate();


  const [checkboxState, dispatchCheckTerms] = useReducer(loginCheckTerms, {
    isValid: false,
    isTouched: false,
  });

  const [loginFormState, dispatching] = useReducer(loginFormReducer, {
    inputs: {
      check: { isValid: false },
    },
    isValid: false,
  }); //here: isValid die gesamte Form

  const [error, setError] = useState(false);
  const handleCloseErrorModal = () => {
    setError(null);
  };


  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      isValid: checkboxState.isValid,
      inputId: "check",
    });
  }, [checkboxState.isValid]);



  const checkTermsChangeHandler = (event) => {
    dispatchCheckTerms({
      type: "CHANGE",
      val: event.target.checked,
    });
  };

  const checkTermsTouchHandler = () => {
    dispatchCheckTerms({ type: "TOUCH" });
  };


  const redirectToHomepage = () => {
    window.location.href = 'https://migration-check.com';
  }


  return (
    <>

      <div className="min-h-screen flex bg-gray-100">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          {page === 1 ? (<div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img src="/assets/images/logo.svg" onClick={redirectToHomepage} className="cursor-pointer h-[21px]"/>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                EARN AND LEARN
              </h2>
              {/* <h3 className="mb-4 mt-2">
                Here you can send us a message, question or feedback
              </h3> */}
              <p className="text-sm text-gray-400 mt-3">
                Here you can register to be introduced to companies for vocational training opportunities.
              </p>
              <br />
            </div>

            {error && (
              <div className="rounded-md bg-red-100 p-4 mt-6">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-red-800">{error}</p>
                  </div>
                </div>
              </div>
            )}

            <div>
              <div className="mt-4">
                <form onSubmit={()=>{}} className="space-y-10">


                  {/* Checkbox accept Terms of Use */}
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="check"
                        name="check"
                        type="checkbox"
                        className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                        // checked={checkedTerms}
                        onChange={checkTermsChangeHandler}
                        onBlur={checkTermsTouchHandler}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="check" className="text-gray-500">
                        By clicking Start, I confirm that I have
                        read and understood the{" "}
                        <a
                          className="text-primary-600 no-underline hover:underline"
                          href="https://migration-check.com/terms/"
                          target="_blank"
                        >
                          terms of use
                        </a>{" "} and the{" "}
                        <a
                          className="text-primary-600 no-underline hover:underline"
                          href="https://migration-check.com/privacy-policy/"
                          target="_blank"
                        >
                          privacy policy
                        </a>{" "}
                        and agree to both of them.
                      </label>
                      <p>
                        {!checkboxState.isValid &&
                          checkboxState.isTouched && (
                            <p className="mt-1 text-xs text-red-600">
                              Please accept our Terms and Conditions to
                              continue!
                            </p>
                          )}
                      </p>
                    </div>
                  </div>


                  <div>
                    <button
                      type="button"
                      onClick={() => { setOpenIntroduceMe(true) }}
                      disabled={!loginFormState.isValid}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                    >
                      Start
                    </button>
                  </div>
                </form>


              </div>
            </div>
            {/* Page 2 */}
          </div>) : (
            <>
              <div className="mx-auto w-full max-w-sm lg:w-96">
                <img className="h-[21px]" src="/assets/images/logo.svg" />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  EARN AND LEARN
                </h2>
                <div className="rounded-md bg-green-100 p-4 mt-6">
                <div className="flex">
                  <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="flex-shrink-0 h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">You have successfully submitted your information.</p>
                  </div>
                </div>
              </div>
               
                {/* <h3 className="mb-4 mt-2">
                  You have successfully sent your message to MigrationCheck.
                </h3> */}
                {/* <p className="text-sm text-gray-400">
                  We will come back to you as soon as possible.
                </p>
                <br />
                <p className="text-sm text-gray-400">
                  We also send you a copy of your message to your email.
                </p>
                <br />
                <p className="text-sm text-gray-400">
                  We look forward to seeing you!
                </p> */}
                <br />
                <button
                  type="button"
                  onClick={redirectToHomepage}
                  className="w-full mt-1 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                >
                  Go back to homepage
                </button>
              </div>
            </>
          )}
        </div>
        <div className="hidden lg:block relative w-0 flex-1 bg-linear-primary-3-1 shadow-inner">
          <img
            className="absolute inset-0 h-full w-full object-contain object-bottom"
            src="/assets/images/loginmigrationcheck.png"
            alt="migrationcheck germany"
          />
        </div>
      </div>
      <IntroduceMePublic openConnectMe={openIntroduceMe} setOpenConnectMe={setOpenIntroduceMe} setPage={setPage} />
      {/* <IntroduceMePublic open={openIntroduceMe} setOpen={setOpenIntroduceMe} /> */}
    </>
  );
}

export default Introducemepublic;
