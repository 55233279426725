import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AuthContext } from "../context/auth-context";
import { StarIcon } from "@heroicons/react/solid";

export default function CreateUserModal(props) {
  const auth = useContext(AuthContext);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (props.editUser) {
      setUser({
        email: props.editUser.email,
        password: "******",
        quota: props.editUser.quota,
        firstname: props.editUser.firstname,
        lastname: props.editUser.lastname,
      });
    } else {
      setUser({
        email: "",
        password: "",
        quota: "",
        firstname: "",
        lastname: "",
      });
    }
  }, [props.editUser]);

  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (props.editUser) {
      if (user.password === "******") {
        user.password = undefined;
      }

      fetch(`/api/users/${props.editUser.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify(user),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }

          return Promise.reject(res);
        })
        .then((_) => props.setOpen(false))
        .catch((err) => {
          if (err.status === 401) {
            auth.logout();
          }
        });
    } else {
      fetch(`/api/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
        body: JSON.stringify(user),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }

          return Promise.reject(res);
        })
        .then((_) => props.setOpen(false))
        .catch((err) => {
          if (err.status === 401) {
            auth.logout();
          }
        });
    }
  };

  const deleteUser = () => {
    fetch(`/api/users/${props.editUser.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((_) => props.setOpen(false))
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                {user && (
                  <form onSubmit={handleSubmit}>
                    <h2 className="text-lg font-medium text-gray-900">
                      {props.editUser
                        ? "Benutzer bearbeiten"
                        : "Neuer Benutzer"}
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      {props.editUser
                        ? "Bearbeite den Benutzer"
                        : "Erstelle einen neuen Benutzer"}
                    </p>
                    <div className="mt-6 py-6 border-t border-b space-y-3">
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          E-Mail-Adresse
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            name="email"
                            type="text"
                            className="shadow-sm focus:ring-primary-500 focus:border-rose-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="E-mail-address"
                            value={user.email}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Passwort
                        </label>
                        <div className="mt-1">
                          <input
                            id="password"
                            name="password"
                            type="text"
                            className="shadow-sm focus:ring-primary-500 focus:border-rose-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Password"
                            value={user.password}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="quota"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Quota
                        </label>
                        <div className="mt-1">
                          <input
                            id="quota"
                            name="quota"
                            type="text"
                            className="shadow-sm focus:ring-primary-500 focus:border-rose-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Quota"
                            value={user.quota}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="firstname"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Vorname
                        </label>
                        <div className="mt-1">
                          <input
                            id="firstname"
                            name="firstname"
                            type="text"
                            className="shadow-sm focus:ring-primary-500 focus:border-rose-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Firstname"
                            value={user.firstname}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="firstname"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Nachname
                        </label>
                        <div className="mt-1">
                          <input
                            id="lastname"
                            name="lastname"
                            type="text"
                            className="shadow-sm focus:ring-primary-500 focus:border-rose-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Lastname"
                            value={user.lastname}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      {props.editUser && props.editUser.stars && (
                        <>
                          <div className="flex-shrink-0 flex">
                            {props.editUser.stars &&
                              Array.from({ length: 5 }, (_, i) => (
                                <StarIcon
                                  key={i}
                                  className={`h-5 w-5 ${
                                    props.editUser.stars > i
                                      ? "text-yellow-400"
                                      : "text-gray-300"
                                  }`}
                                />
                              ))}
                          </div>

                          <p className="text-sm text-gray-500">
                            {props.editUser.review}
                          </p>

                          <p className="text-sm text-gray-500">
                            {props.editUser.reviewPublicly
                              ? "Public review"
                              : "Private review"}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="flex itmems-center justify-between">
                      <div>
                        {props.editUser && (
                          <button
                            type="button"
                            className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                            onClick={deleteUser}
                          >
                            Löschen
                          </button>
                        )}
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                        >
                          Speichern
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
