import {
  ArrowRightIcon,
  ChevronRightIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { useContext, useEffect, useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import CreateMigrationCheckModal from "../../modals/CreateMigrationCheck";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../context/auth-context";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function AdminMigrationCheck() {
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [migrationCheck, setMigrationCheck] = useState(null);
  const [changes, setChanges] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const questionRefs = useRef([]);

  let { id } = useParams();

  const loadMigrationCheck = (action) => {
    fetch(`/api/migration-checks/${id}`, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    }) // path parameter
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => {
        setMigrationCheck(result);
        setChanges(false);
        action?.();
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  const updateQuestions = () => {
    setUpdateLoading(true);
    fetch(`/api/migration-checks/${id}/questions`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
      body: JSON.stringify(migrationCheck.questions),
    })
      .then((res) => {
        if (res.ok) {
          reloadMigrationCheck();
        } else {
          setTimeout(async () => {
            alert(await res.text());
          });
        }
      })
      .finally(() => setUpdateLoading(false));
  };

  const reloadMigrationCheck = (action) => loadMigrationCheck(action);

  const createQuestion = () => {
    const tempId = `temp-${uuidv4()}`;
    const question = {
      id: tempId,
      section: "",
      name: "",
      title: "",
      description: "",
      type: "RADIO",
      distance: 0,
      answers: [],
      origins: [],
    };
    migrationCheck.questions.push(question);

    setMigrationCheck({
      ...migrationCheck,
      questions: [...migrationCheck.questions],
    });
    setChanges(true);
    setTimeout(() => {
      moveToQuestion(questionRefs.current[questionRefs.current.length - 1]);
      setEditId(tempId);
    });
  };

  const handleQuestionChange = (question, field, value) => {
    question[field] = value;
    setMigrationCheck({ ...migrationCheck });
    setChanges(true);
  };

  const deleteQuestion = (question) => { 
    // remove targetId from precursor
    migrationCheck.questions.flatMap(question => question.answers).forEach(x => {
      if(x.targetId === question.id) {
        x.targetId = null;
      }
    })
    
    // remove origins array
    migrationCheck.questions.forEach(x => {
      x.origins = x.origins.filter(y => !question.answers.some(z => z.id === y.id))
    })

    // remove question and answers array, no need to remove sourceId since its the already deleted question
    migrationCheck.questions = migrationCheck.questions.filter(x => x.id !== question.id)
    setMigrationCheck({ ...migrationCheck });
    setChanges(true);
  };

  const createAnswer = (question) => {
    const tempId = `temp-${uuidv4()}`;
    const answer = {
      id: tempId,
      title: "",
      description: "",
      points: 1,
      sourceId: question.id,
    };
    question.answers.push(answer);
    setMigrationCheck({ ...migrationCheck });
    setChanges(true);
  };

  const handleAnswerChange = (answer, field, value) => {
    // console.log("lets go", answer, field, value);

    if (field === "targetId") {
      // clear old origin
      if (answer[field]) {
        let question = getQuestionById(answer[field]);
        // console.log("question:", question);
        question.origins = question.origins.filter(x => x.id !== answer.id)
      }

      if (value) {
        // add origi
        let question = getQuestionById(value);
        question.origins.push(answer);
      }
    }

    answer[field] = value;

    setMigrationCheck({ ...migrationCheck });
    setChanges(true);
  };

  const deleteAnswer = (question, answer) => {
    // remove origin array
    // console.log(migrationCheck.questions);
    migrationCheck.questions.forEach(x => {
      x.origins = x.origins.filter(y => y.id !== answer.id)
    })

    // remove answer from array, no need to update source or target anywhere
    question.answers = question.answers.filter(x => x.id !== answer.id)

    setMigrationCheck({ ...migrationCheck });
    setChanges(true);
  };

  useEffect(() => {
    loadMigrationCheck();
  }, []);

  const getQuestionById = (questionId) => {
    return migrationCheck.questions.find((x) => x.id === questionId);
  };

  const getNameForQuestionId = (questionId) => {
    const question = migrationCheck.questions.find((x) => x.id === questionId);
    if (question) {
      return question.name;
    } else {
      return "";
    }
  };

  const getTitleForQuestionId = (questionId) => {
    const question = migrationCheck.questions.find((x) => x.id === questionId);
    if (question) {
      return question.title;
    } else {
      return "";
    }
  };

  const getQuestionRefById = (questionId) => {
    return questionRefs.current[
      migrationCheck.questions.findIndex((x) => x.id === questionId)
    ];
  };

  const moveToQuestion = (element) => {
    if (element) {
      window.scrollTo(0, element.offsetTop);
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    reorder(
      migrationCheck.questions,
      result.source.index,
      result.destination.index
    );

    setMigrationCheck({ ...migrationCheck });
    setChanges(true);
  };

  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
  };

  return (
    <>
      {migrationCheck && (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
          <header className="flex justify-between items-center">
            <div>
              <nav className="flex" aria-label="Breadcrumb">
                <ol role="list" className="flex items-center space-x-4">
                  <li>
                    <div>
                      <NavLink
                        to="/admin/migration-checks"
                        className="text-sm font-medium text-gray-500 hover:text-gray-700"
                      >
                        Migration Checks
                      </NavLink>
                    </div>
                  </li>
                  <li>
                    <div className="flex items-center">
                      <ChevronRightIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-4 text-sm font-medium text-gray-700">
                        {migrationCheck.title}
                      </span>
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 className="mt-2 text-3xl font-bold leading-tight text-gray-900">
                {migrationCheck.title}{" "}
                <span>({migrationCheck.questions.length})</span>
              </h1>
              <p className="mt-1 text-sm font-medium text-gray-500">
                {migrationCheck.description}
              </p>
            </div>
            <div>
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => setEditId(null)}
              >
                Auswahl zurücksetzen
              </button>
              <button
                type="button"
                className="ml-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                onClick={() => setOpen(true)}
              >
                Bearbeiten
              </button>
              <button
                type="button"
                className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700"
                onClick={() => createQuestion()}
              >
                Neue Frage
              </button>
              <button
                type="button"
                disabled={!changes || updateLoading}
                className="relative ml-2 inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm bg-primary-600 hover:bg-primary-700 disabled:bg-primary-600/50"
                onClick={() => updateQuestions()}
              >
                {updateLoading && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                )}
                Änderungen speichern
              </button>
            </div>
          </header>
          <main>
            <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-md">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      className="divide-y divide-gray-200"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {migrationCheck.questions.map(
                        (question, questionIndex) => (
                          <Draggable
                            key={question.id}
                            draggableId={question.id}
                            index={questionIndex}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div
                                  ref={(x) =>
                                    (questionRefs.current[questionIndex] = x)
                                  }
                                  onClick={() => {
                                    setEditId(question.id);
                                  }}
                                  className={`relative block w-full ${
                                    question.id === editId
                                      ? "bg-gray-50"
                                      : "cursor-pointer bg-white hover:bg-gray-50"
                                  }`}
                                >
                                  {question.id === editId && (
                                    <div className="absolute left-0 top-0 bottom-0 w-1 bg-primary-600"></div>
                                  )}
                                  <div className="px-4 py-4 sm:px-6">
                                    <div className="flex items-center justify-between">
                                      <p className="text-sm truncate">
                                        <span className="text-gray-500 italic">
                                          {question.section}
                                        </span>
                                        <span> - </span>
                                        <span className="font-semibold text-primary-600">
                                          {question.name}
                                        </span>
                                        <span> - </span>
                                        <span className="text-gray-500">
                                          {question.title}
                                        </span>
                                      </p>
                                      {!changes && (
                                        <div className="ml-2 flex-shrink-0 flex space-x-2">
                                          {question.id ===
                                            migrationCheck.question?.id && (
                                            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                              root question
                                            </p>
                                          )}
                                          <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                            max incl.: {question.distance}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                    {question.id === editId && (
                                      <div className="mt-3">
                                        <div className="grid grid-cols-6 gap-2">
                                          <div className="col-span-6 sm:col-span-2">
                                            <input
                                              type="text"
                                              name="section"
                                              className="focus:ring-primary-500 focus:border-rose-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                              value={question.section}
                                              placeholder="Abschnitt"
                                              onChange={(e) =>
                                                handleQuestionChange(
                                                  question,
                                                  "section",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="col-span-6 sm:col-span-2">
                                            <input
                                              type="text"
                                              name="name"
                                              className="focus:ring-primary-500 focus:border-rose-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                              value={question.name}
                                              placeholder="Name"
                                              onChange={(e) =>
                                                handleQuestionChange(
                                                  question,
                                                  "name",
                                                  e.target.value
                                                )
                                              }
                                              autoFocus
                                            />
                                          </div>
                                          <div className="col-span-6 sm:col-span-2">
                                            <input
                                              type="text"
                                              name="title"
                                              className="focus:ring-primary-500 focus:border-rose-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                              value={question.title}
                                              placeholder="Titel (Angezeigte Überschrift)"
                                              onChange={(e) =>
                                                handleQuestionChange(
                                                  question,
                                                  "title",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </div>
                                        </div>

                                        <textarea
                                          name="description"
                                          rows={3}
                                          className="mt-2 shadow-sm focus:ring-primary-500 focus:border-rose-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                                          placeholder="Beschreibung"
                                          value={question.description}
                                          onChange={(e) =>
                                            handleQuestionChange(
                                              question,
                                              "description",
                                              e.target.value
                                            )
                                          }
                                        />

                                        <div className="flex">
                                          <button
                                            type="button"
                                            className="mt-2 ml-auto inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700"
                                            onClick={() =>
                                              deleteQuestion(question)
                                            }
                                          >
                                            <TrashIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </button>
                                        </div>

                                        <hr className="mt-4" />

                                        {question.origins &&
                                          !!question.origins.length && (
                                            <div className="mt-2">
                                              <div className="text-xs mb-1">
                                                Vorherige Antwortoptionen
                                              </div>
                                              <div className="d-flex space-x-2">
                                                {question.origins.map(
                                                  (answer) => (
                                                    <button
                                                      key={answer.id}
                                                      type="button"
                                                      className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                      onClick={() => {
                                                        moveToQuestion(
                                                          getQuestionRefById(
                                                            answer.sourceId
                                                          )
                                                        );
                                                        setTimeout(() => {
                                                          setEditId(
                                                            answer.sourceId
                                                          );
                                                        });
                                                      }}
                                                    >
                                                      {getNameForQuestionId(
                                                        answer.sourceId
                                                      )}{" "}
                                                      -{" "}
                                                      {getTitleForQuestionId(
                                                        answer.sourceId
                                                      )}
                                                      : {answer.title}
                                                    </button>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          )}

                                        <hr className="mt-4" />

                                        <div className="mt-4 space-y-2">
                                          <div className="flex items-center">
                                            <input
                                              id={`${question.id}-type-radio`}
                                              name="type"
                                              type="radio"
                                              className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300"
                                              value="RADIO"
                                              checked={
                                                question.type === "RADIO"
                                              }
                                              onChange={(e) =>
                                                handleQuestionChange(
                                                  question,
                                                  "type",
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <label
                                              htmlFor={`${question.id}-type-radio`}
                                              className="ml-3 block text-sm font-medium text-gray-700"
                                            >
                                              Radiobuttons
                                            </label>
                                          </div>
                                          <div className="flex items-center">
                                            <input
                                              id={`${question.id}-type-select`}
                                              name="type"
                                              type="radio"
                                              className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300"
                                              value="SELECT"
                                              checked={
                                                question.type === "SELECT"
                                              }
                                              onChange={(e) =>
                                                handleQuestionChange(
                                                  question,
                                                  "type",
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <label
                                              htmlFor={`${question.id}-type-select`}
                                              className="ml-3 block text-sm font-medium text-gray-700"
                                            >
                                              Dropdown
                                            </label>
                                          </div>
                                        </div>
                                        {question.answers &&
                                          !!question.answers.length &&
                                          question.answers.map(
                                            (answer, answerIndex) => (
                                              <div key={answer.id}>
                                                <div className="mt-3 flex items-center">
                                                  <div>
                                                    <div className="text-xs mb-1">
                                                      Antwortmöglichkeit{" "}
                                                      {answerIndex + 1}
                                                    </div>
                                                    <div className="grid grid-cols-6 gap-2">
                                                      <div className="col-span-6 sm:col-span-3">
                                                        <input
                                                          type="text"
                                                          name="title"
                                                          className="focus:ring-primary-500 focus:border-rose-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                                          value={answer.title}
                                                          placeholder="Titel"
                                                          onChange={(e) =>
                                                            handleAnswerChange(
                                                              answer,
                                                              "title",
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col-span-6 sm:col-span-1">
                                                        <input
                                                          name="points"
                                                          className="focus:ring-primary-500 focus:border-rose-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                                                          type="number"
                                                          placeholder="Punkte"
                                                          value={answer.points}
                                                          onChange={(e) =>
                                                            handleAnswerChange(
                                                              answer,
                                                              "points",
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col-span-6 sm:col-span-2">
                                                        <select
                                                          name="targetId"
                                                          className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-rose-500 sm:text-sm"
                                                          value={
                                                            answer.targetId
                                                          }
                                                          onChange={(e) =>
                                                            handleAnswerChange(
                                                              answer,
                                                              "targetId",
                                                              e.target.value
                                                            )
                                                          }
                                                        >
                                                          <option value={""}>
                                                            *keine*
                                                          </option>
                                                          {migrationCheck.questions
                                                            .filter(
                                                              (option) =>
                                                                option.id !==
                                                                question.id
                                                            )
                                                            .map((option) => (
                                                              <option
                                                                key={option.id}
                                                                value={
                                                                  option.id
                                                                }
                                                              >
                                                                {option.name} -{" "}
                                                                {option.title}
                                                              </option>
                                                            ))}
                                                        </select>
                                                      </div>
                                                    </div>

                                                    <textarea
                                                      name="description"
                                                      rows={3}
                                                      className="mt-2 shadow-sm focus:ring-primary-500 focus:border-rose-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                                                      value={answer.description}
                                                      placeholder="Beschreibung bei der Auswertung"
                                                      onChange={(e) =>
                                                        handleAnswerChange(
                                                          answer,
                                                          "description",
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="pl-4">
                                                    <button
                                                      type="button"
                                                      className={`inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white ${
                                                        answer.targetId
                                                          ? "bg-primary-600 hover:bg-primary-700"
                                                          : "bg-primary-600/50"
                                                      }`}
                                                      disabled={
                                                        !answer.targetId
                                                      }
                                                      onClick={() => {
                                                        moveToQuestion(
                                                          getQuestionRefById(
                                                            answer.targetId
                                                          )
                                                        );
                                                        setTimeout(() => {
                                                          setEditId(
                                                            answer.targetId
                                                          );
                                                        });
                                                      }}
                                                    >
                                                      <ArrowRightIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="mt-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700"
                                                      onClick={() =>
                                                        deleteAnswer(
                                                          question,
                                                          answer
                                                        )
                                                      }
                                                    >
                                                      <TrashIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        <div className="mt-4">
                                          <button
                                            type="button"
                                            className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700"
                                            onClick={() =>
                                              createAnswer(question)
                                            }
                                          >
                                            <PlusIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </main>

          <CreateMigrationCheckModal
            open={open}
            setOpen={(e) => {
              setOpen(e);
              reloadMigrationCheck();
            }}
            editMigrationCheck={migrationCheck}
          />
        </div>
      )}
    </>
  );
}
