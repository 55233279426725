// import { KeyIcon, MailIcon, XCircleIcon } from "@heroicons/react/outline";
import React, { useEffect, useReducer, useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { Link, useParams } from "react-router-dom";
// import { ChevronRightIcon, SparklesIcon } from "@heroicons/react/solid";



function BuyPackage(props) {
    const auth = useContext(AuthContext);
    let navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [item, setItem] = useState(null);

    // const refresh = () => {
    //     window.location.reload(true)
    // }

    // const AVAILABLEPRODUCTS = ["basic", "standard", "premium", "guide"]
    const AVAILABLEPRODUCTS = ["basic","standard", "premium", "standardplus", "maximize", "pathfindingconsultation", "visainterviewcoachingw0352467", "special"]; // ACHTUNG: Hier nur die Pakete angegeben, die bei FS hinterlegt sind!

    const { mcpackage } = useParams();

    // console.log("In den buyPackages: ", mcpackage);
    // console.log(item);

    // const [error, setError] = useState(false);
    // const handleCloseErrorModal = () => {
    //     setError(null);
    // };

    // console.log(AVAILABLEPRODUCTS.includes(mcpackage));

    // Läd den angemeldeten User aus der DB
    const sendRequest = () => {
        fetch(`/api/user`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }

                return Promise.reject(res);
            })
            .then((result) => {
                setUserData(result);
            })
            .catch((err) => {
                if (err.status === 401) {
                    auth.logout();
                }
            });
    };
    // Prüft den path parameter, ob das Produkt verfügbar ist
    const checkPathParameter = () => {
        if (!AVAILABLEPRODUCTS.includes(mcpackage)){
            // console.log("Not Included");
            navigate("/");
        }
    }

    useEffect(() => {
        checkPathParameter();
        sendRequest();
    }, [auth.token]);


    useEffect(() => {
        const fastSpringCallBack = (data) => {
            /* Iterate over payload to find whatever information is relevant
              For this example we'll want to display localized price of
              the product 'demo-product-4'
              */
            if (data && data.groups) {
                // console.log("The data: ", data);
                data.groups.forEach((group) => {
                    if (group.items && Array.isArray(group.items)) {
                        group.items.forEach((item) => {
                            // console.log("The itemdata: ", item);
                            if (item.path === mcpackage) {
                                // Update local state to add localized product information
                                setItem(item);
                            }
                        });
                    }
                });
            }
        };

        // Add SBL script programatically
        const addSBL = () => {
            const scriptId = "fsc-api";
            const existingScript = document.getElementById(scriptId);
            if (!existingScript) {
                // const storeFrontToUse = "migrationcheck.test.onfastspring.com/popup-mytest";
                const storeFrontToUse = "migrationcheck.onfastspring.com/popup-mytest";
                const script = document.createElement("script");

                script.type = "text/javascript";
                script.id = scriptId;
                script.src = "https://sbl.onfastspring.com/sbl/0.9.5/fastspring-builder.min.js";
                script.dataset.storefront = storeFrontToUse;
                // Make sure to add callback function to window so that the DOM is aware of it
                window.fastSpringCallBack = fastSpringCallBack;
                window.closedFSWindow = closedFSWindow;
                window.dataPopupWebhookReceived = dataPopupWebhookReceived;
                script.setAttribute("data-data-callback", "fastSpringCallBack");
                script.setAttribute("data-popup-closed", "closedFSWindow");
                script.setAttribute("data-popup-webhook-received", "dataPopupWebhookReceived");

                document.body.appendChild(script);
            }
        };
        if (AVAILABLEPRODUCTS.includes(mcpackage)){
            addSBL();
        }
    }, []);

    // darf nur 1x ausgeführt werden
    // useEffect(()=> {
    //     if (item) {
    //         buyProduct();
    //     }
    // },[])

    const closedFSWindow = () => {
        setItem(null);
        // console.log("FS Window closed"); // triggert beim schließen des Kauffensters (also kaufabbruch) und bei Thank u for your order
        const FSelements = document.getElementById("fsc-api");
        // console.log(FSelements);
        if (FSelements) {
            FSelements.remove();
        }
        navigate("/");
    }

    const dataPopupWebhookReceived = (data) => {
        // console.log("data: ", data)
        setItem(null);
        const FSelements = document.getElementById("fsc-api");
        if (FSelements) {
            FSelements.remove();
        }
        // console.log(data.payment.type)
        // console.log(data.payment)
        if (data.payment.type == 'paypal') {
            console.log("successful purchase via paypal");
            setTimeout(() => {
                console.log("We redirect...");
                window.location.href = 'https://app.migration-check.com';
              }, 4000);

        } else {
            console.log("successful purchase");
            setTimeout(() => {
                console.log("We navigte further");
                navigate("/");
              }, 3000);
        }

    }

    // Es triggert nichts, wenn der paypal kauf abgebrochen wurde


    const buyProduct = () => {
        // console.log("we are buying....!");
        window.fastspring.builder.recognize({
            'email': userData.email,
            'firstname': "",
            'lastname' : ""
        });
        window.fastspring.builder.tag({ 'email': userData.email });
        window.fastspring.builder.push({
            products: [
                {
                    path: item.path,
                    quantity: 1
                }
            ],
            checkout: true
        });
    };

    if (item && userData) {
        buyProduct();
        // setItem(null);
        // navigate("/");    // Verursacht den Fehler: Bug: too hard to fix "Cannot update a component from inside the function body of a different component." #18178  https://github.com/facebook/react/issues/18178 
    }

    return (
        <>
            <div className="min-h-screen flex bg-gray-100">
                <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <img className="h-[21px]" src="/assets/images/logo.svg" alt="MigrationCheck Logo"/>
                            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                                Preparing...
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="hidden lg:block relative w-0 flex-1 bg-linear-primary-3-1 shadow-inner">
                    <img
                        className="absolute inset-0 h-full w-full object-contain object-bottom"
                        src="/assets/images/loginmigrationcheck.png"
                        alt="migrationcheck germany"
                    />
                </div>
            </div>
        </>
    );
}

export default BuyPackage;
