import { KeyIcon, MailIcon, XCircleIcon, UserIcon } from "@heroicons/react/outline";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useReducer, useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { ChevronRightIcon, SparklesIcon, CheckCircleIcon } from "@heroicons/react/solid";

import {
  validate,
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_PASSWORD,
} from "../../form-validation/validators";

const loginEmailReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const loginPWReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const loginCheckTerms = (state, action) => {
  // console.log(action);
  switch (action.type) {
    case "CHANGE":
      return { ...state, isValid: action.val };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const loginFormReducer = (state, action) => {
  switch (action.type) {
    case "INPUTCHANGE":
      // console.log('We are here')
      let formIsValid = true;
      for (const input in state.inputs) {
        if (input === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[input].isValid;
        }
      }
      // console.log(formIsValid)
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: { value: action.value, isValid: action.isValid },
        },
        isValid: formIsValid,
      };
    default:
      return state;
  }
};

function Signup(props) {
  const [suStep, setsuStep] = useState(0);
  const [code, setCode] = useState("");
  const [notification, setNotification] = useState (false);
  const [verifyCodeButton, setVerifyCodeButton] = useState(false);
  const [showReSendCodeButton, setShowReSendCodeButton] = useState(false);
  const [firstname, setFirstName] = useState("");
  // const [checkedTerms, setCheckedTerms] = React.useState(false);

  // const handleChangeTerms = () => {
  //   setCheckedTerms(!checkedTerms);
  // };
  const { mcpackage } = useParams();

  // console.log("Package in signup: ", mcpackage);

  const changeCode = (event) => {
    setCode(event.target.value);
    // console.log(code.length);
    if (event.target.value.length > 2) {
      setVerifyCodeButton(true);
    } else {
      setVerifyCodeButton(false);
    }
  };

  // console.log("suStep", suStep);

  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [signupEmailState, dispatch] = useReducer(loginEmailReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });
  const [loginPWState, dispatcher] = useReducer(loginPWReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });
  const [checkboxState, dispatchCheckTerms] = useReducer(loginCheckTerms, {
    isValid: false,
    isTouched: false,
  });
  // console.log(checkboxState);
  const [loginFormState, dispatching] = useReducer(loginFormReducer, {
    inputs: {
      email: { value: "", isValid: false },
      pw: { value: "", isValid: false },
      check: { isValid: false },
    },
    isValid: false,
  }); //here: isValid die gesamte Form?

  const [error, setError] = useState(false);
  const handleCloseErrorModal = () => {
    setError(null);
  };

  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: signupEmailState.value,
      isValid: signupEmailState.isValid,
      inputId: "email",
    });
  }, [signupEmailState.value, signupEmailState.isValid]);
  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: loginPWState.value,
      isValid: loginPWState.isValid,
      inputId: "pw",
    });
  }, [loginPWState.value, loginPWState.isValid]);

  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      isValid: checkboxState.isValid,
      inputId: "check",
    });
  }, [checkboxState.isValid]);

  const emailChangeHandler = (event) => {
    // console.log(event.target.value)
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()],
    });
  };
  const passwordChangeHandler = (event) => {
    // console.log(event.target.value)
    dispatcher({
      type: "CHANGE",
      val: event.target.value,
      // validators: [VALIDATOR_PASSWORD()],
      validators: [VALIDATOR_MINLENGTH(8)],
    });
  };
  const checkTermsChangeHandler = (event) => {
    // console.log(event.target.checked)
    dispatchCheckTerms({
      type: "CHANGE",
      val: event.target.checked,
      // validators: [VALIDATOR_REQUIRE()],
    });
  };
  const firstnameChangeHandler = (event) => {
    // console.log(event.target.value)
    setFirstName(event.target.value);
  };
  const emailTouchHandler = () => {
    dispatch({ type: "TOUCH" });
  };
  const pwTouchHandler = () => {
    dispatcher({ type: "TOUCH" });
  };
  const checkTermsTouchHandler = () => {
    dispatchCheckTerms({ type: "TOUCH" });
  };

  const activateReSendButtonAfterSeconds = () => {
    const timer = setTimeout(() => {
      // console.log('This will run after 30 second once we re-render!')
      setShowReSendCodeButton(true);
    }, 30010);
    return () => clearTimeout(timer);
  };
  
  const loginFormSubmitHandler = async (event) => {   // Achtung, hier wird die gesamte Form zum Backend gesendet und ein User wird angelegt sofern alles i.O. sowie eingeloggt
    // console.log("Hier senden wir das gesamte Formular ab");
    event.preventDefault();
    // console.log("This is what we want send to the server: ", loginFormState.inputs, code, firstname);
    try {
      const response = await fetch("/api/users/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // JSON.stringify() Converts/parses Data to JSON
          email: loginFormState.inputs.email.value,
          password: loginFormState.inputs.pw.value,
          code: code,
          firstname
        }),
      });
      // console.log(response);
      const responseData = await response.json(); // Transforms/parses the data from json to
      // console.log(response.ok)
      if (!response.ok) {
        // without that check we reach the catch-blog only when we dont get a response at all (technical error)-> so we have to check if we get an error response-code (like 401->authenticaten failed)(lec.147):
        // console.log(responseData.message)
        throw new Error(responseData.message); // due to throwing here an error we trigger the catch block
      }
      //Achtung: Folgendes nur, wenn erfolgreich eingeloggt!
      auth.login(responseData);
      // console.log(mcpackage);
      if (mcpackage === undefined){
        // navigate("/");
        navigate("/", {
          state: {
            source: 'signup',
          }
        });
      } else {
        navigate(`/buy/${mcpackage}`);
      }
      // auth.login(responseData.userId, responseData.token);
    } catch (error) {
      console.log(error);
      // setError(err.message || 'Something went wrong, please try again');
      // console.log(error.message)
      setError(error.message);
    }
  };

  const sendCodetoMail = async () => { // ACHTUNG: Hier muss der Backend Request erfolgen um einen Code per Mail zu versenden
    setShowReSendCodeButton(false);
    activateReSendButtonAfterSeconds();
    // console.log("Wir senden die folgende email zum Server: ", signupEmailState.value);
    // console.log("Wir senden die folgende email zum Server: ", loginFormState.inputs.email.value)
    try {
      const response = await fetch("/api/users/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ // JSON.stringify() Converts/parses Data to JSON
          email: loginFormState.inputs.email.value,
          mcpackage
        }),
      });
      // console.log(response);
      const responseData = await response.json(); // Transforms/parses the data from json to
      // console.log(responseData)
      // console.log(response.ok)
      if (!response.ok) {
        // without that check we reach the catch-blog only when we dont get a response at all (technical error)-> so we have to check if we get an error response-code (like 401->authenticaten failed):
        // console.log(responseData.message)
        throw new Error(responseData.message); // due to throwing here an error we trigger the catch block
      }
      //Achtung: Folgendes nur, wenn request erfolgreich!
      nextStep();
    } catch (error) {
      console.log(error);
      // setError(err.message || 'Something went wrong, please try again');
      // console.log(error.message)
      setError(error.message);
    }
  };

  const sendCodetoMailagain = async (event) => {  // ACHTUNG: Hier muss der Backend Request erfolgen um einen Code per Mail zu versenden!!!
    // event.preventDefault();
    setNotification(false);
    handleCloseErrorModal();
    setShowReSendCodeButton(false);
    activateReSendButtonAfterSeconds();
    // console.log("Wir senden die folgende email zum Server: ", loginFormState.inputs.email.value);
    try {
      const response = await fetch("/api/users/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ // JSON.stringify() Converts/parses Data to JSON
          email: loginFormState.inputs.email.value,
          mcpackage
        }),
      });
      // console.log(response);
      const responseData = await response.json(); // Transforms/parses the data from json to
      // console.log(responseData)
      // console.log(response.ok)
      if (!response.ok) {
        // without that check we reach the catch-blog only when we dont get a response at all (technical error)-> so we have to check if we get an error response-code (like 401->authenticaten failed):
        // console.log(responseData.message)
        throw new Error(responseData.message); // due to throwing here an error we trigger the catch block
      }
      setNotification(true);
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  const verifyCode = async (event) => {  // ACHTUNG: Hier muss der Backend Request erfolgen um den Code zu verifizieren
    // event.preventDefault();
    // console.log("Wir schicken foldenden code zum Backend: ", code);
    try {
      const response = await fetch("/api/users/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ // JSON.stringify() Converts/parses Data to JSON
          email: loginFormState.inputs.email.value,
          code: code
        }),
      });
      // console.log(response);
      const responseData = await response.json(); // Transforms/parses the data from json to
      // console.log(responseData)
      // console.log(response.ok)
      if (!response.ok) {
        // without that check we reach the catch-blog only when we dont get a response at all (technical error)-> so we have to check if we get an error response-code (like 401->authenticaten failed):
        // console.log(responseData.message)
        throw new Error(responseData.message); // due to throwing here an error we trigger the catch block
      }
      //Achtung: Folgendes nur, wenn request erfolgreich!
      if (responseData.valid) {
        nextStep();
      }
      else {
        setVerifyCodeButton(false);
        throw new Error("Invalid verification code!");
      }
    } catch (error) {
      console.log(error);
      // setError(err.message || 'Something went wrong, please try again');
      // console.log(error.message)
      setError(error.message);
      setCode("");
      setNotification(false);
    }
  };

  const startSignupProcess = (event) => { // Zum Restarten des Signup Flows, z.B. zum Ändern der Email Adresse
    // event.preventDefault();
    navigate("/signup");
    setsuStep(0);
    handleCloseErrorModal();
  };

  const nextStep = () => {    // Nächste Seite im Signup flow
    handleCloseErrorModal();
    setNotification(false);
    // activateReSendButtonAfterSeconds()
    setsuStep(suStep + 1);
  };

  const redirectToHomepage = () => {
    window.location.href = 'https://migration-check.com';
  }

  return (
    <>
      <div className="min-h-screen flex bg-gray-100">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                alt="Logo MigrationCheck"
                onClick={redirectToHomepage}
                className="cursor-pointer h-[21px]"
                src="/assets/images/logo.svg"
              />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Please sign up
              </h2>
            </div>

            <p className="text-sm text-gray-400 mt-1">
              You need to have an account to get access
            </p>

            {error && (
              <div className="rounded-md bg-red-100 p-4 mt-6">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-red-800">{error}</p>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-8">
              <div className="mt-6">
                <form onSubmit={loginFormSubmitHandler} className="space-y-6">
                  {/* Email Input */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MailIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="on"
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm disabled:bg-gray-200"
                        placeholder="johndoe@gmail.com"
                        onChange={emailChangeHandler}
                        onBlur={emailTouchHandler}
                        value={signupEmailState.value}
                        isinvalid={
                          !signupEmailState.isValid &&
                          signupEmailState.isTouched
                        }
                        disabled={true && suStep >= 1}
                      />
                    </div>
                    {!signupEmailState.isValid &&
                      signupEmailState.isTouched && (
                        <p className="mt-1 text-xs text-red-600">
                          Please use a valid email for your registration
                        </p>
                      )}
                  </div>

                  {/* Code Input */}
                  {suStep === 1 && (
                    <div>
                      <label
                        htmlFor="code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Verification code
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <MailIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          id="code"
                          name="code"
                          type="text"
                          autoComplete="code"
                          required
                          className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                          placeholder="Enter your code"
                          // onChange={emailChangeHandler}
                          // onBlur={emailTouchHandler}
                          value={code}
                          onChange={changeCode}
                          isinvalid={true}
                        />
                      </div>
                      <p className="py-2 block text-xs font-medium text-gray-500">{`Please enter the verification code we sent to ${loginFormState.inputs.email.value} to complete sign up process`}</p>
                    </div>
                  )}

                  {/* Password input */}
                  {suStep === 2 && (
                    <div className="space-y-1">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <KeyIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="off"
                          required
                          className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                          placeholder="•••••••"
                          onChange={passwordChangeHandler}
                          onBlur={pwTouchHandler}
                          value={loginPWState.value}
                          isinvalid={
                            !loginPWState.isValid && loginPWState.isTouched
                          }
                        />
                      </div>
                      {!loginPWState.isValid && loginPWState.isTouched && (
                        <div>
                          <p className="mt-1 text-xs text-red-600">
                            Please enter your password (min. 8 characters!)
                            {/* Passwords must be at least 8 characters long and
                            contain at least three of the following four
                            properties: */}
                          </p>
                          <p className="mt-1 text-xs text-red-600">
                            {/* upper case, lower case, numbers, symbols: @#!§|%&\-;$,?... */}
                          </p>
                        </div>
                      )}
                      {!loginPWState.isTouched && (
                        <div>
                          <p className="mt-1 text-xs text-gray-500">
                            Please enter your password (min. 8 characters!)
                            {/* Passwords must be at least 8 characters long and
                            contain at least three of the following four
                            properties: */}
                          </p>
                          <p className="mt-1 text-xs text-gray-500">
                            {/* upper case, lower case, numbers, symbols: @#!§|%&\-;$,?... */}
                          </p>
                        </div>
                        )}
                    </div>
                  )}

                   {/* Name Input */}
                   {suStep === 2 && (
                    <div>
                      <label
                        htmlFor="firstname"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Firstname
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <UserIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          id="firstname"
                          name="firstname"
                          type="text"
                          autoComplete="off"
                          className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                          placeholder="Firstname (optional)"
                          value={firstname}
                          onChange={firstnameChangeHandler}
                        />
                      </div>
                    </div>
                  )}

                  {/* Button send verification code */}
                  {suStep === 0 && (
                    <div>
                      <button
                        type="button"
                        onClick={sendCodetoMail}
                        disabled={!signupEmailState.isValid}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                      >
                        Send verification code
                      </button>
                    </div>
                  )}

                  {/* Button Verify code */}
                  {suStep === 1 && (
                    <div>
                      <button
                        type="button"
                        onClick={verifyCode}
                        disabled={!verifyCodeButton}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                      >
                        Verify code
                      </button>
                    </div>
                  )}

                  {/* Button Re-Send code */}
                  {/* {suStep === 1 && (
                    <div>
                      <button
                        type="button"
                        onClick={sendCodetoMailagain}
                        // disabled={!signupEmailState.isValid && !showReSendCodeButton}
                        disabled={!showReSendCodeButton}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-600 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500 disabled:pointer-events-none"
                      >
                        Resend code
                      </button>
                    </div>
                  )} */}

                  {/* Button Change email address */}
                  {/* {suStep === 1 && (
                    <div>
                      <button
                        type="button"
                        onClick={startSignupProcess}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                      >
                        Change email address
                      </button>
                    </div>
                  )} */}

                  {/* Checkbox accept Terms of Use */}
                  {suStep === 2 && (
                    <div className="relative flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="check"
                          name="check"
                          type="checkbox"
                          className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                          // checked={checkedTerms}
                          onChange={checkTermsChangeHandler}
                          onBlur={checkTermsTouchHandler}
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor="check" className="text-gray-500">
                          By submitting this form and the associated creation of
                          an account with MigrationCheck, I confirm that I have
                          read and understood the{" "}
                          <a
                            className="text-primary-600 no-underline hover:underline"
                            href="https://migration-check.com/terms/"
                            target="_blank"
                          >
                            terms of use
                          </a>{" "} and the{" "}
                          <a
                            className="text-primary-600 no-underline hover:underline"
                            href="https://migration-check.com/privacy-policy/"
                            target="_blank"
                          >
                            privacy policy
                          </a>{" "}
                          and agree to both of them.
                        </label>
                        <p>
                          {!checkboxState.isValid &&
                            checkboxState.isTouched && (
                              <p className="mt-1 text-xs text-red-600">
                                Please accept our Terms and Conditions to
                                continue!
                              </p>
                            )}
                        </p>
                      </div>
                    </div>
                  )}

                  {/* Form Submit Button */}
                  {(
                    <div className={`${suStep <=1 ? 'unvisible' : 'visible'}`}>
                      <button
                        type="submit"
                        disabled={!loginFormState.isValid || suStep !==2}
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                      >
                        Submit and create account
                      </button>
                    </div>
                  )}

                  {/* Already have account -> Login*/}
                  {/* {suStep <= 1 && (
                    <div>
                      <span className="text-sm text-gray-400">
                        Already have an account?
                      </span>
                      <Link
                        to="/"
                        className="group flex items-center transition hover:translate-x-1 transform-gpu"
                      >
                        <SparklesIcon className="h-4 w-4 text-primary-500 group-hover:text-primary-600" />
                        <span className="ml-2 mr-1 text-sm font-medium bg-clip-text text-transparent bg-primary-linear group-hover:bg-primary-linear-hover">
                          Login
                        </span>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Link>
                    </div>
                  )} */}


                  {/* Re-located resent code button*/}
                  {suStep === 1 && (
                    <div>
                      <span className="text-sm text-gray-400">
                        No verification code received?
                      </span>
                      <button
                        type="button"
                        onClick={sendCodetoMailagain}
                        disabled={!showReSendCodeButton}
                        className="group flex items-center enabled:transition enabled:hover:translate-x-1 enabled:transform-gpu"
                      >
                        <SparklesIcon className="h-4 w-4 text-primary-500 enabled:group-hover:text-primary-600" />
                        <span className="ml-2 mr-1 text-sm font-medium bg-clip-text text-transparent bg-primary-linear enabled:group-hover:bg-primary-linear-hover">
                          Resend code
                        </span>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400 enabled:group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  )}

                  {suStep === 1 && notification && (<div className="rounded-md bg-green-100 p-4 mt-6">
                    <div className="flex">
                      <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="flex-shrink-0 h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-green-800">We have sent you a verification email again. Please check your inbox.</p>
                      </div>
                    </div>
                  </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1 bg-linear-primary-3-1 shadow-inner">
          <img
            className="absolute inset-0 h-full w-full object-contain object-bottom"
            src="/assets/images/loginmigrationcheck.png"
            alt="migrationcheck germany"
          />
        </div>
      </div>
    </>
  );
}

export default Signup;
