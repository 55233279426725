import './App.css'
import { useState, useCallback, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/de'

import { AuthContext } from './context/auth-context'
import Navbar from './components/Navbar'
import Login from './pages/public/Login'
import ResetPW from './pages/public/ResetPW'
import Set from './pages/public/Set'
// import Test from './pages/public/Test'cd 
import Signup from './pages/public/Signup'
import Request from './pages/public/Request'
import Onboarding from './pages/public/Onboarding'
import RequestService from './pages/public/RequestService'
import Message from './pages/public/Message'
import Dashboard from './pages/intern/Dashboard'
import UserResult from './pages/intern/UserResult'
import Upgrade from './pages/intern/Upgrade'
import Webinars from './pages/intern/Webinars'
import MigrationCheck from './pages/intern/MigrationCheck'
import Admin from './pages/admin/Admin'
import AdminUsers from './pages/admin/AdminUsers'
import AdminMigrationChecks from './pages/admin/AdminMigrationChecks'
import AdminMigrationCheck from './pages/admin/AdminMigrationCheck'
import BuyPackage from './pages/intern/BuyPackage';
import SignupWithLink from './pages/public/SignupWithLink';
import Introducemepublic from './pages/public/introducemepublic';
import Opportunitycard from './pages/public/Opportunitycard';

const App = () => {
  const [token, setToken] = useState(
    () => JSON.parse(localStorage.getItem('token')) || '',
  )

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', JSON.stringify(token))
    } else {
      localStorage.removeItem('token')
    }
  }, [token])

  const login = useCallback((token) => {
    setToken(token)
  }, [])
  const logout = useCallback(() => {
    setToken(null)
  }, [])

  moment().locale('en')

  const [progress, setProgress] = useState(0)
  const [section, setSection] = useState(0)

  return (
    <AuthContext.Provider
      value={{ token: token, login: login, logout: logout }}
    >
      {token ? (
        <Routes>
          {/* intern */}
          <Route
            path="/"
            element={
              <Navbar
                progress={progress}
                setProgress={setProgress}
                section={section}
                setSection={setSection}
              />
            }
          >
            <Route index element={<Dashboard />} />
            <Route
              path="/participate/:id"
              element={
                <MigrationCheck
                  setProgress={setProgress}
                  setSection={setSection}
                />
              }
            />
            <Route path="/user-results/:id" element={<UserResult />} />
            <Route path="/upgrade" element={<Upgrade />} />
            <Route path="/webinars" element={<Webinars />} />
            <Route path="/buy/:mcpackage" element={<BuyPackage />} />
            <Route path="request" element={<Request />} />
            <Route path="message" element={<Message />} />
            <Route path="opportunitycard" element={<Opportunitycard />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Route>

          {/* admin */}
          <Route path="admin" element={<Admin />}>
            <Route index element={<Navigate to="/admin/users" replace />} />
            <Route path="users" element={<AdminUsers />} />
            <Route path="migration-checks" element={<AdminMigrationChecks />} />
            <Route
              path="migration-checks/:id"
              element={<AdminMigrationCheck />}
            />
          </Route>
        </Routes>
      ) : (
        <Routes>
          {/* ecxlusive no token */}
          <Route path="login" element={<Login />} />
          <Route path="reset" element={<ResetPW />} />
          <Route path="set/:email/:resetPasswordVerification" element={<Set />} />
          <Route path="signup/:email/:activationcode/:mcpackage" element={<SignupWithLink />} />
          <Route path="signup/:email/:activationcode/" element={<SignupWithLink />} />
          <Route path="signup/:mcpackage" element={<Signup />} />
          <Route path="signup" element={<Signup />} />
          <Route path="request" element={<Request />} />
          <Route path="onboarding" element={<Onboarding />} />
          <Route path="opportunitycard" element={<Opportunitycard />} />
          <Route path="requestservice" element={<RequestService />} />
          <Route path="message" element={<Message />} />
          <Route path="introducemepublic" element={<Introducemepublic />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </AuthContext.Provider>
  )
}
export default App
