import { Menu, Transition } from "@headlessui/react";
import { BadgeCheckIcon } from "@heroicons/react/solid";
import { Fragment, useContext } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import NavigationItem from "../../components/NavigationItem";
import { AuthContext } from "../../context/auth-context";

export default function Admin() {
  const auth = useContext(AuthContext);
  let navigate = useNavigate();

  const handleLogout = () => {
    auth.logout();
    navigate("/login");
  };

  return (
    <>
      <div className="h-16"></div>
      <nav className="fixed top-0 left-0 right-0 z-20 bg-gray-100 border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <NavLink to="/" className="block flex items-center">
                <BadgeCheckIcon className="h-8 w-8 text-primary-600" />
                <span className="ml-3 font-semibold tracking-wider">Admin</span>
              </NavLink>
              <div className="pl-12 -mb-px ml-6 flex space-x-8">
                <NavigationItem label="Benutzer" to="/admin/users" />
                <NavigationItem
                  label="Migration Checks"
                  to="/admin/migration-checks"
                />
              </div>
            </div>
            <div className="flex-shrink-0 flex items-center">
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none">
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full ring-2 ring-offset-2 ring-primary-600"
                    // src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&h=256&q=80"
                    src="/assets/images/avatar/ava17.png"
                    alt=""
                  />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute z-30 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <span
                            onClick={handleLogout}
                            className={`block cursor-pointer px-4 py-2 text-sm text-gray-700 ${
                              active ? "bg-gray-100" : ""
                            }`}
                          >
                            Abmelden
                          </span>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </nav>

      <Outlet />
    </>
  );
}
