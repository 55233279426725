import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function NavigationItem(props) {
  let resolved = useResolvedPath(props.to);
  let match = useMatch({ path: resolved.pathname, end: false });

  return (
    <>
      <Link
        to={props.to}
        className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium ${
          match
            ? "border-rose-600 text-gray-900"
            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
        }`}
        aria-current={match ? "page" : undefined}
      >
        {props.label}
      </Link>
    </>
  );
}
