export const generateTextForScore = (progress, migrationcheck) => {
  // console.log(migrationcheck);
  // console.log(progress)
  switch (migrationcheck) {
    case 'Au Pair':
      // console.log("MigrationCheck: ", migrationcheck);
      if (progress <= 24) {
        return 'very low'
      } else if (progress <= 45) {
        return 'low'
      } else if (progress <=80) {
        return 'fair'
      } else if (progress <=109) {
        return 'high'
      } else {
        return 'very high'
      }

      case 'Volunteer':
        // console.log("MigrationCheck: ", migrationcheck);
        if (progress <= 24) {
          return 'very low'
        } else if (progress <= 45) {
          return 'low'
        } else if (progress <=80) {
          return 'fair'
        } else if (progress <=109) {
          return 'high'
        } else {
          return 'very high'
        }

      case 'Work':
      // console.log("MigrationCheck: ", migrationcheck);
      if (progress <= 24) {
        return 'very low'
      } else if (progress <= 44) {
        return 'low'
      } else if (progress <= 79) {
        return 'fair'
      } else if (progress <= 134) {
        return 'high'
      } else {
        return 'very high'
      }

      case 'Start a Business':
        // console.log("MigrationCheck: ", migrationcheck);
        if (progress <= 40) {
          return 'very low'
        } else if (progress <= 89) {
          return 'low'
        } else if (progress <= 249) {
          return 'fair'
        } else if (progress <= 279) {
          return 'high'
        } else {
          return 'very high'
        }

      case 'Apprenticeship':
        // console.log("MigrationCheck: ", migrationcheck);
        if (progress <= 25) {
          return 'very low'
        } else if (progress <= 50) {
          return 'low'
        } else if (progress <= 95) {
          return 'fair'
        } else if (progress <= 125) {
          return 'high'
        } else {
          return 'very high'
        }

      case 'Study':
        // console.log("MigrationCheck: ", migrationcheck);
        if (progress <= 20) {
          return 'very low'
        } else if (progress <= 39) {
          return 'low'
        } else if (progress <= 70) {
          return 'fair'
        } else if (progress <= 99) {
          return 'high'
        } else {
          return 'very high'
        }

      case 'Contract Marriage"':
        // console.log("MigrationCheck: ", migrationcheck);
        if (progress <= 20) {
          return 'very low'
        } else if (progress <= 45) {
          return 'low'
        } else if (progress <= 95) {
          return 'fair'
        } else if (progress <= 130) {
          return 'high'
        } else {
          return 'very high'
        }

      case 'Family Reunion':
        // console.log("MigrationCheck: ", migrationcheck);
        if (progress <= 19) {
          return 'very low'
        } else if (progress <= 41) {
          return 'low'
        } else if (progress <= 81) {
          return 'fair'
        } else if (progress <= 101) {
          return 'high'
        } else {
          return 'very high'
        }

    default:
      if (progress < 25) {
        return 'poor'
      } else if (progress < 50) {
        return 'fair'
      } else if (progress < 75) {
        return 'good'
      } else {
        return 'great'
      }
  }

}