import { Transition } from "@headlessui/react";
import { CheckCircleIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/auth-context";

const steps = [
  { name: 'verifying entries', href: '#', duration: 2000 },
  { name: 'searching for comparability', href: '#', duration: 5000 },
  { name: 'analysing data', href: '#', duration: 6000 },
  { name: 'generating recommendations', href: '#', duration: 3000 },
]

export default function MigrationCheckSummary(props) {
  const auth = useContext(AuthContext);
  const [current, setCurrent] = useState(props.userResult.step || 0);
  const [timer, setTimer] = useState(null);

  const verifying = () => {
    fetch(`/api/user-results/${props.userResult.id}/verifying`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => {
        setCurrent(1)
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  const comparability = () => {
    fetch(`/api/user-results/${props.userResult.id}/comparability`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => {
        setCurrent(2)
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  const analysing = () => {
    fetch(`/api/user-results/${props.userResult.id}/analysing`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => {
        setCurrent(3)
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  const generating = () => {
    fetch(`/api/user-results/${props.userResult.id}/generating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => {
        setCurrent(4)
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  useEffect(() => {
    props.setProgress(100);
    props.setSection("completed");
  }, []);

  useEffect(() => {
    if (current < steps.length) {
      next();
    }
  }, [current])

  const next = () => {
    if(current === 0) {
      verifying()
    } else if (current === 1) {
      comparability()
    } else if(current === 2) {
      analysing()
    } else if(current === 3) {
      generating()
    }
  }

  return (
    <div className="max-w-[880px] mx-auto px-4 sm:px-6 lg:px-8 py-20">
      <header>
        <button onClick={() => props.onBackClicked()} className="group">
          <div className="flex items-center">
            {/* <ChevronLeftIcon className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" /> */}
            {/* <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">previous question</span> */}
          </div>
        </button>
        <h1 className="mt-2 text-3xl font-bold leading-tight text-gray-900">
          Evaluation
        </h1>
      </header>
      <main className="mt-12">
        <div className="py-12 px-4 sm:px-6 lg:px-8">
          <nav className="flex justify-center" aria-label="Progress">
            <ol role="list" className="space-y-6">
              {steps.map((step, index) => (
                <li key={step.name}>
                  <div className="flex items-start">
                    <div className="relative h-5 w-5">
                      <Transition
                        show={index < current}
                        enter="absolute inset-0 transition-opacity ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="absolute inset-0 transition-opacity ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
                          <CheckCircleIcon
                            className="h-full w-full text-primary-600"
                            aria-hidden="true"
                          />
                        </span>
                      </Transition>
                      <Transition
                        show={index === current}
                        enter="absolute inset-0 transition-opacity ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="absolute inset-0 transition-opacity ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                          <svg className="animate-spin h-4 w-4 text-primary-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        </span>
                      </Transition>
                      <Transition
                        show={index > current}
                        enter="absolute inset-0 transition-opacity ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="absolute inset-0 transition-opacity ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                          <div className="h-2 w-2 bg-gray-300 rounded-full" />
                        </div>
                      </Transition>
                    </div>
                    <span className={`transition duration-300 ml-3 text-sm font-medium ${index === current ? "text-primary-600" : "text-gray-500"}`}>
                      {step.name}
                    </span>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>

        {current === steps.length && <div className="flex justify-center">
          <button type="button" className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-primary-600 hover:bg-primary-700" onClick={props.finishUserResult}>
            See results
          </button>
        </div>}
      </main>
    </div>
  );
}
