// export default function Upgrade(props) {
//   return (
//     <div className="max-w-[880px] mx-auto px-4 sm:px-6 lg:px-8 py-20">
//       Select a new Package:
//     </div>
//   );
// }
import { useNavigate } from "react-router-dom";
import Tooltip from "../../components/Tooltip"
import { SparklesIcon, PuzzleIcon, TrendingUpIcon, DocumentTextIcon } from "@heroicons/react/solid";
import Footer from "../../components/Footer";



// const packageInclude = [{ title: 'Detailed Results', tooltip: 'Upon completion of a detailed migration check, you’ll get a detailed result' },
// { title: 'Save & Continue Later', tooltip: 'You can complete a detailed check as long as you can access your dashboard' },
// { title: 'Recommendations & Links', tooltip: 'We provide recommendations on how to improve your chances, based on the answers you provide during the detailed check.' },
// { title: 'Support via Email', tooltip: 'We’ll gladly respond to each email you send us.' },
// ]

const packageIncludestandard = [
  { title: '3 detailed checks', tooltip: 'The number of detailed checks you can complete.' },
  { title: 'Full version', tooltip: 'Get full access to all the remarks and recommendations on how to improve your chances.' },
  { title: 'Detailed results', tooltip: 'Upon completion of a detailed migration check, you’ll get a detailed result' },
  { title: 'Guide-me feature', tooltip: 'See all the steps and links in the step-by-step guide.' },
  { title: 'Connect-me feature', tooltip: 'Use the Connect-me button, if it is included in your category.' },
  { title: 'Introduce-me feature', tooltip: 'Use the Introduce-me button, if it is included in your category' },
  { title: 'Save and continue later', tooltip: 'You can discontinue your check and resume later' },
  { title: 'Technical support', tooltip: 'We’ll gladly respond to each email you send us.' },
  ]

  const packageIncludestandardplus = [
    { title: '6 detailed checks', tooltip: 'The number of detailed checks you can complete.' },
    { title: 'Full version', tooltip: 'Get full access to all the remarks and recommendations on how to improve your chances.' },
    { title: 'Detailed results', tooltip: 'Upon completion of a detailed migration check, you’ll get a detailed result' },
    { title: 'Guide-me feature', tooltip: 'See all the steps and links in the step-by-step guide.' },
    { title: 'Connect-me feature', tooltip: 'Use the Connect-me button, if it is included in your category.' },
    { title: 'Introduce-me feature', tooltip: 'Use the Introduce-me button, if it is included in your category' },
    { title: 'Save and continue later', tooltip: 'You can discontinue your check and resume later' },
    { title: 'Technical support', tooltip: 'We’ll gladly respond to each email you send us.' },
    ]

    const packageIncludepremium = [
      { title: '8 detailed checks', tooltip: 'The number of detailed checks you can complete.' },
      { title: 'Full version', tooltip: 'Get full access to all the remarks and recommendations on how to improve your chances.' },
      { title: 'Detailed results', tooltip: 'Upon completion of a detailed migration check, you’ll get a detailed result' },
      { title: 'Guide-me feature', tooltip: 'See all the steps and links in the step-by-step guide.' },
      { title: 'Connect-me feature', tooltip: 'Use the Connect-me button, if it is included in your category.' },
      { title: 'Introduce-me feature', tooltip: 'Use the Introduce-me button, if it is included in your category' },
      { title: 'Save and continue later', tooltip: 'You can discontinue your check and resume later' },
      { title: 'Technical support', tooltip: 'We’ll gladly respond to each email you send us.' },
      { title: 'Path-finding Consultation', tooltip: 'A 45-minute 1-on-1 consultation session to discover the best options for you based on your personal situation.' },
      { title: 'Visa Interview Coaching', tooltip: 'A two-hour webinar session to prepare you to perform best during your visa interview.'},
      ]

export default function Upgrade() {
  const navigate = useNavigate();
  return (
    <>
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Simple no-tricks pricing</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            The more detailed checks you complete, the more options you have to maximize your chances!
          </p>
        </div>
        {/* Standard neu */}
        <div className="mt-16 mx-auto max-w-2xl rounded-xl ring-1 ring-gray-200 2xl:mx-0 2xl:flex xl:max-w-none">
          <div className="p-8 sm:p-10 2xl:flex-auto">
            <div className="flex gap-x-3">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">Standard</h3>
              <DocumentTextIcon className="h-6 w-6 text-primary-500" />
            </div>
            <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-2">
              Get full access to all your personal recommendations, and use the special features such as "Connect me" and "Introduce me" to fast track moving to Germany!
            </div>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {packageIncludestandard.map((x) => (
                <li key={x.title} className="flex gap-x-3">
                  <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                  {x.title}
                  <Tooltip>{x.tooltip}</Tooltip>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-2 2xl:p-20 xl:px-32 xl:pb-6 2xl:mt-0 2xl:w-full 2xl:max-w-md 2xl:flex-shrink-0">
            <div className="rounded-xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 2xl:flex 2xl:flex-col 2xl:justify-center">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-xs font-semibold text-gray-600">One time fee, no other costs</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-7xl font-bold tracking-tight text-gray-900">5</span>
                  <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                </p>
                <button
                  type="button"
                  className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    navigate("/buy/standard");
                  }}
                >
                  book now
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                get new dashboard access for 6 months
                </p>
              </div>
            </div>
          </div>
        </div>
         {/* Standard+ neu */}
         <div className="mt-16 mx-auto max-w-2xl rounded-xl ring-1 ring-gray-200 2xl:mx-0 2xl:flex xl:max-w-none">
          <div className="p-8 sm:p-10 2xl:flex-auto">
            <div className="flex gap-x-3">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">Standard+</h3>
              <PuzzleIcon className="h-6 w-6" style={{ color: "#FFAB70" }} />
            </div>
            <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-2">
              Get full access to all your personal recommendations, and use the special features such as "Connect me" and "Introduce me" to fast track moving to Germany!
            </div>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {packageIncludestandardplus.map((x) => (
                <li key={x.title} className="flex gap-x-3">
                  <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                  {x.title}
                  <Tooltip>{x.tooltip}</Tooltip>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-2 2xl:p-20 xl:px-32 xl:pb-6 2xl:mt-0 2xl:w-full 2xl:max-w-md 2xl:flex-shrink-0">
            <div className="rounded-xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 2xl:flex 2xl:flex-col 2xl:justify-center">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-xs font-semibold text-gray-600">One time fee, no other costs</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-7xl font-bold tracking-tight text-gray-900">9</span>
                  <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                </p>
                <button
                  type="button"
                  className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    navigate("/buy/standardplus");
                  }}
                >
                  book now
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                get new dashboard access for 12 months
                </p>
              </div>
            </div>
          </div>
        </div>
          {/* Premium neu */}
        <div className="mt-16 mx-auto max-w-2xl rounded-xl ring-1 ring-gray-200 2xl:mx-0 2xl:flex xl:max-w-none">
          <div className="p-8 sm:p-10 2xl:flex-auto">
            <div className="flex gap-x-3">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">Premium</h3>
              <SparklesIcon className="h-7 w-7" style={{ color: "#FF8C81" }}/>
            </div>
            <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-2">
              Get full access to all your personal recommendations, and use the special features such as "Connect me" and "Introduce me" to fast track moving to Germany!
            </div>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {packageIncludepremium.map((x) => (
                <li key={x.title} className="flex gap-x-3">
                  <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                  {x.title}
                  <Tooltip>{x.tooltip}</Tooltip>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-2 2xl:p-20 xl:px-32 xl:pb-6 2xl:mt-0 2xl:w-full 2xl:max-w-md 2xl:flex-shrink-0">
            <div className="rounded-xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 2xl:flex 2xl:flex-col 2xl:justify-center">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-xs font-semibold text-gray-600">One time fee, no other costs</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-7xl font-bold tracking-tight text-gray-900">79</span>
                  <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                </p>
                <button
                  type="button"
                  className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    navigate("/buy/premium");
                  }}
                >
                  book now
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                get new dashboard access for 12 months
                </p>
              </div>
            </div>
          </div>
        </div> 


        {/* BASIC */}
        {/* <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <div className="flex gap-x-3">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">Basic</h3>
              <DocumentTextIcon className="h-6 w-6 text-primary-500" />
            </div>
            <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-2">
              Detailed Checks: 1
              <Tooltip>{"The number of times you can complete a detailed check."}</Tooltip>
            </div>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {packageInclude.map((x) => (
                <li key={x.title} className="flex gap-x-3">
                  <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                  {x.title}
                  <Tooltip>{x.tooltip}</Tooltip>
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">One time fee, no other costs</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-7xl font-bold tracking-tight text-gray-900">14</span>
                  <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                </p>
                <button
                  type="button"
                  className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    navigate("/buy/basic");
                  }}
                >
                  Get the package
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  provides new 3 month dashboard access
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* STANDARD */}
        {/* <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <div className="flex gap-x-3">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">Standard</h3>
              <PuzzleIcon className="h-6 w-6" style={{ color: "#FFAB70" }} />
            </div>
            <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-3">
              Detailed Checks: <span className="font-bold">3</span>
              <Tooltip>{"The number of times you can complete a detailed check."}</Tooltip>
            </div>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {packageInclude.map((x) => (
                <li key={x.title} className="flex gap-x-3">
                  <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                  {x.title}
                  <Tooltip>{x.tooltip}</Tooltip>
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">One time fee, no other costs</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-7xl font-bold tracking-tight text-gray-900">39</span>
                  <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                </p>
                <button
                  type="button"
                  className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    navigate("/buy/standard");
                  }}
                >
                  Get the package
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  provides new 6 month dashboard access
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* STANDARD+ */}
        {/* <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <div className="flex gap-x-3">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">Standard+</h3>
              <SparklesIcon className="h-7 w-7" style={{ color: "#FF8C81" }}/>
            </div>
            <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-3">
              Detailed Checks: <span className="font-bold">6</span>
              <Tooltip>{"The number of times you can complete a detailed check."}</Tooltip>
            </div>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {packageInclude.map((x) => (
                <li key={x.title} className="flex gap-x-3">
                  <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                  {x.title}
                  <Tooltip>{x.tooltip}</Tooltip>
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">One time fee, no other costs</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-7xl font-bold tracking-tight text-gray-900">60</span>
                  <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                </p>
                <button
                  type="button"
                  className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    navigate("/buy/standardplus");
                  }}
                >
                  Get the package
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  provides new 6 month dashboard access
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* Maximize */}
        {/* <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <div className="flex gap-x-3">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">Maximize</h3>
              <TrendingUpIcon className="h-8 w-9 text-primary-500"/>
            </div>
            <div className="mt-6 text-base leading-7 text-gray-600 flex gap-x-3">
              Detailed Checks: <span className="font-bold">10</span>
              <Tooltip>{"The number of times you can complete a detailed check."}</Tooltip>
            </div>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {packageInclude.map((x) => (
                <li key={x.title} className="flex gap-x-3">
                  <img className="w-6 h-6" width={24} height={24} alt="" src="/assets/images/Check.svg"></img>
                  {x.title}
                  <Tooltip>{x.tooltip}</Tooltip>
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">One time fee, no other costs</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-7xl font-bold tracking-tight text-gray-900">90</span>
                  <div><img style={{ marginBottom: "35px", marginLeft: "5px" }} alt="" src="/assets/images/euro.svg"></img></div>
                </p>
                <button
                  type="button"
                  className="mt-10 w-full inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  onClick={() => {
                    navigate("/buy/maximize");
                  }}
                >
                  Get the package
                </button>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  provides new 12 month dashboard access
                </p>
              </div>
            </div>
          </div>
        </div> */}

      </div>
    </div>
    <div className="bg-white">
    <Footer />
    </div>
    </>
  )
}
