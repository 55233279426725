import { Fragment, useRef} from 'react'
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from '@headlessui/react'
// import { useNavigate } from "react-router-dom"
import {
  BadgeCheckIcon,
  CalendarIcon,
  UsersIcon,
  XIcon,
  AcademicCapIcon,
  PencilAltIcon,
  DocumentTextIcon,
  HomeIcon,
  SwitchHorizontalIcon,
  LibraryIcon,
  FlagIcon,
  EyeIcon
} from "@heroicons/react/solid";

import { BriefcaseIcon, CheckIcon, CurrencyEuroIcon, ClipboardListIcon, PencilIcon, RefreshIcon } from "@heroicons/react/outline";


export default function GuideMe(props) {

  const cancelButtonRef = useRef(null)
  // const navigate = useNavigate();

  const navigate = useNavigate();

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  // console.log("Im Guideme Modal ", props.userResult.migrationCheck.title)

  if (props.userResult.migrationCheck.title === "Work") {

    if (props.userResult?.questions[0].answers[0].selected) {
      //Opportunity Card
      return (
        <Transition.Root show={props.openGuideMe} as={Fragment}>
          <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={props.setOpenGuideMe}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="absolute right-0 top-0 pr-4 pt-4 block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                        onClick={() => props.setOpenGuideMe(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    <h2 className="text-xl font-medium text-gray-900">
                      Guide me
                    </h2>


                    <div className="bg-white border-t border-b mt-6 py-6">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-400 sm:mx-0 sm:h-10 sm:w-10">
                          <BriefcaseIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            A step-by-step guide to obtaining the Opportunity Card
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              You’ve completed the detailed check, and you are now ready to start your journey to experiencing one of Europe’s economic powerhouses; here is your road map:
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p className='font-medium mb-5'>

                      </p>
                      <div className="flow-root">
                        <ul role="list" className="-mb-8">

                          <li key={1}>

                            <div className={`relative ${props.userData?.premium ? 'pb-8' : 'pb-1'}`}>

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <img
                                      className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                      src="/assets/images/avatar/ava17.png"
                                      alt=""
                                    /> */}
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                                      <CheckIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Confirm your eligibility"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-green-500', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"easy"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Start with this first basic step</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>To ascertain your eligibility for the opportunity card, assess your qualifications. You automatically qualify and do not need to rely on the point system if you possess any of the following:</p>
                                      <div className="ml-0.5 opacity-100 my-2">
                                        <ul className="list-disc ml-4">
                                          <li>A German university degree,</li>
                                          <li>A German vocational qualification,</li>
                                          <li>A university degree equivalent to a German university degree, or</li>
                                          <li>A statement of comparability.</li>
                                        </ul>
                                      </div>

                                      <p>With a foreign vocational qualification, you want to confirm you have the six points required in the point-based system. You can be sure you qualify for the opportunity card if your chances are high or very high after completing the detailed check.</p>

                                    </div>
                                  </div>
                                </>
                                {/* assignment' */}
                                {/* <>
                                      <div>
                                        <div className="relative px-1">
                                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                            <UsersIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="min-w-0 flex-1 py-1.5">
                                        <div className="text-sm text-gray-500">
                                          <a href={""} className="font-medium text-gray-900">
                                            {"Tom"}
                                          </a>{' '}
                                          assigned{' '}
                                          <a href={""} className="font-medium text-gray-900">
                                            {"Max"}
                                          </a>{' '}
                                          <span className="whitespace-nowrap">{"22.07.2009"}</span>
                                        </div>
                                        <div className="mt-2 text-sm text-gray-700">
                                          <p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.'}</p>
                                        </div>
                                      </div>
                                    </> */}
                                {/* tags */}
                                {/* <>
                                      <div>
                                        <div className="relative px-1">
                                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                            <CheckCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="min-w-0 flex-1 py-0">
                                        <div className="text-sm leading-8 text-gray-500">
                                          <span className="mr-0.5">
                                            <a href={""} className="font-medium text-gray-900">
                                              {"Tina"}
                                            </a>{' '}
                                            added tags
                                          </span>{' '}
                                          <span className="mr-0.5">
                                  
                                              <Fragment key={"Tag"}>
                                                <a
                                                  href={""}
                                                  className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                                >
                                                  <svg
                                                    className={classNames('green', 'h-1.5 w-1.5')}
                                                    viewBox="0 0 6 6"
                                                    aria-hidden="true"
                                                  >
                                                    <circle cx={3} cy={3} r={3} />
                                                  </svg>
                                                  {"Tag"}
                                                </a>{' '}
                                              </Fragment>
                              
                                          </span>
                                          <span className="whitespace-nowrap">{"27.01.2024"}</span>
                                        </div>
                                      </div>
                                    </> */}

                              </div>
                            </div>
                          </li>

                          {!props.userData?.premium && <div className="paywall bg-gray-50 mt-2 mb-8 mx-auto max-w-2xl rounded-xl ring-1 ring-gray-200 2xl:mx-0 2xl:flex xl:max-w-none" style={{position: "relative"}}>
                            <div className="p-4 2xl:p-8 2xl:p-6 sm:p-10 2xl:flex-auto gap-x-3">
                              <div className="flex gap-x-3 w-full mx-auto place-content-center">
                                <div>
                                <p className="text-base xl:text-xl font-bold tracking-tight text-gray-500">Upgrade to get full access</p>
                                </div>
                                <DocumentTextIcon className="h-6 w-6 text-primary-500" />
                              </div>
                              <div className="flex gap-x-3 w-full mx-auto place-content-center mt-6">
                                <div>
                                <img className="w-5 h-5" width={18} height={18} alt="" src="/assets/images/Check.svg"></img>
                                </div>
                                <p className='text-gray-500 text-sm'>8 more steps await you</p>
                              </div>
                              <div className="flex gap-x-3 w-full mx-auto place-content-center mt-8">
                              <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                onClick={() => {
                                  navigate("/buy/standard");
                                }}
                              >
                                <p className="px-8">book now</p>
                              </button>
                              </div>
                            </div>
                            
                          </div>}
                          { props.userData?.premium &&
                          <>
                          <li key={2}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-100 ring-8 ring-white">
                                      <CurrencyEuroIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Prepare proof of sufficient finances"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-red-700', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"critical"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">This step might require much time, start asap</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>In addition to qualifying for the opportunity card, it's essential to prove your ability to support yourself during your job search in Germany. Even if you anticipate securing employment within a few weeks after arriving, you must demonstrate financial stability for the duration of your stay.</p>
                                      <p className='mt-2'>One of the three ways to demonstrate this is to open a blocked account with a minimum balance of 1027 Euros per month, which makes 12,234 Euros for a year.</p>
                                      <p className='mt-2'>A formal obligation, also known as a declaration of commitment is your second option. The formal obligation should include confirmation from the foreigners authority regarding the financial capacity of the obligor.</p>
                                      <p className='mt-2'>The third way of covering your living costs in Germany is through part-time employment. With an employment contract or a binding offer, you have solid evidence of sufficient financial means. You can request our service to secure a part-time job by filling out <a class="text-primary-600 hover:text-primary-700" href="https://migrationguide.de/chancenkarte-parttime-job-application/">this application form</a>.</p>
                                    </div>
                                  </div>
                                </>
                                {/* assignment' */}
                                {/* <>
                                      <div>
                                        <div className="relative px-1">
                                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                            <UsersIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="min-w-0 flex-1 py-1.5">
                                        <div className="text-sm text-gray-500">
                                          <a href={""} className="font-medium text-gray-900">
                                            {"Tom"}
                                          </a>{' '}
                                          assigned{' '}
                                          <a href={""} className="font-medium text-gray-900">
                                            {"Max"}
                                          </a>{' '}
                                          <span className="whitespace-nowrap">{"22.07.2009"}</span>
                                        </div>
                                        <div className="mt-2 text-sm text-gray-700">
                                          <p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.'}</p>
                                        </div>
                                      </div>
                                    </> */}
                                {/* tags */}
                                {/* <>
                                      <div>
                                        <div className="relative px-1">
                                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                            <CheckCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="min-w-0 flex-1 py-0">
                                        <div className="text-sm leading-8 text-gray-500">
                                          <span className="mr-0.5">
                                            <a href={""} className="font-medium text-gray-900">
                                              {"Tina"}
                                            </a>{' '}
                                            added tags
                                          </span>{' '}
                                          <span className="mr-0.5">
                                  
                                              <Fragment key={"Tag"}>
                                                <a
                                                  href={""}
                                                  className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                                >
                                                  <svg
                                                    className={classNames('green', 'h-1.5 w-1.5')}
                                                    viewBox="0 0 6 6"
                                                    aria-hidden="true"
                                                  >
                                                    <circle cx={3} cy={3} r={3} />
                                                  </svg>
                                                  {"Tag"}
                                                </a>{' '}
                                              </Fragment>
                              
                                          </span>
                                          <span className="whitespace-nowrap">{"27.01.2024"}</span>
                                        </div>
                                      </div>
                                    </> */}

                              </div>
                            </div>
                          </li>

                          <li key={3}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-pink-100 ring-8 ring-white">
                                      <HomeIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Make arrangements for accommodation"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-yellow-300', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"medium"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Avoid unnecessary mistakes</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>As part of your preparations for obtaining the card, planning your accommodation is essential, which entails selecting a city or town. It's important to note that you’ll be required to provide the full address of your intended residence when applying for the opportunity card.</p>
                                      <p className='mt-2'>If you have the option of staying with family or friends, that's certainly a viable choice. However, if this isn't possible, consider key factors when choosing your place of residence, such as the cost of living and job prospects in the selected location.</p>
                                      <p className='mt-2'>Having assistance from someone residing in Germany is advantageous, as securing accommodation remotely, particularly in larger cities, can be challenging. Therefore, services that provide support in this area play a crucial role in facilitating a smoother transition.</p>
                                    </div>
                                  </div>
                                </>
                                {/* assignment' */}
                                {/* <>
                                      <div>
                                        <div className="relative px-1">
                                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                            <UsersIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="min-w-0 flex-1 py-1.5">
                                        <div className="text-sm text-gray-500">
                                          <a href={""} className="font-medium text-gray-900">
                                            {"Tom"}
                                          </a>{' '}
                                          assigned{' '}
                                          <a href={""} className="font-medium text-gray-900">
                                            {"Max"}
                                          </a>{' '}
                                          <span className="whitespace-nowrap">{"22.07.2009"}</span>
                                        </div>
                                        <div className="mt-2 text-sm text-gray-700">
                                          <p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.'}</p>
                                        </div>
                                      </div>
                                    </> */}
                                {/* tags */}
                                {/* <>
                                      <div>
                                        <div className="relative px-1">
                                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                            <CheckCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="min-w-0 flex-1 py-0">
                                        <div className="text-sm leading-8 text-gray-500">
                                          <span className="mr-0.5">
                                            <a href={""} className="font-medium text-gray-900">
                                              {"Tina"}
                                            </a>{' '}
                                            added tags
                                          </span>{' '}
                                          <span className="mr-0.5">
                                  
                                              <Fragment key={"Tag"}>
                                                <a
                                                  href={""}
                                                  className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                                >
                                                  <svg
                                                    className={classNames('green', 'h-1.5 w-1.5')}
                                                    viewBox="0 0 6 6"
                                                    aria-hidden="true"
                                                  >
                                                    <circle cx={3} cy={3} r={3} />
                                                  </svg>
                                                  {"Tag"}
                                                </a>{' '}
                                              </Fragment>
                              
                                          </span>
                                          <span className="whitespace-nowrap">{"27.01.2024"}</span>
                                        </div>
                                      </div>
                                    </> */}

                              </div>
                            </div>
                          </li>

                          <li key={4}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-orange-100 ring-8 ring-white">
                                      <ClipboardListIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Familiarise yourself with specific requirements"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-yellow-300', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"medium"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Do not overlook the details</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>Besides the general requirements for the card, specific document requirements may vary depending on your country of residence.</p>
                                      <p className='mt-2'>Ensure you visit the website of the German embassy where you’ll submit your application to carefully review the checklist of documents to submit and pay attention to how the documents should be presented, e.g., in original, two copies, certified copies, etc.</p>
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={5}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-purple-100 ring-8 ring-white">
                                      <CalendarIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Schedule an appointment and gather the required documentation"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-red-700', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"critical"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Make sure you are ready</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>You want to book an appointment for your visa application as soon as it becomes possible. Take into consideration how long it takes to secure a visa appointment when planning to gather your supporting documents.</p>
                                      <p className='mt-2'>Give yourself ample time to gather all the necessary documents you’ll submit during your visa appointment, including evidence of your qualifications, blocked account statements or confirmed formal obligation, health insurance coverage, and a valid passport with at least six months of validity remaining.</p>

                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={6}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 ring-8 ring-white">
                                      <PencilAltIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Apply for the Opportunity Card"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-red-700', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"critical"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Stay careful and diligent</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>Next, fill out the visa application form accurately and completely. Double-check that all information provided matches what is stated on your other supporting documents. Any discrepancies can lead to delays or even rejection of your application.</p>
                                      <p className='mt-2'>Stay positive and proactive even after submitting your application! </p>

                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={7}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-cyan-100 ring-8 ring-white">
                                      <SwitchHorizontalIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Travel to Germany and get registered"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-green-500', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"easy"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Welcome to one of the most exciting steps!</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>Once you have obtained your visa, it is time to travel to Germany and start a new chapter of your career. Make sure to book your flight well in advance to get the best deals.</p>
                                      <p className='mt-2'>Welcome to Germany! Within two weeks of arriving in Germany, you must register at the local registration office. You will need to bring your passport, visa, proof of address, and any other relevant documents requested by the office.</p>


                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={8}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-red-200 ring-8 ring-white">
                                      <EyeIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Start job hunting and settle in"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-green-500', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"easy"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">You can do it, go for it!</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>The objective is not just to reach Germany but to secure employment, and once you've arrived, it's crucial to promptly initiate your job search, even with the opportunity card's one-year validity.</p>
                                      <p className='mt-2'>Exploring local recruitment agencies and leveraging online job portals such as Stepstone and Jobware are effective strategies for expediting your job hunt. Attending career events, networking, and utilizing social media platforms like LinkedIn and Xing are additional avenues to explore.</p>
                                      <p className='mt-2'>While actively seeking a position aligned with your qualifications and professional background, consider taking up a temporary part-time job. Keep in mind that learning the German language and enhancing your language skills will significantly contribute to expediting the job acquisition process.</p>

                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={9}>

                            <div className="relative pb-8">

                              {/* <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" /> */}

                              <div className="relative flex items-start space-x-3">

                                {/* tags */}
                                <>
                                  <div>
                                    <div className="relative px-1">
                                      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                        <FlagIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1 py-0">
                                    <div className="text-sm text-gray-500">
                                      <span className="mr-0.5">
                                        <span className="font-medium text-gray-900">
                                          {"Well done! You have reached an important stage in your career."}
                                        </span>{' '}

                                      </span>{' '}
                                      <span className="mr-0.5">

                                      </span>
                                      {/* <span className="whitespace-nowrap">{"We wish you all the best in Germany"}</span> */}
                                    </div>
                                  </div>
                                </>

                              </div>
                            </div>
                          </li>
                          </>
                          }
                        </ul>
                      </div>
                    </div>

                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )
    } else {
      //Work Permit
      return (
        <Transition.Root show={props.openGuideMe} as={Fragment}>
          <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={props.setOpenGuideMe}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="absolute right-0 top-0 pr-4 pt-4 block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                        onClick={() => props.setOpenGuideMe(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    <h2 className="text-xl font-medium text-gray-900">
                      Guide me
                    </h2>


                    <div className="bg-white border-t border-b mt-6 py-6">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-200 sm:mx-0 sm:h-10 sm:w-10">
                          <BriefcaseIcon className="h-6 w-6 text-gray-700" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            Step-by-step guide to securing a work contract and a German work permit
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              You’ve completed the detailed check, and you are now ready to start your journey to experiencing one of Europe’s economic powerhouses; here is your road map:
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p className='font-medium mb-5'>

                      </p>
                      <div className="flow-root">
                        <ul role="list" className="-mb-8">

                          <li key={1}>

                            <div className={`relative ${props.userData?.premium ? 'pb-8' : 'pb-1'}`}>

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <img
                                      className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                      src="/assets/images/avatar/ava17.png"
                                      alt=""
                                    /> */}
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                                      <AcademicCapIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Review your qualification"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-green-500', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"easy"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Start with this first basic step</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>Possessing a qualification comparable to a German university degree or vocational qualification is fundamental and essential. If required, apply for a statement of comparability or seek recognition for your professional qualification.</p>
                                      <p className='mt-2'>In cases where your qualification is not in English or German, having it translated into either language underscores your commitment to pursuing employment opportunities and ensures clarity in the evaluation process.</p>

                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          {!props.userData?.premium && <div className="paywall bg-gray-50 mt-2 mb-8 mx-auto max-w-2xl rounded-xl ring-1 ring-gray-200 2xl:mx-0 2xl:flex xl:max-w-none" style={{position: "relative"}}>
                            <div className="p-4 2xl:p-8 2xl:p-6 sm:p-10 2xl:flex-auto gap-x-3">
                              <div className="flex gap-x-3 w-full mx-auto place-content-center">
                                <div>
                                <p className="text-base xl:text-xl font-bold tracking-tight text-gray-500">Upgrade to get full access</p>
                                </div>
                                <DocumentTextIcon className="h-6 w-6 text-primary-500" />
                              </div>
                              <div className="flex gap-x-3 w-full mx-auto place-content-center mt-6">
                                <div>
                                <img className="w-5 h-5" width={18} height={18} alt="" src="/assets/images/Check.svg"></img>
                                </div>
                                <p className='text-gray-500 text-sm'>10 more steps await you</p>
                              </div>
                              <div className="flex gap-x-3 w-full mx-auto place-content-center mt-8">
                              <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                onClick={() => {
                                  navigate("/buy/standard");
                                }}
                              >
                                <p className="px-8">book now</p>
                              </button>
                              </div>
                            </div>
                            
                          </div>}

                          {props.userData?.premium &&
                          <>
                          <li key={2}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-100 ring-8 ring-white">
                                      <BadgeCheckIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Prepare for job application"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-yellow-300', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"medium"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Avoid unnecessary mistakes</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>Familiarizing yourself with the German job market, especially within your field, is important. To make a positive impression on potential employers, your resume and cover letter should align with German standards, increasing your chances of securing a position with a German company.</p>
                                      <p className='mt-2'>Consider participating in webinars to equip yourself with the skills needed to craft compelling applications. It's important to note that applying for positions will be a common step. Some job openings may require a quick application, achievable in a few minutes, while others may involve a more detailed and extended process.</p>
                                      <p className='mt-2'>In addition to preparing to engage with companies, focus on preparing to connect with recruiters, as they can significantly expedite your employment process. Demonstrating your potential is key to receiving their support and services.</p>
                                    </div>
                                  </div>
                                </>
                                {/* assignment' */}
                                {/* <>
                                      <div>
                                        <div className="relative px-1">
                                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                            <UsersIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="min-w-0 flex-1 py-1.5">
                                        <div className="text-sm text-gray-500">
                                          <a href={""} className="font-medium text-gray-900">
                                            {"Tom"}
                                          </a>{' '}
                                          assigned{' '}
                                          <a href={""} className="font-medium text-gray-900">
                                            {"Max"}
                                          </a>{' '}
                                          <span className="whitespace-nowrap">{"22.07.2009"}</span>
                                        </div>
                                        <div className="mt-2 text-sm text-gray-700">
                                          <p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.'}</p>
                                        </div>
                                      </div>
                                    </> */}
                                {/* tags */}
                                {/* <>
                                      <div>
                                        <div className="relative px-1">
                                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                            <CheckCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="min-w-0 flex-1 py-0">
                                        <div className="text-sm leading-8 text-gray-500">
                                          <span className="mr-0.5">
                                            <a href={""} className="font-medium text-gray-900">
                                              {"Tina"}
                                            </a>{' '}
                                            added tags
                                          </span>{' '}
                                          <span className="mr-0.5">
                                  
                                              <Fragment key={"Tag"}>
                                                <a
                                                  href={""}
                                                  className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                                >
                                                  <svg
                                                    className={classNames('green', 'h-1.5 w-1.5')}
                                                    viewBox="0 0 6 6"
                                                    aria-hidden="true"
                                                  >
                                                    <circle cx={3} cy={3} r={3} />
                                                  </svg>
                                                  {"Tag"}
                                                </a>{' '}
                                              </Fragment>
                              
                                          </span>
                                          <span className="whitespace-nowrap">{"27.01.2024"}</span>
                                        </div>
                                      </div>
                                    </> */}

                              </div>
                            </div>
                          </li>

                          <li key={3}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-pink-100 ring-8 ring-white">
                                      <PencilAltIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Find employment opportunities and apply"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-red-700', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"critical"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">This step might require much time, start asap</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>Acquiring a work permit becomes relatively easy once you have a work contract in hand. However, the initial challenge lies in obtaining that work contract. So, the big question arises: How do you secure a work contract?</p>
                                      <p className='mt-2'>There are several avenues to explore in order to secure a job and ultimately secure the coveted work contract, including:</p>
                                      <div class="ml-0.5">
                                        <ol class="list-decimal ml-4">
                                          <li className='mt-2'>Recruiters and Employment Agents:
                                            Recruitment agencies help companies find suitable candidates for their job openings by actively seeking out potential candidates and matching them with job opportunities based on their skills and qualifications. Employment agents, on the other hand, help individuals find employment opportunities, acting more as representatives for job seekers. You want to use our “Connect Me” feature to get connected with recruiters, as this is one of the fastest ways to secure a job, especially for someone outside the country. </li>
                                          <li className='mt-2'>Company Websites:
                                            Visit the official websites of companies in your preferred industry. Many companies in Germany advertise their vacant positions directly on their websites. Check the 'Career' or 'Jobs' section for relevant opportunities.</li>
                                          <li className='mt-2'>
                                            <p>Job Portals and Career Websites:
                                              Explore popular job portals and career websites in Germany, as they often list vacancies across various industries:</p>
                                            <div className="ml-0.5 mt-1">
                                              <ul className="list-disc ml-4">
                                                <li><a className="text-primary-600 hover:text-primary-700" target="_blank" rel="noreferrer" href=" https://www.arbeitsagentur.de/jobsuche/">Federal Employment Agency's Jobbörse</a></li>
                                                <li><a className="text-primary-600 hover:text-primary-700" target="_blank" rel="noreferrer" href="https://www.jobware.de/">Jobware</a></li>
                                                <li><a className="text-primary-600 hover:text-primary-700" target="_blank" rel="noreferrer" href="https://www.stepstone.de/en">StepStone</a></li>
                                                <li><a className="text-primary-600 hover:text-primary-700" target="_blank" rel="noreferrer" href="https://de.indeed.com/">Indeed</a></li>
                                                <li><a className="text-primary-600 hover:text-primary-700" target="_blank" rel="noreferrer" href="https://jobs.meinestadt.de/">Meinestadt.de</a></li>
                                              </ul>
                                            </div>
                                          </li>


                                          <li className='mt-2'>Social Media Platforms:
                                            Leveraging social media platforms for your job search in this day and age has become a standard practice. Brush up your profile to make it more appealing to headhunters. Platforms such as LinkedIn and Xing continue to be valuable resources for exploring employment opportunities.
                                          </li>

                                          <li className='mt-2'>Networking and Job Fairs:
                                            Attend career fairs and networking events if you are in Germany. These events provide opportunities to connect with employers and even submit applications. If you are not in Germany, you want to ask friends, family, or acquaintances living in Germany who may know or have connections to companies in the industry you are interested in.</li>
                                        </ol>
                                      </div>

                                      <p className='mt-2'>You want to make sure you update and adapt your resume/CV, portfolio, and cover letter to suit each position you apply for and each company you apply to. Attending a webinar can be instrumental in getting you ready for the German job market.</p>



                                    </div>
                                  </div>
                                </>
                                {/* assignment' */}
                                {/* <>
                                      <div>
                                        <div className="relative px-1">
                                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                            <UsersIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="min-w-0 flex-1 py-1.5">
                                        <div className="text-sm text-gray-500">
                                          <a href={""} className="font-medium text-gray-900">
                                            {"Tom"}
                                          </a>{' '}
                                          assigned{' '}
                                          <a href={""} className="font-medium text-gray-900">
                                            {"Max"}
                                          </a>{' '}
                                          <span className="whitespace-nowrap">{"22.07.2009"}</span>
                                        </div>
                                        <div className="mt-2 text-sm text-gray-700">
                                          <p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.'}</p>
                                        </div>
                                      </div>
                                    </> */}
                                {/* tags */}
                                {/* <>
                                      <div>
                                        <div className="relative px-1">
                                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                            <CheckCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="min-w-0 flex-1 py-0">
                                        <div className="text-sm leading-8 text-gray-500">
                                          <span className="mr-0.5">
                                            <a href={""} className="font-medium text-gray-900">
                                              {"Tina"}
                                            </a>{' '}
                                            added tags
                                          </span>{' '}
                                          <span className="mr-0.5">
                                  
                                              <Fragment key={"Tag"}>
                                                <a
                                                  href={""}
                                                  className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                                >
                                                  <svg
                                                    className={classNames('green', 'h-1.5 w-1.5')}
                                                    viewBox="0 0 6 6"
                                                    aria-hidden="true"
                                                  >
                                                    <circle cx={3} cy={3} r={3} />
                                                  </svg>
                                                  {"Tag"}
                                                </a>{' '}
                                              </Fragment>
                              
                                          </span>
                                          <span className="whitespace-nowrap">{"27.01.2024"}</span>
                                        </div>
                                      </div>
                                    </> */}

                              </div>
                            </div>
                          </li>

                          <li key={4}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-orange-100 ring-8 ring-white">
                                      <UsersIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Shine in your job interviews"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-red-700', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"critical"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Yes, you can do it!</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>The decision to hire you hinges on your performance during the job interview, underscoring the importance of taking it seriously. Whether the interview is conducted by the company or a recruiter, approach it with the same level of seriousness. Indeed, there's no room to take it any more seriously than it deserves.</p>
                                      <p className='mt-2'>Following a successful interview, you can anticipate receiving a job contract shortly thereafter. Congratulations on reaching this significant milestone!</p>
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={5}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-purple-100 ring-8 ring-white">
                                      <CalendarIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Request or schedule a visa appointment "}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-red-700', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"critical"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Not difficult, but crucial</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>How and when you secure an appointment for your visa application depends on whether the fast-track procedure is initiated. It's advisable to discuss the fast-track option with your employer, as it guarantees a visa appointment within three weeks of the request.</p>
                                      <p className='mt-2'>Without the fast-track procedure, you’ll most likely have to book an appointment through an appointment booking system. In any case, once your new position is confirmed, promptly visit the German embassy in your country of residence to see how to get an appointment for your work visa application.</p>

                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={6}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 ring-8 ring-white">
                                      <HomeIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Make arrangements for your accommodation"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-yellow-300', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"medium"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Stay careful and diligent</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>It's important to note that you might be required to provide the full address of your intended residence when applying for a work visa. A hotel reservation can serve when getting a rental agreement proves challenging. If you have the option of staying with family or friends, that's certainly a viable choice</p>
                                      <p className='mt-2'>Having assistance from someone residing in Germany is advantageous, as securing accommodation remotely, particularly in larger cities, can be challenging. Therefore, services that provide support in this area play a crucial role in facilitating a smoother transition.</p>

                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={7}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-cyan-100 ring-8 ring-white">
                                      <ClipboardListIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Gather the required documentation"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-green-500', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"easy"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Do not overlook the details</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>Give yourself ample time to gather all the necessary documents you’ll submit during your visa appointment, including evidence of your qualifications, your work contract, sufficient health insurance coverage, and a valid passport with at least six months of validity remaining.</p>
                                      <p className='mt-2'>Make sure you visit the website of the German embassy where you’ll submit your application to carefully review the checklist of documents to submit and pay attention to how the documents should be presented, e.g., in original, two copies, certified copies, etc.</p>

                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={8}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-red-200 ring-8 ring-white">
                                      <UsersIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Apply for a work visa and attend the visa interview"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-yellow-300', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"medium"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Stay attentive, confident, and positive</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>Next, ensure that you accurately and thoroughly fill out the visa application form. Double-check to ensure that all the information provided aligns with the details on your supporting documents. Discrepancies can result in delays or, in some cases, the rejection of your application.</p>
                                      <p className='mt-2'>Maintain a positive and proactive attitude even after the submission of your application! Your enthusiasm and engagement throughout the process can positively influence the outcome.</p>

                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={9}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-green-200 ring-8 ring-white">
                                      <SwitchHorizontalIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Travel to Germany and get registered"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-green-500', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"easy"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">You have reached one of the most exciting steps!</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>After successfully obtaining your visa, it's time to travel to Germany and commence a new chapter in your career. You want to secure the best flight deals by booking well in advance.</p>
                                      <p className='mt-2'>Welcome to Germany! If you haven't already secured housing before arriving, now is the time to do so. Once you have a permanent address, it's essential to register at the local registration office within 14 days. Bring along your passport, visa, proof of address, and any other documents requested by the office. This registration is crucial not only for obtaining a work permit but also for handling other administrative tasks.</p>

                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={10}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-yellow-200 ring-8 ring-white">
                                      <HomeIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Commence employment and settle in"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-green-500', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"easy"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Explore your new home</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>Your initial weeks in Germany will be unique, not only due to your immersion in a new work environment but also because of various experiences beyond the workplace. You'll be occupied with tasks like acquainting yourself with local amenities, opening a bank account, and mastering the intricacies of public transportation, biking, or driving.</p>
                                      <p className='mt-2'>Socializing and networking with both locals and fellow expats play a crucial role in the settling-in process and contribute significantly to your overall adaptation to the new environment.</p>
                                      <p className='mt-2'>Embrace this exciting chapter with enthusiasm, as each new experience and connection brings you closer to a fulfilling and enriching adventure in Germany!</p>
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={11}>

                            <div className="relative pb-8">

                              {/* <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" /> */}

                              <div className="relative flex items-start space-x-3">

                                {/* tags */}
                                <>
                                  <div>
                                    <div className="relative px-1">
                                      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                        <FlagIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1 py-0">
                                    <div className="text-sm text-gray-500">
                                      <span className="mr-0.5">
                                        <span className="font-medium text-gray-900">
                                          {"Congratulations! Dreams come true."}
                                        </span>{' '}

                                      </span>{' '}
                                      <span className="mr-0.5">

                                      </span>
                                      {/* <span className="whitespace-nowrap">{"We wish you all the best in Germany"}</span> */}
                                    </div>
                                  </div>
                                </>

                              </div>
                            </div>
                          </li>
                          </>
                          }

                        </ul>
                      </div>
                    </div>

                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )
    }

  }

  if (props.userResult.migrationCheck.title === "Study") {
    return (
      <Transition.Root show={props.openGuideMe} as={Fragment}>
        <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={props.setOpenGuideMe}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 pr-4 pt-4 block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      onClick={() => props.setOpenGuideMe(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <h2 className="text-xl font-medium text-gray-900">
                    Guide me
                  </h2>


                  <div className="bg-white border-t border-b mt-6 py-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <AcademicCapIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Your personal step-by-step guide to studying in Germany
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            You’ve completed the detailed check, and you are now ready to start your journey to studying in one of the most attractive countries for international students; here is your road map:
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <p className='font-medium mb-5'>

                    </p>
                    <div className="flow-root">
                      <ul role="list" className="-mb-8">

                        <li key={1}>

                          <div className={`relative ${props.userData?.premium ? 'pb-8' : 'pb-1'}`}>

                            <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                            <div className="relative flex items-start space-x-4">


                              {/* comment */}
                              <>
                                <div className="relative">
                                  {/* <img
                                    className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                    src="/assets/images/avatar/ava17.png"
                                    alt=""
                                  /> */}
                                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <AcademicCapIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                                  </div>
                                </div>
                                <div className="min-w-0 flex-1">
                                  <div>
                                    <div className="text-sm">
                                      <span href="" className="font-medium text-gray-900">
                                        {"Decide on a Study Program"}
                                      </span>
                                      {" "}<Fragment key={1}>
                                        <span
                                          className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                        >
                                          <svg
                                            className={classNames('fill-green-500', 'h-1.5 w-1.5')}
                                            viewBox="0 0 6 6"
                                            aria-hidden="true"
                                          >
                                            <circle cx={3} cy={3} r={3} />
                                          </svg>
                                          {"easy"}
                                        </span>{' '}
                                      </Fragment>
                                    </div>
                                    <p className="mt-0.5 mb-4 text-sm text-gray-500">Start with this first basic step</p>
                                  </div>
                                  <div className="mt-2 text-sm text-gray-700">

                                    <p>Three websites that should definitely be on your list for searching study programs are </p>
                                    <div className="ml-0.5 opacity-100 my-2">
                                      <ul className="list-disc ml-4">
                                        <li><a target="_blank" rel="noreferrer" href="https://studiengaenge.zeit.de/studienangebote">HeyStudium</a></li>
                                        <li><a target="_blank" rel="noreferrer" href="https://www.daad.de/en/study-and-research-in-germany/courses-of-study-in-germany/all-study-programmes-in-germany/">DAAD</a> </li>
                                        <li><a target="_blank" rel="noreferrer" href="https://www.hochschulkompass.de/studium-interessentest.html">Higher Education Compass</a></li>
                                      </ul>
                                    </div>

                                    <p>{'While these platforms share some similarities as search engines for study programs, each has its unique features. HeyStudium ranks German universities and programs as well as features a study choice test for prospective students to see which degree programs match their interests. If you\'re interested in knowing how your chosen universities rank, you can find that information there.'}</p>
                                    <p className='mt-2'>{'DAAD provides a comprehensive website designed for prospective foreign students and covers topics on financing and scholarships. Higher Education Compass, on the other hand, features a study place exchange that allows people to search for available admission spots.'}</p>

                                  </div>
                                </div>
                              </>

                            </div>
                          </div>
                        </li>
                        {!props.userData?.premium && <div className="paywall bg-gray-50 mt-2 mb-8 mx-auto max-w-2xl rounded-xl ring-1 ring-gray-200 2xl:mx-0 2xl:flex xl:max-w-none" style={{position: "relative"}}>
                            <div className="p-4 2xl:p-8 2xl:p-6 sm:p-10 2xl:flex-auto gap-x-3">
                              <div className="flex gap-x-3 w-full mx-auto place-content-center">
                                <div>
                                <p className="text-base xl:text-xl font-bold tracking-tight text-gray-500">Upgrade to get full access</p>
                                </div>
                                <DocumentTextIcon className="h-6 w-6 text-primary-500" />
                              </div>
                              <div className="flex gap-x-3 w-full mx-auto place-content-center mt-6">
                                <div>
                                <img className="w-5 h-5" width={18} height={18} alt="" src="/assets/images/Check.svg"></img>
                                </div>
                                <p className='text-gray-500 text-sm'>9 more steps await you</p>
                              </div>
                              <div className="flex gap-x-3 w-full mx-auto place-content-center mt-8">
                              <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                onClick={() => {
                                  navigate("/buy/standard");
                                }}
                              >
                                <p className="px-8">book now</p>
                              </button>
                              </div>
                            </div>
                            
                          </div>}

                          {props.userData?.premium &&
                          <>
                          <li key={2}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-100 ring-8 ring-white">
                                      <BadgeCheckIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Check admission requirements"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-yellow-300', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"medium"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Do not overlook the details</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>{'In addition to meeting the academic criteria and language requirements, some universities may also require additional documents, such as recommendation letters or a statement of purpose.'}</p>
                                      <p className='mt-2'>{'By carefully reviewing the admission requirements and keeping track of deadlines, you can position yourself as a strong candidate for acceptance into your desired program.'}</p>

                                    </div>
                                  </div>
                                </>
                                {/* assignment' */}
                                {/* <>
                                      <div>
                                        <div className="relative px-1">
                                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                            <UsersIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="min-w-0 flex-1 py-1.5">
                                        <div className="text-sm text-gray-500">
                                          <a href={""} className="font-medium text-gray-900">
                                            {"Tom"}
                                          </a>{' '}
                                          assigned{' '}
                                          <a href={""} className="font-medium text-gray-900">
                                            {"Max"}
                                          </a>{' '}
                                          <span className="whitespace-nowrap">{"22.07.2009"}</span>
                                        </div>
                                        <div className="mt-2 text-sm text-gray-700">
                                          <p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.'}</p>
                                        </div>
                                      </div>
                                    </> */}
                                {/* tags */}
                                {/* <>
                                      <div>
                                        <div className="relative px-1">
                                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                            <CheckCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="min-w-0 flex-1 py-0">
                                        <div className="text-sm leading-8 text-gray-500">
                                          <span className="mr-0.5">
                                            <a href={""} className="font-medium text-gray-900">
                                              {"Tina"}
                                            </a>{' '}
                                            added tags
                                          </span>{' '}
                                          <span className="mr-0.5">
                                  
                                              <Fragment key={"Tag"}>
                                                <a
                                                  href={""}
                                                  className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                                >
                                                  <svg
                                                    className={classNames('green', 'h-1.5 w-1.5')}
                                                    viewBox="0 0 6 6"
                                                    aria-hidden="true"
                                                  >
                                                    <circle cx={3} cy={3} r={3} />
                                                  </svg>
                                                  {"Tag"}
                                                </a>{' '}
                                              </Fragment>
                              
                                          </span>
                                          <span className="whitespace-nowrap">{"27.01.2024"}</span>
                                        </div>
                                      </div>
                                    </> */}

                              </div>
                            </div>
                          </li>

                          <li key={3}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-pink-100 ring-8 ring-white">
                                      <PencilAltIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Apply for admission"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-red-700', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"critical"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Avoid unnecessary mistakes</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>{'While some universities accept direct applications via their online application portals, many require prospective international students to apply via uni-assist.'}</p>
                                      <p className='mt-2'>{'Take your time gathering all necessary documentation and ensure they meet the specified format and guidelines. Make sure to submit your application before the application deadlines.'}</p>
                                      <p className='mt-2'>{'Prospective international students from China, India, and Vietnam must first apply for APS certification before applying for admission.'}</p>
                                    </div>
                                  </div>
                                </>
                                {/* assignment' */}
                                {/* <>
                                      <div>
                                        <div className="relative px-1">
                                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                            <UsersIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="min-w-0 flex-1 py-1.5">
                                        <div className="text-sm text-gray-500">
                                          <a href={""} className="font-medium text-gray-900">
                                            {"Tom"}
                                          </a>{' '}
                                          assigned{' '}
                                          <a href={""} className="font-medium text-gray-900">
                                            {"Max"}
                                          </a>{' '}
                                          <span className="whitespace-nowrap">{"22.07.2009"}</span>
                                        </div>
                                        <div className="mt-2 text-sm text-gray-700">
                                          <p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.'}</p>
                                        </div>
                                      </div>
                                    </> */}
                                {/* tags */}
                                {/* <>
                                      <div>
                                        <div className="relative px-1">
                                          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                            <CheckCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="min-w-0 flex-1 py-0">
                                        <div className="text-sm leading-8 text-gray-500">
                                          <span className="mr-0.5">
                                            <a href={""} className="font-medium text-gray-900">
                                              {"Tina"}
                                            </a>{' '}
                                            added tags
                                          </span>{' '}
                                          <span className="mr-0.5">
                                  
                                              <Fragment key={"Tag"}>
                                                <a
                                                  href={""}
                                                  className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                                >
                                                  <svg
                                                    className={classNames('green', 'h-1.5 w-1.5')}
                                                    viewBox="0 0 6 6"
                                                    aria-hidden="true"
                                                  >
                                                    <circle cx={3} cy={3} r={3} />
                                                  </svg>
                                                  {"Tag"}
                                                </a>{' '}
                                              </Fragment>
                              
                                          </span>
                                          <span className="whitespace-nowrap">{"27.01.2024"}</span>
                                        </div>
                                      </div>
                                    </> */}

                              </div>
                            </div>
                          </li>

                          <li key={4}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-orange-100 ring-8 ring-white">
                                      <CalendarIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Schedule a study visa appointment and prepare proof of sufficient funds"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-red-700', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"critical"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">This step might require much time, start asap</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>{'Visit the website of the German embassy in your country of residence that processes study visas to book a visa appointment. In certain German embassies getting an appointment takes several months; therefore, be sure to check for specific instructions on how to get an appointment and do this as soon as possible.'}</p>
                                      <p className='mt-2'>{'To fulfill the financial requirements for a year of study in Germany, consider opening a blocked account with a minimum of 11,208 € as evidence of sufficient funds. For more details on this process, refer to the remark and recommendation section.'}</p>
                                      <p className='mt-2'>{'Alternatively, you can provide a formal obligation as proof of available funds. If you have close relatives in Germany, they can act as your obligor after confirming their financial capacity with the foreigners authority.'}</p>
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={5}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-purple-100 ring-8 ring-white">
                                      <DocumentTextIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Gather the required documentation and prepare for a visa interview"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-red-700', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"critical"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Make sure you are ready</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>{'Take this step seriously and give yourself ample time to gather all the necessary documents beforehand, including proof of acceptance from a German university, financial statements showing you have sufficient funds to support yourself during your studies, health insurance coverage, and a valid passport with at least six months of validity remaining.'}</p>
                                      <p className='mt-2'>{'Carefully review the checklist of documents to submit and pay attention to how the documents should be presented, e.g., in original, two copies, certified copies, etc.'}</p>
                                      <p className='mt-2'>{'Interviews allow officials to assess whether you meet their criteria and are genuinely planning to study in Germany. You want to be ready to demonstrate you are eligible and can study in Germany.'}</p>
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={6}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 ring-8 ring-white">
                                      <PencilAltIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Apply for a study visa"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-red-700', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"critical"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Stay careful and diligent</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>{'Next, fill out the visa application form accurately and completely. Double-check that all information provided matches what is stated on your other supporting documents. Any discrepancies can lead to delays or even rejection of your application.'}</p>
                                      <p className='mt-2'>{'Stay positive and proactive even after submitting your application! And this leads to your next step.'}</p>

                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={7}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-cyan-100 ring-8 ring-white">
                                      <HomeIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Make arrangements for accommodation"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-green-500', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"easy"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Not difficult, but important</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>{'Before you arrive in Germany, make sure you have a place to stay. The country offers various choices, such as student dormitories, shared apartments (WG), or private rentals. Each option has its pros and cons, so make sure to weigh them carefully based on factors like cost, location, and amenities.'}</p>
                                      <p className='mt-2'>{'Once you have decided on the type of accommodation that best suits your needs, contact the student services (Studentenwerk) where you’ll study. They usually have an allotment for international students and can reserve a place for you for a while.'}</p>
                                      <p className='mt-2'>You can also search for available options through online platforms like <a target="_blank" rel="noreferrer" href="https://www.wg-gesucht.de/">wg-gesucht.de</a> or <a target="_blank" rel="noreferrer" href="https://rentola.de/">rentola.de</a>. It's advisable to start this process well in advance to secure a place that fits within your budget.</p>

                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={8}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-red-200 ring-8 ring-white">
                                      <SwitchHorizontalIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Travel to Germany and get registered"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-green-500', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"easy"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Welcome to one of the most exciting steps!</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>Once you have obtained your visa, it is time to travel to Germany and start your exciting journey as an international student. Make sure to book your flight well in advance to get the best deals.</p>
                                      <p className='mt-2'>Before you leave, make sure to double-check that you have all necessary documents, especially your student visa and admission letter, as you’ll have to present these at the airports when departing your country and arriving in Germany.</p>
                                      <p className='mt-2'>Within two weeks of arriving in Germany, you must register at the local registration office. You will need to bring your passport, visa, proof of address, and any other relevant documents requested by the office.</p>

                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={9}>

                            <div className="relative pb-8">

                              <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                              <div className="relative flex items-start space-x-4">


                                {/* comment */}
                                <>
                                  <div className="relative">
                                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-green-200 ring-8 ring-white">
                                      <LibraryIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span href="" className="font-medium text-gray-900">
                                          {"Commence your program and settle in"}
                                        </span>
                                        {" "}<Fragment key={1}>
                                          <span
                                            className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                          >
                                            <svg
                                              className={classNames('fill-green-500', 'h-1.5 w-1.5')}
                                              viewBox="0 0 6 6"
                                              aria-hidden="true"
                                            >
                                              <circle cx={3} cy={3} r={3} />
                                            </svg>
                                            {"easy"}
                                          </span>{' '}
                                        </Fragment>
                                      </div>
                                      <p className="mt-0.5 mb-4 text-sm text-gray-500">Explore your new home</p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">

                                      <p>Universities commonly organize welcome programs, including orientation weeks for new students. These events provide an excellent chance to familiarize yourself with the campus and gain insights into life beyond its boundaries. A key starting point during your initial days is the international office or center of your university.</p>
                                      <p className='mt-2'>During your early weeks in Germany, a crucial task involves visiting the foreigners authority to secure a residence permit (Aufenthaltstitel). It's essential to keep in mind that enrolling in language lessons and actively engaging with people will contribute significantly to your smooth transition and integration.</p>

                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>

                          <li key={10}>

                            <div className="relative pb-8">

                              {/* <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" /> */}

                              <div className="relative flex items-start space-x-3">

                                {/* tags */}
                                <>
                                  <div>
                                    <div className="relative px-1">
                                      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                        <FlagIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="min-w-0 flex-1 py-0">
                                    <div className="text-sm text-gray-500">
                                      <span className="mr-0.5">
                                        <span className="font-medium text-gray-900">
                                          {"Congratulations! You have reached a remarkable milestone in your career."}
                                        </span>{' '}

                                      </span>{' '}
                                      <span className="mr-0.5">

                                      </span>
                                      {/* <span className="whitespace-nowrap">{"We wish you all the best in Germany"}</span> */}
                                    </div>
                                  </div>
                                </>

                              </div>
                            </div>
                          </li>
                          </>
                          }
                      </ul>
                    </div>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  if (props.userResult.migrationCheck.title === "Apprenticeship") {
    return (
      <Transition.Root show={props.openGuideMe} as={Fragment}>
        <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={props.setOpenGuideMe}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 pr-4 pt-4 block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      onClick={() => props.setOpenGuideMe(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <h2 className="text-xl font-medium text-gray-900">
                    Guide me
                  </h2>


                  <div className="bg-white border-t border-b mt-6 py-6">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                        {/* <AcademicCapIcon className="h-6 w-6 text-gray-600" aria-hidden="true" /> */}
                        <span className="h-9 w-9 rounded-lg opacity-80">
                          {/* <GiftIcon
                                        className="h-6 w-6 text-white"
                                        aria-hidden="true"
                                      /> */}
                          <img
                            src="/assets/images/icons_purposes/apprenticeship.svg"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          A step-by-step guide to starting dual vocational training in Germany
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            You’ve completed the detailed check, and you are now ready to start your journey to experiencing one of Europe’s economic powerhouses; here is your road map:
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <p className='font-medium mb-5'>

                    </p>
                    <div className="flow-root">
                      <ul role="list" className="-mb-8">

                        <li key={1}>

                          <div className={`relative ${props.userData?.premium ? 'pb-8' : 'pb-1'}`}>

                            <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                            <div className="relative flex items-start space-x-4">


                              {/* comment */}
                              <>
                                <div className="relative">
                                  {/* <img
                                    className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                    src="/assets/images/avatar/ava17.png"
                                    alt=""
                                  /> */}
                                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <BadgeCheckIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                                  </div>
                                </div>
                                <div className="min-w-0 flex-1">
                                  <div>
                                    <div className="text-sm">
                                      <span href="" className="font-medium text-gray-900">
                                        {"Meet the requirements"}
                                      </span>
                                      {" "}<Fragment key={1}>
                                        <span
                                          className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                        >
                                          <svg
                                            className={classNames('fill-green-500', 'h-1.5 w-1.5')}
                                            viewBox="0 0 6 6"
                                            aria-hidden="true"
                                          >
                                            <circle cx={3} cy={3} r={3} />
                                          </svg>
                                          {"easy"}
                                        </span>{' '}
                                      </Fragment>
                                    </div>
                                    <p className="mt-0.5 mb-4 text-sm text-gray-500">Start with this first basic step</p>
                                  </div>
                                  <div className="mt-2 text-sm text-gray-700">

                                    <p>The requirements for starting dual vocational training with a company in Germany are few:</p>
                                    <p className='mt-2'>First, you must have completed your secondary school education. This is a basic and standard requirement.</p>
                                    <p className='mt-2'>econdly, language proficiency is essential. While proficiency in English is advantageous, a strong command of the German language is crucial for vocational training opportunities. Although some companies may consider applicants who are in the process of learning the language, most prefer candidates who already possess good German language skills, typically at the B2 level.</p>

                                  </div>
                                </div>
                              </>

                            </div>
                          </div>
                        </li>

                        {!props.userData?.premium && <div className="paywall bg-gray-50 mt-2 mb-8 mx-auto max-w-2xl rounded-xl ring-1 ring-gray-200 2xl:mx-0 2xl:flex xl:max-w-none" style={{position: "relative"}}>
                            <div className="p-4 2xl:p-8 2xl:p-6 sm:p-10 2xl:flex-auto gap-x-3">
                              <div className="flex gap-x-3 w-full mx-auto place-content-center">
                                <div>
                                <p className="text-base xl:text-xl font-bold tracking-tight text-gray-500">Upgrade to get full access</p>
                                </div>
                                <DocumentTextIcon className="h-6 w-6 text-primary-500" />
                              </div>
                              <div className="flex gap-x-3 w-full mx-auto place-content-center mt-6">
                                <div>
                                <img className="w-5 h-5" width={18} height={18} alt="" src="/assets/images/Check.svg"></img>
                                </div>
                                <p className='text-gray-500 text-sm'>8 more steps await you</p>
                              </div>
                              <div className="flex gap-x-3 w-full mx-auto place-content-center mt-8">
                              <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                onClick={() => {
                                  navigate("/buy/standard");
                                }}
                              >
                                <p className="px-8">book now</p>
                              </button>
                              </div>
                            </div>
                            
                          </div>}

                          {props.userData?.premium &&
                          <>
                            <li key={2}>

                              <div className="relative pb-8">

                                <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                                <div className="relative flex items-start space-x-4">


                                  {/* comment */}
                                  <>
                                    <div className="relative">
                                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-100 ring-8 ring-white">
                                        <PencilAltIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                                      </div>
                                    </div>
                                    <div className="min-w-0 flex-1">
                                      <div>
                                        <div className="text-sm">
                                          <span href="" className="font-medium text-gray-900">
                                            {"Prepare to apply to companies"}
                                          </span>
                                          {" "}<Fragment key={1}>
                                            <span
                                              className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                            >
                                              <svg
                                                className={classNames('fill-yellow-300', 'h-1.5 w-1.5')}
                                                viewBox="0 0 6 6"
                                                aria-hidden="true"
                                              >
                                                <circle cx={3} cy={3} r={3} />
                                              </svg>
                                              {"medium"}
                                            </span>{' '}
                                          </Fragment>
                                        </div>
                                        <p className="mt-0.5 mb-4 text-sm text-gray-500">Avoid unnecessary mistakes</p>
                                      </div>
                                      <div className="mt-2 text-sm text-gray-700">

                                        <p>It is common for companies to request your CV as a means of familiarizing themselves with your background and experiences. A well-written, concise one-page CV can go a long way in creating interest in you.</p>
                                        <p className='mt-2'>Consider applying for the recognition of your school-leaving qualification as equivalent to a German school-leaving certificate, as this step could make you a stronger candidate. If you choose not to pursue this initially, keep in mind that you might still be required to undertake this process even after receiving a job offer from a company.</p>

                                      </div>
                                    </div>
                                  </>
                                  {/* assignment' */}
                                  {/* <>
                                        <div>
                                          <div className="relative px-1">
                                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                              <UsersIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="min-w-0 flex-1 py-1.5">
                                          <div className="text-sm text-gray-500">
                                            <a href={""} className="font-medium text-gray-900">
                                              {"Tom"}
                                            </a>{' '}
                                            assigned{' '}
                                            <a href={""} className="font-medium text-gray-900">
                                              {"Max"}
                                            </a>{' '}
                                            <span className="whitespace-nowrap">{"22.07.2009"}</span>
                                          </div>
                                          <div className="mt-2 text-sm text-gray-700">
                                            <p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.'}</p>
                                          </div>
                                        </div>
                                      </> */}
                                  {/* tags */}
                                  {/* <>
                                        <div>
                                          <div className="relative px-1">
                                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                              <CheckCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="min-w-0 flex-1 py-0">
                                          <div className="text-sm leading-8 text-gray-500">
                                            <span className="mr-0.5">
                                              <a href={""} className="font-medium text-gray-900">
                                                {"Tina"}
                                              </a>{' '}
                                              added tags
                                            </span>{' '}
                                            <span className="mr-0.5">
                                    
                                                <Fragment key={"Tag"}>
                                                  <a
                                                    href={""}
                                                    className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                                  >
                                                    <svg
                                                      className={classNames('green', 'h-1.5 w-1.5')}
                                                      viewBox="0 0 6 6"
                                                      aria-hidden="true"
                                                    >
                                                      <circle cx={3} cy={3} r={3} />
                                                    </svg>
                                                    {"Tag"}
                                                  </a>{' '}
                                                </Fragment>
                                
                                            </span>
                                            <span className="whitespace-nowrap">{"27.01.2024"}</span>
                                          </div>
                                        </div>
                                      </> */}

                                </div>
                              </div>
                            </li>

                            <li key={3}>

                              <div className="relative pb-8">

                                <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                                <div className="relative flex items-start space-x-4">


                                  {/* comment */}
                                  <>
                                    <div className="relative">
                                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-pink-100 ring-8 ring-white">
                                        <PencilIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                      </div>
                                    </div>
                                    <div className="min-w-0 flex-1">
                                      <div>
                                        <div className="text-sm">
                                          <span href="" className="font-medium text-gray-900">
                                            {"Secure a work contract with a company"}
                                          </span>
                                          {" "}<Fragment key={1}>
                                            <span
                                              className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                            >
                                              <svg
                                                className={classNames('fill-red-700', 'h-1.5 w-1.5')}
                                                viewBox="0 0 6 6"
                                                aria-hidden="true"
                                              >
                                                <circle cx={3} cy={3} r={3} />
                                              </svg>
                                              {"critical"}
                                            </span>{' '}
                                          </Fragment>
                                        </div>
                                        <p className="mt-0.5 mb-4 text-sm text-gray-500">This step might require much time, start asap</p>
                                      </div>
                                      <div className="mt-2 text-sm text-gray-700">

                                        <p>This is the biggest and most significant step: to get a work contract with one of the thousands of German companies that offer vocational training opportunities. Ways to secure a training offer include:</p>
                                        <div class="ml-0.5">
                                          <ol class="list-decimal ml-4">
                                            <li className='mt-2'>The “Introduce Me” feature:
                                              By simply clicking on the “Introduce Me” button and providing your information, you can start the process that leads to getting a work contract with a company offering vocational training opportunities.</li>
                                            <li className='mt-2'>Apprenticeship Platforms:
                                              Utilize specialized apprenticeship platforms that focus on vocational training opportunities. Websites like <a class="text-primary-600 hover:text-primary-700" target="_blank" rel="noreferrer" href="https://www.ausbildung.de/">Ausbildung.de </a>and<a class="text-primary-600 hover:text-primary-700" target="_blank" rel="noreferrer" href="https://www.azubiyo.de/"> Azubiyo </a>provide comprehensive listings of apprenticeships and vocational training programs in Germany.</li>
                                            <li className='mt-2'>Company Websites:
                                              Visit the official websites of companies in your preferred industry. Many companies in Germany advertise their vocational training programs directly on their websites. Check the 'Career' or 'Jobs' section for relevant opportunities.</li>
                                            <li className='mt-2'>
                                              <p>Job Portals and Career Websites:
                                                Explore popular job portals and career websites in Germany, as they often list available vocational training positions across various industries:</p>
                                              <div className="ml-0.5">
                                                <ul className="list-disc ml-4">
                                                  <li><a className="text-primary-600 hover:text-primary-700" target="_blank" rel="noreferrer" href=" https://www.arbeitsagentur.de/jobsuche/">Federal Employment Agency's Jobbörse</a></li>
                                                  <li><a className="text-primary-600 hover:text-primary-700" target="_blank" rel="noreferrer" href="https://www.jobware.de/">Jobware</a></li>
                                                  <li><a className="text-primary-600 hover:text-primary-700" target="_blank" rel="noreferrer" href="https://www.stepstone.de/en">StepStone</a></li>
                                                  <li><a className="text-primary-600 hover:text-primary-700" target="_blank" rel="noreferrer" href="https://de.indeed.com/">Indeed</a></li>



                                                  <li><a class="text-primary-600 hover:text-primary-700" target="_blank" rel="noreferrer" href="https://jobs.meinestadt.de//">Meinestadt.de</a></li>
                                                </ul>
                                              </div>
                                            </li>

                                            <li className='mt-2'>Recruiter and Agents:
                                              Local recruiters can provide information about available apprenticeships and connect you with companies offering training programs.</li>

                                            <li className='mt-2'>Vocational Schools and Educational Institutions:
                                              Contact vocational schools (Berufsschulen) and educational institutions in the region you are interested in. They often collaborate with companies to offer vocational training programs. Inquire about available opportunities and application procedures.</li>
                                            <li className='mt-2'>Networking and Job Fairs:
                                              Attend job fairs and networking events if you are in Germany. These events provide opportunities to connect with employers, learn about vocational training programs, and even submit applications. If you are not in Germany, you want to ask friends, family, or acquaintances living in Germany who may know or have connections to organizations training young people.</li>
                                          </ol>
                                        </div>

                                        <p className='mt-2'>Remember, you might be invited for an interview and the decision to offer you the vocational training opportunity will be made based on your performance during the interview. You want to prepare for it and take it very seriously</p>
                                        
                                      </div>
                                    </div>
                                  </>
                                  {/* assignment' */}
                                  {/* <>
                                        <div>
                                          <div className="relative px-1">
                                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                              <UsersIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="min-w-0 flex-1 py-1.5">
                                          <div className="text-sm text-gray-500">
                                            <a href={""} className="font-medium text-gray-900">
                                              {"Tom"}
                                            </a>{' '}
                                            assigned{' '}
                                            <a href={""} className="font-medium text-gray-900">
                                              {"Max"}
                                            </a>{' '}
                                            <span className="whitespace-nowrap">{"22.07.2009"}</span>
                                          </div>
                                          <div className="mt-2 text-sm text-gray-700">
                                            <p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt nunc ipsum tempor purus vitae id. Morbi in vestibulum nec varius. Et diam cursus quis sed purus nam. Scelerisque amet elit non sit ut tincidunt condimentum. Nisl ultrices eu venenatis diam.'}</p>
                                          </div>
                                        </div>
                                      </> */}
                                  {/* tags */}
                                  {/* <>
                                        <div>
                                          <div className="relative px-1">
                                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                              <CheckCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="min-w-0 flex-1 py-0">
                                          <div className="text-sm leading-8 text-gray-500">
                                            <span className="mr-0.5">
                                              <a href={""} className="font-medium text-gray-900">
                                                {"Tina"}
                                              </a>{' '}
                                              added tags
                                            </span>{' '}
                                            <span className="mr-0.5">
                                    
                                                <Fragment key={"Tag"}>
                                                  <a
                                                    href={""}
                                                    className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                                  >
                                                    <svg
                                                      className={classNames('green', 'h-1.5 w-1.5')}
                                                      viewBox="0 0 6 6"
                                                      aria-hidden="true"
                                                    >
                                                      <circle cx={3} cy={3} r={3} />
                                                    </svg>
                                                    {"Tag"}
                                                  </a>{' '}
                                                </Fragment>
                                
                                            </span>
                                            <span className="whitespace-nowrap">{"27.01.2024"}</span>
                                          </div>
                                        </div>
                                      </> */}

                                </div>
                              </div>
                            </li>

                            <li key={4}>

                              <div className="relative pb-8">

                                <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                                <div className="relative flex items-start space-x-4">


                                  {/* comment */}
                                  <>
                                    <div className="relative">
                                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-orange-100 ring-8 ring-white">
                                        <CalendarIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                      </div>
                                    </div>
                                    <div className="min-w-0 flex-1">
                                      <div>
                                        <div className="text-sm">
                                          <span href="" className="font-medium text-gray-900">
                                            {"Request or schedule a visa appointment"}
                                          </span>
                                          {" "}<Fragment key={1}>
                                            <span
                                              className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                            >
                                              <svg
                                                className={classNames('fill-red-700', 'h-1.5 w-1.5')}
                                                viewBox="0 0 6 6"
                                                aria-hidden="true"
                                              >
                                                <circle cx={3} cy={3} r={3} />
                                              </svg>
                                              {"critical"}
                                            </span>{' '}
                                          </Fragment>
                                        </div>
                                        <p className="mt-0.5 mb-4 text-sm text-gray-500">Be proactive in your approach</p>
                                      </div>
                                      <div className="mt-2 text-sm text-gray-700">

                                        <p>How and when you secure an appointment for your visa application depends on whether the fast-track procedure is initiated. It's advisable to discuss the fast-track option with your employer, as it guarantees a visa appointment within three weeks of the request.</p>
                                        <p className='mt-2'>Without the fast-track procedure, you’ll most likely have to book an appointment through an appointment booking system. In any case, once your new position is confirmed, promptly visit the German embassy in your country of residence to see how to get an appointment for your work visa application.</p>

                                      </div>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </li>

                            <li key={5}>

                              <div className="relative pb-8">

                                <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                                <div className="relative flex items-start space-x-4">


                                  {/* comment */}
                                  <>
                                    <div className="relative">
                                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-purple-100 ring-8 ring-white">
                                        <HomeIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                      </div>
                                    </div>
                                    <div className="min-w-0 flex-1">
                                      <div>
                                        <div className="text-sm">
                                          <span href="" className="font-medium text-gray-900">
                                            {"Make arrangements for your accommodation"}
                                          </span>
                                          {" "}<Fragment key={1}>
                                            <span
                                              className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                            >
                                              <svg
                                                className={classNames('fill-yellow-300', 'h-1.5 w-1.5')}
                                                viewBox="0 0 6 6"
                                                aria-hidden="true"
                                              >
                                                <circle cx={3} cy={3} r={3} />
                                              </svg>
                                              {"medium"}
                                            </span>{' '}
                                          </Fragment>
                                        </div>
                                        <p className="mt-0.5 mb-4 text-sm text-gray-500">Not difficult, but important</p>
                                      </div>
                                      <div className="mt-2 text-sm text-gray-700">

                                        <p>It's important to note that you might be required to provide the full address of your intended residence when applying for a work visa. A hotel reservation can serve when getting a rental agreement proves challenging. If you have the option of staying with family or friends, that's certainly a viable choice</p>
                                        <p className='mt-2'>Having assistance from someone residing in Germany is advantageous, as securing accommodation remotely, particularly in larger cities, can be challenging. Therefore, services that provide support in this area play a crucial role in facilitating a smoother transition.</p>

                                      </div>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </li>

                            <li key={6}>

                              <div className="relative pb-8">

                                <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                                <div className="relative flex items-start space-x-4">


                                  {/* comment */}
                                  <>
                                    <div className="relative">
                                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-100 ring-8 ring-white">
                                        <ClipboardListIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                      </div>
                                    </div>
                                    <div className="min-w-0 flex-1">
                                      <div>
                                        <div className="text-sm">
                                          <span href="" className="font-medium text-gray-900">
                                            {"Gather the required documentation"}
                                          </span>
                                          {" "}<Fragment key={1}>
                                            <span
                                              className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                            >
                                              <svg
                                                className={classNames('fill-green-500', 'h-1.5 w-1.5')}
                                                viewBox="0 0 6 6"
                                                aria-hidden="true"
                                              >
                                                <circle cx={3} cy={3} r={3} />
                                              </svg>
                                              {"easy"}
                                            </span>{' '}
                                          </Fragment>
                                        </div>
                                        <p className="mt-0.5 mb-4 text-sm text-gray-500">Do not overlook the details</p>
                                      </div>
                                      <div className="mt-2 text-sm text-gray-700">

                                        <p>Give yourself ample time to gather all the necessary documents you’ll submit during your visa appointment, including evidence of your qualifications, your work contract, sufficient health insurance coverage, and a valid passport with at least six months of validity remaining.</p>
                                        <p className='mt-2'>Make sure you visit the website of the German embassy where you’ll submit your application to carefully review the checklist of documents to submit and pay attention to how the documents should be presented, e.g., in original, two copies, certified copies, etc.</p>

                                      </div>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </li>

                            <li key={7}>

                              <div className="relative pb-8">

                                <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                                <div className="relative flex items-start space-x-4">


                                  {/* comment */}
                                  <>
                                    <div className="relative">
                                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-cyan-100 ring-8 ring-white">
                                        <SwitchHorizontalIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                      </div>
                                    </div>
                                    <div className="min-w-0 flex-1">
                                      <div>
                                        <div className="text-sm">
                                          <span href="" className="font-medium text-gray-900">
                                            {"Apply for a visa and travel to Germany"}
                                          </span>
                                          {" "}<Fragment key={1}>
                                            <span
                                              className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                            >
                                              <svg
                                                className={classNames('fill-yellow-300', 'h-1.5 w-1.5')}
                                                viewBox="0 0 6 6"
                                                aria-hidden="true"
                                              >
                                                <circle cx={3} cy={3} r={3} />
                                              </svg>
                                              {"medium"}
                                            </span>{' '}
                                          </Fragment>
                                        </div>
                                        <p className="mt-0.5 mb-4 text-sm text-gray-500">Welcome to one of the most exciting steps!</p>
                                      </div>
                                      <div className="mt-2 text-sm text-gray-700">

                                        <p>Next, fill out the visa application form accurately and completely. Double-check that all information provided matches what is stated on your other supporting documents. Any discrepancies can lead to delays or even rejection of your application.</p>
                                        <p className='mt-2'>Once you have obtained your visa, it is time to travel to Germany and start a new chapter of your career. Make sure to book your flight well in advance to get the best deals.</p>

                                      </div>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </li>

                            <li key={8}>

                              <div className="relative pb-8">

                                <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />

                                <div className="relative flex items-start space-x-4">


                                  {/* comment */}
                                  <>
                                    <div className="relative">
                                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10"> */}
                                      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-red-200 ring-8 ring-white">
                                        <RefreshIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                                      </div>
                                    </div>
                                    <div className="min-w-0 flex-1">
                                      <div>
                                        <div className="text-sm">
                                          <span href="" className="font-medium text-gray-900">
                                            {"Start your training and settle in"}
                                          </span>
                                          {" "}<Fragment key={1}>
                                            <span
                                              className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                            >
                                              <svg
                                                className={classNames('fill-green-500', 'h-1.5 w-1.5')}
                                                viewBox="0 0 6 6"
                                                aria-hidden="true"
                                              >
                                                <circle cx={3} cy={3} r={3} />
                                              </svg>
                                              {"easy"}
                                            </span>{' '}
                                          </Fragment>
                                        </div>
                                        <p className="mt-0.5 mb-4 text-sm text-gray-500">Explore your new home</p>
                                      </div>
                                      <div className="mt-2 text-sm text-gray-700">

                                        <p>Welcome to Germany! If you haven't already secured housing before arriving, it's time you did. Upon having a permanent address in Germany, you will need to register at the local registration office within 14 days. You will need to bring your passport, visa, proof of address, and any other relevant documents requested by the office.</p>
                                        <p className='mt-2'>Your first weeks in Germany will be special not only because of the new work environment you will engage in but also because of a whole lot of things outside the work environment. You’ll be busy familiarizing yourself with local amenities, Opening bank accounts, and learning how to get around using public transportation, bike, or car. Embrace the opportunity to socialize and enjoy your new environment of Germany.</p>

                                      </div>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </li>

                            <li key={9}>

                              <div className="relative pb-8">

                                {/* <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" /> */}

                                <div className="relative flex items-start space-x-3">

                                  {/* tags */}
                                  <>
                                    <div>
                                      <div className="relative px-1">
                                        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                                          <FlagIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="min-w-0 flex-1 py-0">
                                      <div className="text-sm text-gray-500">
                                        <span className="mr-0.5">
                                          <span className="font-medium text-gray-900">
                                            {"Congratulations! You have reached a remarkable milestone in your career."}
                                          </span>{' '}

                                        </span>{' '}
                                        <span className="mr-0.5">

                                        </span>
                                        {/* <span className="whitespace-nowrap">{"We wish you all the best in Germany"}</span> */}
                                      </div>
                                    </div>
                                  </>

                                </div>
                              </div>
                            </li>
                          </>
                          }
                      </ul>
                    </div>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

}