import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AuthContext } from "../context/auth-context";
import { StarIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

export default function ReviewModal(props) {
  const auth = useContext(AuthContext);
  const [review, setReview] = useState(null);
  const [stars, setStars] = useState(0);

  useEffect(() => {
    setReview({
      stars: props.userData.stars,
      review: props.userData.review,
      privacy: false,
      publicly: false,
    });
    setStars(props.userData.stars);
  }, [props.userData.stars]);

  const handleInputChange = (e) => {
    if (e.target.type === "checkbox") {
      setReview({ ...review, [e.target.name]: e.target.checked });
    } else {
      setReview({ ...review, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(review);
  };

  const onDelete = () => {
    let review = { stars: null, review: null, privacy: null, publicly: null };
    setReview(review);
    onSave(review);
  };

  const onSave = (review) => {
    fetch(`/api/user/review`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
      body: JSON.stringify(review),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((data) => {
        props.setUserData({
          ...props.userData,
          stars: data.stars,
          review: data.review,
        });
        props.setOpen(false);
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                {review && (
                  <form onSubmit={handleSubmit}>
                    <h2 className="text-lg font-medium text-gray-900">
                      {props.userData.review
                        ? "Update your review"
                        : "Write a review"}
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      What do you think about MigrationCheck? We would love to know your experience.
                    </p>
                    <div className="mt-6 py-6 border-t border-b space-y-3">
                      <div className="inline-flex items-center divide-x divide-gray-300 mx-auto">
                        <div className="min-w-0 flex-1 pr-5 text-sm text-gray-500">
                          <div className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                              <img
                                className="h-6 w-6 rounded-full"
                                // src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&h=256&q=80"
                                src="/assets/images/avatar/ava17.png"
                                alt=""
                              />
                            </div>
                            <div className="text-sm font-medium text-gray-700">
                              {props.userData.firstname}
                            </div>
                          </div>
                        </div>
                        <div className="flex-shrink-0 flex pl-5">
                          {Array.from({ length: 5 }, (_, i) => (
                            <StarIcon
                              key={i}
                              onMouseEnter={() => setStars(i + 1)}
                              onMouseLeave={() => setStars(review.stars)}
                              onClick={() =>
                                setReview({ ...review, stars: i + 1 })
                              }
                              className={`h-5 w-5 cursor-pointer ${
                                stars > i ? "text-yellow-400" : "text-gray-300"
                              }`}
                              aria-hidden="true"
                            />
                          ))}
                        </div>
                      </div>
                      <textarea
                        id="review"
                        name="review"
                        type="text"
                        rows={4}
                        className="shadow-sm focus:ring-primary-500 focus:border-rose-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Feel free to drop a review."
                        value={review.review}
                        onChange={handleInputChange}
                      />

                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="privacy"
                            name="privacy"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={review.privacy}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="privacy"
                            className="font-medium text-gray-700"
                          >
                            I accept the privacy policy
                          </label>
                          <p className="text-gray-500">
                            You can find our privacy policy{" "}
                            {/* <Link
                              className="text-primary-600 no-underline hover:underline"
                              to={"/privacy"}
                            >
                              here
                            </Link> */}
                            <a
                              href="https://migration-check.com/privacy-policy/"
                              target="_blank" rel="noopener noreferrer"
                              className="text-primary-600 no-underline hover:underline"
                            >
                              here
                            </a>
                            .
                          </p>
                        </div>
                      </div>

                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="publicly"
                            name="publicly"
                            type="checkbox"
                            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                            checked={review.publicly}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="publicly"
                            className="font-medium text-gray-700"
                          >
                            I agree to making my review public.
                          </label>
                          <p className="text-gray-500">
                            Your review may be used for
                            advertisement purposes.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex itmems-center justify-between">
                      <div>
                        {props.userData.review && (
                          <button
                            type="button"
                            className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                            onClick={onDelete}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:bg-primary-600/50"
                          disabled={!review.privacy}
                        >
                          {props.userData.review ? "Update" : "Create"}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
