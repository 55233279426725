import { ChevronRightIcon, HomeIcon, StarIcon } from "@heroicons/react/solid";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth-context";
import CreateUserModal from "../../modals/CreateUser";
import Moment from "react-moment";

const people = [
  {
    name: "Lindsay Walton",
    title: "Front-end Developer",
    email: "lindsay.walton@example.com",
    role: "Member",
  },

  // More people...
];

export default function AdminUsers() {
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [users, setUsers] = useState(null);

  const loadUsers = () => {
    fetch("/api/users", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        return Promise.reject(res);
      })
      .then((result) => {
        setUsers(result);
      })
      .catch((err) => {
        if (err.status === 401) {
          auth.logout();
        }
      });
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const extractProductNames = (user) => {
    return user.fsorders.flatMap(x => x).join(', ')
  }

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <header className="flex justify-between items-center">
          <div>
            <nav className="flex" aria-label="Breadcrumb">
              <ol role="list" className="flex items-center space-x-4">
                <li>
                  <div>
                    <span className="text-sm font-medium text-gray-700">
                      Benutzer
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
            <h1 className="mt-2 text-3xl font-bold leading-tight text-gray-900">
              Benutzer {users && <span>({users.length})</span>}
            </h1>
          </div>
          <div>
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:w-auto"
              onClick={() => {
                setEditUser(null);
                setOpen(true);
              }}
            >
              Neuer Benutzer
            </button>
          </div>
        </header>
        <main>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Vorname
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Erstellt
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Expire
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Quota
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Products
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Review
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Admin
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {users &&
                        users.map((user, index) => (
                          <tr
                            key={user.id}
                            className="cursor-pointer hover:bg-gray-50"
                            onClick={() => {
                              setEditUser(user);
                              setOpen(true);
                            }}
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {index + 1}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                              {user.firstname}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {user.email}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Moment date={user.createdOn} format="LL" locale="de" />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <Moment date={user.expireDate} format="LL" locale="de" />
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {user.quota}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {extractProductNames(user)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div className="flex-shrink-0 flex">
                                {user.stars &&
                                  Array.from({ length: 5 }, (_, i) => (
                                    <StarIcon
                                      key={i}
                                      className={`h-5 w-5 ${
                                        user.stars > i
                                          ? "text-yellow-400"
                                          : "text-gray-300"
                                      }`}
                                    />
                                  ))}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {user.admin ? "Ja" : "Nein"}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <CreateUserModal
        open={open}
        setOpen={(e) => {
          setOpen(e);
          loadUsers();
        }}
        editUser={editUser}
      />
    </>
  );
}
